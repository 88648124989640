import React, { useEffect } from 'react'
import { semaforoCumplimiento } from '../../helpers/semaforoCumplimiento';
import { useDispatch, useSelector } from 'react-redux';
import { startCargandoPedidosNoCotejados, startCargandoPedidosNoPagados } from '../../redux/store/pedidos/thunks';
import { TablaPedidos } from './TablaPedidos';
import { NoItems } from './NoItems';


export const VendedorIndividual = ({ pedidosCompletos = [] }) => {

  const dispatch = useDispatch();
  const { displayName, meta, comision } = useSelector(state => state.auth);
  const { pedidosNoCotejados } = useSelector(state => state.pedidos);

 console.log("Meta y Comisión: ", meta, comision);



  const filtrarNoCotejados = () => {
    dispatch(startCargandoPedidosNoCotejados(displayName));
  }

  console.log("No Cotejados: ", pedidosNoCotejados);

    let vendedores = [];
    console.log('Pedidos completos: ', pedidosCompletos);

    for (const pedido of pedidosCompletos) {
  
      if (!vendedores.includes(pedido.vendedorInfo.nombre)) {
        vendedores.push(pedido.vendedorInfo.nombre);
      }
  
    }


    let estadisticaPorVendedor = [];

  
    vendedores.forEach(vendedor => {
      const todosIndividual = pedidosCompletos.filter( pedido =>  pedido.vendedorInfo.nombre === vendedor);
 

     let dinero = 0;
     let comisionPendiente = 0;
     let comisionLiberada = 0;

     for (const pedido of todosIndividual) {
      dinero = dinero + parseFloat(pedido.detallesPago.cantidad);

      if (pedido.detallesPago?.cotejado) {
        comisionLiberada = comisionLiberada + parseFloat(pedido.detallesPago?.cantidad)
      } else if (!pedido.detallesPago?.cotejado) {
        comisionPendiente = comisionPendiente + parseFloat(pedido.detallesPago?.cantidad)
      }
     }

     //AQUI VA LA META SEMANAL, POR EL MOMENTO ES FIJA

      let nuevoVendedor = {
        vendedor,
        completos: todosIndividual.length,
        dinero,
        porcentajeMeta: (comisionLiberada/meta) * 100,
        porcentajeMetaSinCotejar: (comisionPendiente/meta) * 100,
        comisionPendiente: comisionPendiente * (comision / 100),
        comisionLiberada: comisionLiberada * (comision / 100)
      }
  
      estadisticaPorVendedor.push(nuevoVendedor);
  
    });

    console.log("Estadisticas por Vendedor: ", estadisticaPorVendedor);
  
    estadisticaPorVendedor.sort(function (a, b) {
        if (a.total > b.total) {
          return -1;
        }
        if (a.total < b.total) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

  return (
    <>



{/* <table className="table table__tableStats">
  <thead>
    <tr>
      <th scope="col">Repartidor</th>
      <th scope="col">Pedidos Completos</th>
      <th scope="col">Dinero</th>
      <th scope="col">Meta</th>
      <th scope="col">Avance</th>
    </tr>
  </thead>
  <tbody>
    {estadisticaPorVendedor.map( vendedor => {
        return (
            <tr key={vendedor.vendedor}>
            <th scope="row">{vendedor.vendedor}</th>
            <td>{vendedor.completos}</td>
            <td>{vendedor.dinero}</td>
            <td>$10</td>
            <td>{(vendedor.dinero/10) * 100}%</td>
            </tr>
        )
    })}

  </tbody>
</table>  */}

<div className="container-fluid" style={{backgroundColor: "#f5f7fa"}}>
  <section>
    <div className="row">
      <div className="col-12 mt-3 mb-1">
      <h1 className='settings__title' style={{marginTop: "64px"}}>Pedidos Realizados por {estadisticaPorVendedor[0]?.vendedor}</h1>
        <p>Los datos corresponden al mes señalado en el filtro</p>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-6 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between p-md-1">
              <div className="d-flex flex-row">
                <div className="align-self-center">
                  <i className="fas fa-list-check text-info fa-3x me-4"></i>
                </div>
                <div>
                  <h4>Pedidos Completos</h4>
                  <p className="mb-0">Pedidos registrados como completos</p>
                </div>
              </div>
              <div className="align-self-center">
                <h2 className="h1 mb-0">{estadisticaPorVendedor[0]?.completos}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between p-md-1">
              <div className="d-flex flex-row">
                <div className="align-self-center">
                  <i className="fas fa-money-check-alt text-success fa-3x me-4"></i>
                </div>
                <div>
                  <h4>Ventas Totales</h4>
                  <p className="mb-0">Cantidad de ingreso total por ventas</p>
                </div>
              </div>
              <div className="align-self-center">
                <h2 className="h1 mb-0">${estadisticaPorVendedor[0]?.dinero}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-6 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between p-md-1">
              <div className="d-flex flex-row">
                <div className="align-self-center">
                  <h2 className="h1 mb-0 me-4">${estadisticaPorVendedor[0]?.comisionLiberada.toFixed(2)}</h2>
                </div>
                <div>
                  <h4>Comisión Liberada</h4>
                  <p className="mb-0">Comisiones ganadas esta semana</p>
                </div>
              </div>
              <div className="align-self-center">
                <i className="fas fa-wallet text-success fa-3x"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between p-md-1">
              <div className="d-flex flex-row">
                <div className="align-self-center">
                  <h2 className="h1 mb-0 me-4">${estadisticaPorVendedor[0]?.comisionPendiente.toFixed(2)}</h2>
                </div>
                <div>
                  <h4>Comisión Pendiente</h4>
                  <p className="mb-0">Comisiones de ventas pendientes de cotejar o cobrar</p>
                </div>
              </div>
              <div className="align-self-center">
              <i className="fas fa-wallet text-warning fa-3x"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-12 col-md-12 mb-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between p-md-1">
              <div className="d-flex flex-row">
                <div className="align-self-center">
                  <h2 className="h1 mb-0 me-4">{parseFloat(estadisticaPorVendedor[0]?.porcentajeMeta.toFixed(2)) + parseFloat(estadisticaPorVendedor[0]?.porcentajeMetaSinCotejar.toFixed(2))}%</h2>
                </div>
                <div>
                  <h4>Porcenaje de avance meta mensual <span style={{fontSize: "14px", color: "red"}}>{` (${estadisticaPorVendedor[0]?.porcentajeMetaSinCotejar}% por cotejar)`}</span></h4>
                  <p className="mb-0">META SEMANAL: $10</p>
                </div>
              </div>
              <div className="align-self-center">
                <i className="fas fa-chart-line fa-3x"></i>
              </div>
              </div>
              
              {/* <div className="progress table__progressBar vendedor__progressBar mt-2">
                  <div className="progress-bar" role="progressbar" style={{width: `${estadisticaPorVendedor[0]?.porcentajeMeta}%`, backgroundColor: semaforoCumplimiento(estadisticaPorVendedor[0]?.porcentajeMeta)}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{`${estadisticaPorVendedor[0]?.porcentajeMeta}%`}</div>
              </div> */}

              <div class="progress mt-2">
                <div class="progress-bar bg-success" role="progressbar" style={{width: `${estadisticaPorVendedor[0]?.porcentajeMeta}%`}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">{`${estadisticaPorVendedor[0]?.porcentajeMeta}%`}</div>
                <div class="progress-bar bg-warning" role="progressbar" style={{width: `${estadisticaPorVendedor[0]?.porcentajeMetaSinCotejar}%`}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">{`${estadisticaPorVendedor[0]?.porcentajeMetaSinCotejar}%`}</div>
              </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>




<div className='dashboard__pedidos' style={{paddingBottom: "50px"}}>
        <div className="table__noCotejados"> 
        <button id="filtrarNoCotejados" style={{width: "300px", padding: "10px" }} className='stadistics__FiltrarNoPagados' onClick={filtrarNoCotejados}>Mostrar Comisiones No Liberadas</button>
        </div>

          {
             pedidosNoCotejados.length > 0
             ? <TablaPedidos pedidos={ pedidosNoCotejados } isAsignados={false} isCompletos={true} isNoPagados={false} />
             : <NoItems tipoPedido='no liberados' />
          }
        </div>


 </>
  )
}