import { createSlice } from "@reduxjs/toolkit";


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'checking',
        uid: null,
        email: null,
        displayName: null,
        meta: null,
        comision: null,
        direccion: null,
        municipio: null,
        telefono: null,
        errorMessage: null,
        perfil: null,
        datosFactura: null,
        rfc: null,
        idcfdi: null,
        imagenidcfdi: [],
        loadingNoReg: false,
        currentUserDocID: null,
    },
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated';
            state.uid = payload.uid;
            state.email = payload.email;
            state.displayName = payload.displayName;
            state.meta = payload.meta;
            state.comision = payload.comision;
            state.perfil = payload.perfil;
            state.direccion = payload.direccion;
            state.municipio = payload.municipio;
            state.telefono = payload.telefono;
            state.datosFactura = payload.datosFactura;
            state.rfc = payload.rfc;
            state.idcfdi = payload.idcfdi;
            state.imagenidcfdi = payload.imagenidcfdi;
            state.docID = payload.docID;
            state.errorMessage = null;
        },
        setPerfil: (state, { payload }) => {
            state.perfil = payload
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated';
            state.uid = null;
            state.email = null;
            state.displayName = null;
            state.meta = null;
            state.comision = null;
            state.perfil = null;
            state.direccion = null;
            state.municipio = null;
            state.telefono = null;
            state.datosFactura = null;
            state.rfc = null;
            state.idcfdi = null;
            state.imagenidcfdi = [];
            state.errorMessage = payload;

        },
        checkingCredentials: (state) => {
            state.status = 'checking';
        },
        isLoadingNoReg: (state, action) => {
            state.loadingNoReg = action.payload;
        },
        setCurrentUserDocID: (state, action) => {
            state.currentUserDocID = action.payload;
        }
    }
});


export const { login, setPerfil, logout, checkingCredentials, isLoadingNoReg, setCurrentUserDocID } = authSlice.actions;