import React from "react";
import { AppRouter } from "./routers/AppRouter";
import { Provider } from 'react-redux'
import { store } from "./redux/store/store";
// import OneSignal from 'react-onesignal';

export const PedidosApp = () => {

  
    
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    )
}