import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { FirebaseAuth } from './config'
import { sendPasswordResetEmail } from 'firebase/auth';
import Swal from "sweetalert2";

export const registerUserWithEmailPassword = async ( email, password, displayName ) => {

    
    try {
        const resp = await createUserWithEmailAndPassword(FirebaseAuth, email, password);
        const { uid } = resp.user;

        await updateProfile( FirebaseAuth.currentUser, {
            displayName
        } );


        return {
            ok: true,
            uid,
            email,
            displayName
        }
        
    } catch (error) {
        return {ok: false, errorMessage: error.message}
    }
}

export const loginWithEmailPassword = async ({email, password}) => {

    try {
        const resp = await signInWithEmailAndPassword(FirebaseAuth, email, password);
        const {uid, displayName} = resp.user;


        return {
            ok: true,
            uid,
            displayName
        }
        
    } catch (error) {
        console.log(error);
        return {ok: false, errorMessage: error.message}
    }
}   


export const logoutFirebase = async () => {

    return await FirebaseAuth.signOut();
}


export const resetPassword = async ( email ) => {

    sendPasswordResetEmail(FirebaseAuth, email.email)
    .then(() => {
        Swal.fire(
            'Confirmar cambio de contraseña',
            'Por favor revisa tu email para continuar el proceso',
            'success'
          )
    })
    .catch((error) => {
     console.log("Error cambiar contraseña: ", error)

    });
}