import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startCargarVendedores } from '../../redux/store/pedidos/thunks';
import { VendedoresAdmin } from './VendedoresAdmin';


export const EstadisticasPorVendedor = ({ pedidosCompletos = [] }) => {

  const dispatch = useDispatch();
  const { vendedores } = useSelector(state => state.pedidos);

  useEffect(() => {
    dispatch(startCargarVendedores());
  }, [dispatch])

  console.log("DB Vendedores: ", vendedores);

    let vendedoresFiltrados = [];
    console.log('Pedidos completos: ', pedidosCompletos);

    for (const pedido of pedidosCompletos) {
  
      if (!vendedoresFiltrados.includes(pedido.vendedorInfo.nombre)) {
        vendedoresFiltrados.push(pedido.vendedorInfo.nombre);
      }
  
    }

    console.log("Vendedores filtrados: ", vendedoresFiltrados);


    let estadisticaPorVendedor = [];


  
    vendedoresFiltrados.forEach(vendedor => {
      const todosIndividual = pedidosCompletos.filter( pedido =>  pedido.vendedorInfo.nombre === vendedor);
 

     let dinero = 0;
     let comisionPendiente = 0;
     let comisionLiberada = 0;
     let meta = 0;
     let comision = 0;


     for (const vendor of vendedores) {
      if (vendor.displayName === vendedor) {
        meta = vendor.meta;
        comision = vendor.comision;
      }
     }

     for (const pedido of todosIndividual) {
      dinero = dinero + parseFloat(pedido.detallesPago?.cantidad);
      if (pedido.detallesPago?.cotejado) {
        comisionLiberada = comisionLiberada + parseFloat(pedido.detallesPago?.cantidad)
      } else if (!pedido.detallesPago?.cotejado) {
        comisionPendiente = comisionPendiente + parseFloat(pedido.detallesPago?.cantidad)
      }
     }

      let nuevoVendedor = {
        vendedor,
        completos: todosIndividual.length,
        dinero,
        comisionPendiente: comisionPendiente * (comision/100),
        comisionLiberada: comisionLiberada * (comision/100),
        avancePorCotejar: (comisionPendiente/meta) * 100,
        meta,
        comision,
      }
  
      estadisticaPorVendedor.push(nuevoVendedor);
  
    });
  
    estadisticaPorVendedor.sort(function (a, b) {
        if (a.completos > b.completos) {
          return -1;
        }
        if (a.completos < b.completos) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

  return (
    <>

<VendedoresAdmin vendedores={vendedores} />

<h1 className='settings__title' style={{marginTop: "64px"}}>Estadística mensual</h1>

<table className="table table__tableStats table__tableVendedor">
  <thead>
    <tr>
      <th scope="col">Vendedor</th>
      <th scope="col">Pedidos Completos</th>
      <th scope="col">Ingreso Total</th>
      <th scope="col">Comisión Liberada</th>
      <th scope="col">Comisión Pendiente</th>
      <th scope="col">Meta</th>
      <th scope="col">Avance</th>
      <th scope="col">Avance por Cotejar</th>
    </tr>
  </thead>
  <tbody>
    {estadisticaPorVendedor.map( vendedor => {
        return (
            <tr key={vendedor.vendedor}>
            <th scope="row">{vendedor.vendedor}</th>
            <td>{vendedor.completos}</td>
            <td>$ {vendedor.dinero.toFixed(2)}</td>
            <td>$ {vendedor.comisionLiberada.toFixed(2)}</td>
            <td>$ {vendedor.comisionPendiente.toFixed(2)}</td>
            <td>$ {vendedor.meta}</td>
            <td>{((vendedor.dinero/vendedor.meta) * 100).toFixed(2)}%</td>
            <td>{vendedor.avancePorCotejar.toFixed(2)}%</td>
            </tr>
        )
    })}

  </tbody>
</table>






    </>
  )
}