import React, { useState } from 'react'
import {  useSelector, useDispatch } from 'react-redux/';

import moment from 'moment';
import 'moment/locale/es';
import Countdown from 'react-countdown';
import { semaforo } from '../../helpers/semaforo';
import { Formik, Field } from 'formik';
import { startActualizarPagos, startCotejarPago, startEntregarDocumento } from '../../redux/store/pedidos/thunks';
import { pedidoSeleccionado } from '../../redux/store/pedidos/pedidosSlice';


moment.locale('es');


export const PagosModal = ({ closeModal, pedido, noPagadosFlag }) => {

  const dispatch = useDispatch();
  // const { perfil, displayName } = useSelector(state => state.auth);
  const { pedidoModificar } = useSelector( state => state.pedidos);
  const [datosDelPago, setDatosDelPago] = useState(true);
  const [datosDeTransferencia, setDatosDeTransferencia] = useState(false);
  const { perfil } = useSelector(state => state.auth);

  dispatch(pedidoSeleccionado(pedido));

// if (pedido.pagado === "Sí") {
//   setDatosDelPago(true)
// }


  const cotejarPago = ( id, detallesPago ) => {
    dispatch(startCotejarPago( id, detallesPago ));
    closeModal(false);
  }

  const entregarDocumento = ( id, detallesPago ) => {
    dispatch(startEntregarDocumento( id, detallesPago ));
    closeModal(false);
  }
  


  return (
    <div className='modal__backgroundModal'>
        <div className='modal__container'>
            <div className='modal__closeModalXButton'>
                <button onClick={() => closeModal(false)}>X</button>
            </div>
            <div className='modal__header'>
              <h3 className='modal__title' style={{width: "100%", textAlign: "center"}}> INFORMACIÓN DEL PAGO</h3>
              {pedido.listo === "Nuevo" || pedido.listo === "No"  ? (<p  style={{textAlign: "center", color: semaforo(pedido.limite, pedido.status)}}><b>Límite de Producción:</b><br /> {<Countdown date={pedido.limite - 3600000} daysInHours overtime={true} />}</p>) : null}
              {(pedido.status !== "completo" && pedido.listo === "Sí") && (<p  style={{textAlign: "center", color: semaforo(pedido.limite, pedido.status)}}><b>Límite de Entrega:</b><br /> {<Countdown date={pedido.limite} daysInHours overtime={true} />}</p>)}
            </div>
            

            <div className='modal__body'>

                {pedido.pagado === "Sí" && <p style={{color: "#240BC1"}}>Pagado: {pedido.pagado}</p>} 
                {pedido.pagado === "Sí" && <p><b>Fecha de Pago: </b>{moment(pedido.detallesPago.fecha).format('dddd, DD [de] MMMM [de] YYYY')}</p>} 
                {pedido.pagado === "Sí" && <p><b>Cantidad: </b>${pedido.detallesPago.cantidad}</p>}
                {pedido.pagado === "Sí" && <p><b>Método: </b>{pedido.detallesPago.metodo}</p>}
                {pedido.pagado === "Sí" && <p><b>No. Transferencia: </b>{pedido.detallesPago.transferencia}</p>}
                {pedido.pagado === "Sí" && <p style={{color: pedido.detallesPago.cotejado ? "#009B09" : "#E80101"}}><b>Cotejado: </b>{pedido.detallesPago.cotejado ? "Sí" : "No"}</p>}

                {pedido.pagado === "Sí" && <hr />}

                <p style={{color: "#240BC1"}}><b>Cliente:</b> {pedido.cliente.toUpperCase()}</p>
                <p><b>Registro:</b> {moment(pedido.fecha).format('dddd, DD [de] MMMM[,] LT ')}</p>
                {pedido.listo === "Sí" && (<p><b>No. Pedido:</b> {pedido.numeroPedido}</p>)} 
                <p><b>Dirección:</b> {pedido.direccion}</p>
                <p><b>Municipio:</b> {pedido.municipio}</p>
                {pedido.status === "parcial" || pedido.status === "completo" ? (<p><b>Entregado: </b> {moment(pedido.fechaEntrega).format('dddd, DD [de] MMMM [-] LT ')}</p>) : null} 
                {(perfil === "Repartidor" && pedido.detallesPago.metodo !== "Efectivo") && <p style={ pedido.detallesPago.documento ? {color: "green"} : {color: "red"}}><b>Documento Entregado: </b>{pedido.detallesPago.documento ? "Sí" : "No"}</p> }


                <div className='modal__actions'>
                    {(pedido.pagado === 'Sí' && !pedido.detallesPago.cotejado && perfil === "Administrador") && <button style={{marginTop: "15px"}} className='modal__btnAsignar' onClick={ () => cotejarPago(pedido.uid, pedido.detallesPago) }>Cotejar Pago</button>}
                    {(pedido.pagado === 'No' && !pedido.detallesPago.documento && perfil === "Administrador") && <button style={{marginTop: "15px", width: "40%"}} className='modal__btnAsignar' onClick={ () => entregarDocumento(pedido.uid, pedido.detallesPago) }>Documento Entregado</button>}
                </div>
                <br />

                <hr />



          {(perfil === "Administrador" && (pedido.pagado === "Sí" || pedido.detallesPago.documento)) &&
          (
            <Formik 
            initialValues={{
              
              pagado: pedido.pagado,
              fechaPago: pedido.detallesPago.fecha,
              metodoPago: pedido.detallesPago.metodo,
              cantidad: pedido.detallesPago.cantidad,
              transferencia: pedido.detallesPago.transferencia

              }}
            validate={(valores) => {
              let errores = {};
    

              if (!valores.cantidad) {
                errores.cantidad = "Requerido"
              }


              if (!valores.fechaPago) {
                errores.fechaPago = "La fecha de pago es requerida"
              } 


              if (valores.pagado === "Sí") {
                setDatosDelPago(true);
              } else if (valores.pagado === "No") {
                setDatosDelPago(false);
              }


              if (valores.metodoPago === "Transferencia") {
                setDatosDeTransferencia(true)
              } else {
                setDatosDeTransferencia(false)
              }

      
              if (valores.metodoPago === '' && valores.pagado === "Sí") {
                errores.metodoPago = "Requerido"
              }

              if (valores.metodoPago === 'Transferencia' && !valores.transferencia) {
                errores.transferencia = "Requerido"
              }




              return errores;
            }}
            onSubmit={(valores) => {

              let cotejado = false;
              if (valores.pagado === 'Sí') {
                cotejado = true;
              }
              dispatch(startActualizarPagos(pedidoModificar, pedidoModificar.uid, valores, cotejado, noPagadosFlag));
              closeModal(false);

            }}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <form className='generate__form' onSubmit={handleSubmit}>
    
              <div className='generate__form-section'>



              



                <div className='generate__pago-selects'>

    
                <div className='generate__labeledSelect'  style={{width: "100%"}}>
                    <p className='generate__label-selects modalPagos__label-selects'>
                      ¿Se realizó el pago?
                    </p>
                    <Field as="select" name="pagado" className='settings__selectInput modalPagos__selectInput'>
                      <option value="No">No</option>
                      <option value="Sí">Sí</option>
                    </Field>  
                  </div>
    
    
                </div>  

        
                {datosDelPago && (
                  <div className='modalPagos__factura-inputs'>

                  <div className='generate__pedido-elements modalPagos__pedido-elements'>
                    <label className='generate__label'>
                      Fecha de Pago: 
                      <input 
                        type="date" 
                        name="fechaPago" 
                        className='generate__input-limite'
                        value={moment(values.fechaPago).format('yyyy-MM-DD')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    {touched.fechaPago && errors.fechaPago && <div style={{marginTop: '0'}} className='generate__error'>{errors.fechaPago}</div>}
                    </label>
                  </div>


                  <label className='generate__labelFactura modalPagos__labelFactura'>
                    Cantidad: 
                    <input 
                        type="number" 
                        placeholder="Cantidad" 
                        name="cantidad" 
                        className='generate__inputFactura'
                        value={values.cantidad}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.cantidad && errors.cantidad && <div className='generate__error '>{errors.cantidad}</div>}
                  </label>
      

                  <div style={{flexDirection: "column", justifyContent: "flex-start"}} className='generate__labelFactuar generate__noMT'>
                    <Field as="select" name="metodoPago" className='settings__selectInput metodoSelect'>
                      <option value=''>Método de Pago</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Transferencia">Transferencia</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Otro">Otro</option>
                    </Field>  
                    {touched.metodoPago && errors.metodoPago && <div style={{marginTop: '0'}} className='generate__error'>{errors.metodoPago}</div>}
                  </div>

                  {
                    datosDeTransferencia && (

                      <label className='generate__labelFactura modalPagos__labelFactura'>
                      Número de Transferencia: 
                      <input 
                        type="text" 
                        placeholder="Número de transferencia" 
                        name="transferencia" 
                        className='generate__inputFactura'
                        value={values.direccionFactura}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.transferencia && errors.transferencia && <div className='generate__error'>{errors.transferencia}</div>}
                    </label>
                    )
                  }
                        
                  </div>
                )}
     
              </div>
    
        
              <button
                type='submit'
                className='generate__btn generate__btn-primary'
                >
                  Actualizar Pago
              </button>
              </form>
            )}


           </Formik>
          )
          
          }   



{/* 
                {pedido.pagado === "Sí" &&  <p style={{color: "#240BC1"}}>Cliente: {pedido.cliente.toUpperCase()}</p>}                             
                {pedido.pagado === "Sí" &&  <p><b>Fecha:</b> {moment(pedido.fecha).format('dddd, DD [de] MMMM[,] LT ')}</p> }
                {(pedido.listo === "Sí" && pedido.pagado === "Sí") && (<p><b>No. Pedido:</b> {pedido.numeroPedido}</p>)} 
                {pedido.pagado === "Sí" && <p><b>Dirección:</b> {pedido.direccion}</p>}
                {pedido.pagado === "Sí" && <p><b>Municipio:</b> {pedido.municipio}</p> }
                {pedido.pagado === "Sí" && (pedido.status === "parcial" || pedido.status === "completo") ? (<p><b>Fecha de Entrega: </b> {moment(pedido.fechaEntrega).format('dddd, DD [de] MMMM [-] LT ')}</p>) : null} 
                {pedido.pagado === "Sí" && <hr />} */}


                




                
                
                
                
            </div>

 

        </div>
    </div>
  )
}
