import React from 'react'
import { semaforoCumplimiento } from '../../helpers/semaforoCumplimiento';

export const EstadisticasPorRepartidor = ({ pedidosCompletos }) => {

    let repartidores = [];

    for (const pedido of pedidosCompletos) {
  
      if (!repartidores.includes(pedido.repartidor)) {
        repartidores.push(pedido.repartidor);
      }
  
    }


    let estadisticaPorRepartidor = [];

  
    repartidores.forEach(repartidor => {
      const completosIndividual = pedidosCompletos.filter( pedido => pedido.repartidor === repartidor);
      const enTiempoIndividual = completosIndividual.filter( pedido => pedido.enTiempo === true);

      let nuevoRepa = {
        repartidor,
        total: completosIndividual.length,
        enTiempo: enTiempoIndividual.length,
        porcentaje: (enTiempoIndividual.length / completosIndividual.length) * 100
      }
  
      estadisticaPorRepartidor.push(nuevoRepa);
  
    });
  
    estadisticaPorRepartidor.sort(function (a, b) {
        if (a.porcentaje > b.porcentaje) {
          return -1;
        }
        if (a.porcentaje < b.porcentaje) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

  return (
    <>

<h1 className='settings__title' style={{marginTop: "64px"}}>Pedidos Entregados por Repartidor</h1>

<table className="table table__tableStats">
  <thead>
    <tr>
      <th scope="col">Repartidor</th>
      <th scope="col">En Tiempo</th>
      <th scope="col">Fuera de Tiempo</th>
      <th scope="col">Total</th>
      <th scope="col">Porcentaje</th>
    </tr>
  </thead>
  <tbody>
    {estadisticaPorRepartidor.map( repartidor => {
        return (
            <tr key={repartidor.repartidor}>
            <th scope="row">{repartidor.repartidor}</th>
            <td>{repartidor.enTiempo}</td>
            <td>{repartidor.total - repartidor.enTiempo}</td>
            <td>{repartidor.total}</td>
            <td colSpan="2">
            <div className="progress table__progressBar">
                <div className="progress-bar" role="progressbar" style={{width: `${repartidor.porcentaje}%`, backgroundColor: semaforoCumplimiento(repartidor.porcentaje)}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{`${Math.round(repartidor.porcentaje * 100) / 100}%`}</div>
            </div>
            <div className='table__progressNumber' style={{color: semaforoCumplimiento(repartidor.porcentaje)}}>{`${Math.round(repartidor.porcentaje * 100) / 100}%`}</div>
            </td>
        </tr>
        )
    })}

  </tbody>
</table>



    </>
  )
}
