import React from 'react'

export const CargandoPedido = () => {
  return (
    <>
        <div className='generalSettings__loadingContainer'>    
            <div className='generalSettings__loadingBody'>
                <p>Espere un momento...</p>
            </div>
        </div>
    </>
  )
}
