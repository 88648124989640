import React from 'react'
import { useSelector } from 'react-redux';

export const TotalEntregados = ({ pedidosCompletos }) => {

 const { perfil, displayName } = useSelector(state => state.auth);
 let nuevosPedidosCompletos = [];
 
 if ( perfil === "Sucursal"  || perfil === "Repartidor") {
    nuevosPedidosCompletos = pedidosCompletos.filter( pedido => pedido.repartidor === displayName);
 } else {
  nuevosPedidosCompletos = pedidosCompletos;
 }



const enTiempo = nuevosPedidosCompletos.filter( pedido => pedido.enTiempo === true);
const despuesLimite = nuevosPedidosCompletos.filter( pedido => pedido.enTiempo === false);
let porcentajeCompletosEnTiempo = (enTiempo.length / nuevosPedidosCompletos.length) * 100;
if (isNaN(porcentajeCompletosEnTiempo)) {
    porcentajeCompletosEnTiempo = 0;
}
const widthPorcentaje = porcentajeCompletosEnTiempo.toString() + '%';

  return (
    <>

<div className='stadistics__completosContainer'>
        <div className='stadistics__completosHeader'>
            <p className='stadistics__completosTitle'>PEDIDOS ENTREGADOS</p>
        </div>
        <div className='stadistics__completosBody'>
            <div className='stadistics__completosBodyField'>
              <p className='stadistics__completosBodyFieldTitle'>Total</p>
              <p className='stadistics__completosBodyFieldData'>{nuevosPedidosCompletos.length}</p>
            </div>
            <div className='stadistics__completosBodyField'>
              <p className='stadistics__completosBodyFieldTitle'>En Tiempo</p>
              <p className='stadistics__completosBodyFieldData'>{enTiempo.length}</p>
            </div>
            <div className='stadistics__completosBodyField'>
              <p className='stadistics__completosBodyFieldTitle'>Fuera de Tiempo</p>
              <p className='stadistics__completosBodyFieldData'>{despuesLimite.length}</p>
            </div>
          </div>
          <div className='stadistics__completosFooter'>
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{width: widthPorcentaje, backgroundColor: "#008700"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{`${Math.round(porcentajeCompletosEnTiempo * 100) / 100}% entregados en tiempo`}</div>
         </div>
          </div>
      </div>



    </>
  )
}
