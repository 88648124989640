import React from "react";

export const CheckingAuth = () => {
  return (
    <div style={{marginTop: "300px"}} >
      <div className='d-flex justify-content-center align-items-center'>
        <div className='spinner-border' role='status'>
          <span className='sr-only'></span>
        </div>
      </div>
      <p className='mt-2 d-flex justify-content-center align-items-center'>Espere un momento...</p>
    </div>
  );
};

