import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/'
// import { Redirect } from 'react-router-dom'
import { startCargandoPedidosCompletos } from '../../redux/store/pedidos/thunks'
import { Navbar2 } from '../support/Navbar2'
import { NoItems } from '../support/NoItems'
import { TablaPedidos } from '../support/TablaPedidos'
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export const CompleteScreen = () => {

  const dispatch = useDispatch();
  const { pedidosCompletos } = useSelector(state => state.pedidos);
  const initialDesde = moment().startOf('week').format("yyyy-MM-DD");
  const initialHasta = moment().endOf('week').format("yyyy-MM-DD");


  const [ fechaDesde, setFechaDesde ] = useState(initialDesde);
  const [ fechaHasta, setFechaHasta ] = useState(initialHasta);
  const [ cambioFecha, setCambioFecha] = useState(true);
  const [ repartidorSeleccionado, setRepartidorSeleccionado ] = useState("Todos");
  const [ tipoPedidoSeleccionado, setTipoPedidoSeleccionado ] = useState("Externo");
  const [ pedidosCompletosFiltrados, setPedidosCompletosFiltrados ] = useState(pedidosCompletos);
  const { perfil, displayName } = useSelector(state => state.auth);

  let nuevosPedidosCompletos = [];
 
  if (perfil === "Repartidor") {
     nuevosPedidosCompletos = pedidosCompletosFiltrados.filter( pedido => pedido.repartidor === displayName);
  } else if (perfil === "Sucursal") {
    nuevosPedidosCompletos = pedidosCompletosFiltrados.filter( pedido => pedido.registro === displayName);
  } else if (perfil === "Vendedor") {
    nuevosPedidosCompletos = pedidosCompletosFiltrados.filter( pedido => pedido.vendedorInfo?.nombre === displayName);
  }
    else {
    nuevosPedidosCompletos = pedidosCompletosFiltrados;
  }



  // useEffect(() => {
  //   dispatch(startCargandoPedidosCompletos());
  // }, [dispatch])

  // useEffect(() => {
  //   filtrarFechas();
  // }, [])


  // if ( perfil === "Produccion" ) {
  //   return <Redirect to="/produccion" />
  // }

  const seleccionarDesde = (event) => {
    if (event.target.value !== fechaDesde) {
      setFechaDesde(event.target.value);
      setCambioFecha(true);
    }
  }

  const seleccionarHasta = (event) => {
    if (event.target.value !== fechaHasta) {
      setFechaHasta(event.target.value)
      setCambioFecha(true)
    }
  }

  const changeRepartidor = (event) => {
    setRepartidorSeleccionado(event.target.value);
  }

  const changeTipoPedido = (event) => {
    setTipoPedidoSeleccionado(event.target.value);
  }

  const filtrarFechas = () => {

    let desde = new Date(fechaDesde).getTime() + 18000000; 
    let hasta = new Date(fechaHasta).getTime() + 104400000;

    if (hasta < desde) {
      Swal.fire('¡Error!', 'La fecha final no puede ser menor que la inicial','error');
      return;
    }

    if (cambioFecha) {
      dispatch(startCargandoPedidosCompletos(desde, hasta));
      setCambioFecha(false);
    } 

    filtrarDatos();

  }



  const filtrarDatos = () => {
    let filtradosCompletos = [];
    let repartidor = repartidorSeleccionado;
    let tipoPedido = tipoPedidoSeleccionado;

    if (repartidor === "Todos") {
      filtradosCompletos = pedidosCompletos;
    } else if (repartidor === "Otros") {
      filtradosCompletos = pedidosCompletos.filter( pedido => (pedido.repartidor !== "Martín" && pedido.repartidor !== "Héctor" && pedido.repartidor !== "Emergente" && pedido.repartidor !== "Diego de la Cruz " && pedido.repartidor !== "Francisco Rangel "));
    } else {
      filtradosCompletos = pedidosCompletos.filter( pedido => (pedido.repartidor === repartidor));
    }

    let filtradosTipoPedido = [];

    if (tipoPedido === "Todos") {
      filtradosTipoPedido = filtradosCompletos;
    } else if (tipoPedido === "Externo") {
      filtradosTipoPedido = filtradosCompletos.filter( pedido => (pedido.tipoPedido === "Externo" || pedido.tipoPedido === "Cliente Web" ));
    } else if (tipoPedido === "Interno") {
      filtradosTipoPedido = filtradosCompletos.filter( pedido => pedido.tipoPedido === "Interno");
    }
 
    setPedidosCompletosFiltrados(filtradosTipoPedido);
  }

  const semanaAnterior = () => {
    let desde = moment(fechaDesde).subtract(7, 'days');
    let hasta = moment(fechaHasta).subtract(7, 'days');
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setCambioFecha(true);
  }

  const semanaSiguiente = () => {
    let desde = moment(fechaDesde).add(7, 'days');
    let hasta = moment(fechaHasta).add(7, 'days');
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setCambioFecha(true);
  }



  return (
    <div>
        <Navbar2 />
        <div className='stadistics__rangoContainer'>

            
            <div className='stadistics__rangoFechas'>
              <label className='generate__label'>
                Desde:
                <input 
                  type="date" 
                  name="desde" 
                  className='stadistics__input-limite'
                  value={fechaDesde}
                  onChange={seleccionarDesde}
                />
              </label>
            </div>


            <div className='stadistics__arrowsContainer'>
                <i className="fa-solid fa-circle-arrow-left stadistics__arrows" onClick={semanaAnterior}></i>
                <i className="fa-solid fa-circle-arrow-right stadistics__arrows" onClick={semanaSiguiente}></i>
            </div>
        

            <div className='stadistics__rangoFechas'>
              <label className='generate__label'>
                Hasta:
                <input 
                  type="date" 
                  name="hasta" 
                  className='stadistics__input-limite'
                  value={fechaHasta}
                  onChange={seleccionarHasta}
                />
              </label>
            </div>

           {(perfil !== "Repartidor" && perfil !== "Sucursal") && (
            
                <div className='stadistics__rangoFechas repartidorInput'>
                <label className='generate__label'>
                  Repartidor:
                  <select name="select" onChange={changeRepartidor} className="stadistics__input-limite">
                    <option value="Todos" selected>Todos</option>
                    <option value="Diego de la Cruz ">Diego</option>
                    <option value="Francisco Rangel ">Francisco</option>              
                    <option value="Héctor">Héctor</option>
                    <option value="Emergente">Emergente</option>
                    <option value="Martín">Martín</option>
                    <option value="Otros">Otros</option>
                  </select>
                </label>
                </div>
           )}

              <div className='stadistics__rangoFechas repartidorInput'>
                <label className='generate__label'>
                  Tipo de Pedido:
                  <select name="select" onChange={changeTipoPedido} className="stadistics__input-limite">
                    <option value="Externo" selected>Externos</option>
                    <option value="Interno">Internos</option>
                    <option value="Todos">Todos</option>
                  </select>
                </label>
                </div>



          <button id="filtrarFechas" className='stadistics__FiltrarBtn' onClick={filtrarFechas}>Filtrar</button>


          </div>

        <h1 className='settings__title'>Pedidos Completos</h1>

        <div className='dashboard__pedidos'>
          {
             pedidosCompletos.length > 0
             ? <TablaPedidos pedidos={ nuevosPedidosCompletos } isAsignados={false} isCompletos={true} isNoPagados={false} />
             : <NoItems tipoPedido='completos' />
          }
        </div>
    </div>
  )
}
