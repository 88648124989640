import { collection, doc, setDoc, updateDoc } from 'firebase/firestore/lite'
import { FirebaseDB, obtenerToken, suscribe } from '../../../firebase/config'
import { cargarImagenes, cargarImagenIdcfdi, cargarPedidosAsignados, cargarPedidosCliente, cargarPedidosCompletos, cargarPedidosNoCotejados, cargarPedidosNoPagados, cargarPedidosNuevos, cargarPedidosPendientes, cargarPedidosSinEntregar, cargarPedidosVendedores, cargarTodosPedidos, cargarVendedores, gatosExtras, isLoading, limpiarImagenes, limpiarImagenIdcfdi, limpiarPedidoSeleccionado } from './pedidosSlice';
import moment from 'moment';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { deleteDoc, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { fileUpload } from '../../../helpers/fileUpload';
import { sendNotificationsNuevoPedido } from '../../../helpers/sendNotification';
import { logout } from '../auth/authSlice';



moment.locale('es');

export const startGenerarNuevoPedido = (valores) => {
    return async (dispatch, getState) => {

        const { displayName, datosFactura, currentUserDocID } = getState().auth;
        const {cliente,
               tipo,
               direccion, 
               municipio, 
               rangoPrecio,
               tipoPedido,
               pedido, 
               limite, 
               factura, 
               comentarios,
               rfc, 
               idcfdi,
               imagenID,
               direccionFactura, 
               cfdi,
               formaPago, 
               metodoPago,
               costoExtra, 
               repartidor, 
               sucursal,
               telefono,
               email,
               vendedor,
               vendedorInfo,
               imagenes} = valores;



        const nuevoPedido = {
            cliente,
            tipo,
            direccion,
            municipio,
            rangoPrecio,
            tipoPedido,
            pedido,
            limite: new Date(limite).getTime(),
            factura,
            comentarios,
            rfc,
            idcfdi: idcfdi !== undefined ? idcfdi : "",
            imagenID: imagenID !== undefined ? imagenID : [],
            telefono: telefono !== undefined ? telefono : "",
            email: email !== undefined ? email : "",
            direccionFactura,
            cfdi,
            formaPago,
            metodoPago,
            costoExtra,
            repartidor,
            sucursal,
            produccion: sucursal,
            registro: displayName,
            vendedor: vendedor !== undefined ? vendedor : false,
            vendedorInfo: vendedorInfo !== undefined ? vendedorInfo : [],
            fecha: new Date().getTime(),
            status: repartidor === "Libre" ? "nuevo" : "asignado",
            imagenes,
            listo: repartidor === "Libre" ? "Nuevo" : "No",
            numeroPedido: "S/N",
            pagado: "No",
            detallesPago: {
              cantidad: 0,
              metodo: "",
              fecha: "",
              transferencia: "",
              cotejado: false,
              documento: false
            }
        }

        
        console.log("Valores: ", nuevoPedido);


        if (tipo === "Cliente" && currentUserDocID !== "B8ulCbQyT5tUif3WxyAy") {

          if (!datosFactura && nuevoPedido.factura === "Sí") {

            const usuarioAct = {
              datosFactura: true,
              rfc: nuevoPedido.rfc,
              idcfdi: nuevoPedido.idcfdi,
              imagenidcfdi: nuevoPedido.imagenID
            }
            const usuarioRef = doc(FirebaseDB, "usuarios", currentUserDocID)
            // const pedidoDoc = doc(FirebaseDB, "pedidos", id);
            try {
              await updateDoc(usuarioRef, {...usuarioAct})
              Swal.fire( 'Usuario Actualizado','Se agregaron los datos de facturación', 'success');
            } catch (error) {
              console.log(error);
            }
          }

        }




        const newDoc = doc(collection(FirebaseDB, `/pedidos`))
        // await setDoc(newDoc, nuevoPedido);
        // Swal.fire( 'Pedido Registrado','Tu pedido ha sido registrado satisfactoriamente', 'success');

        try {
          await setDoc(newDoc, nuevoPedido);
          Swal.fire( 'Pedido Registrado','Tu pedido ha sido registrado satisfactoriamente', 'success');
          sendNotificationsNuevoPedido(nuevoPedido.cliente, nuevoPedido.repartidor);
          dispatch(limpiarImagenes());
          dispatch(limpiarImagenIdcfdi())
          if (currentUserDocID === "B8ulCbQyT5tUif3WxyAy") {
            dispatch(logout("¡Gracias por tu pedido, vuelve pronto!"))
          }
        } catch (error) {
          console.log(error);
          Swal.fire( 'Error al registrar', '', 'error');
          dispatch(limpiarImagenes());
          if (currentUserDocID === "B8ulCbQyT5tUif3WxyAy") {
            dispatch(logout("Algo salió mal, intenta nuevamente."))
          }
        }


    }
}

export const startCargandoPedidosPendientes = () => {
    return async (dispatch, getState) => {

        const collectionRef = collection(FirebaseDB, '/pedidos');
        const { displayName, perfil } = getState().auth;
        const q =  query(collectionRef, where("status", "!=", "completo"), orderBy("status"), orderBy("limite", "asc")); //Aqui van los filtros
    
        onSnapshot(q, (snapshot) => {
            let pedidos = [];
            let pendientes = [];
            snapshot.docs.forEach((doc) => {
                pedidos.push({uid: doc.id, ...doc.data()});
            })

            if (displayName === "Suc Villa") {
              pendientes = pedidos.filter( pedido => (pedido.produccion === "Suc Villa" || pedido.registro === "Suc Villa") && (pedido.status === 'pendiente' || pedido.status === 'produccion'))
            } else if ( perfil === "Vendedor") {
              pendientes = pedidos.filter (pedido => pedido.vendedorInfo?.nombre === displayName && pedido.status !== "nuevo");
            } else {
              pendientes = pedidos.filter( pedido => pedido.status === 'pendiente' || pedido.status === 'produccion')
            }

            dispatch(cargarPedidosPendientes(pendientes));
        } )

    }
}

export const startCargandoPedidosSinEntregar = () => {
  return async (dispatch) => {

      const collectionRef = collection(FirebaseDB, '/pedidos');
      const q =  query(collectionRef, where("status", "!=", "completo"), orderBy("status"), orderBy("limite", "asc")); //Aqui van los filtros
  
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })
          dispatch(cargarPedidosSinEntregar(pedidos));
      } )

  }
}

export const startCargandoTodosPedidos = (desde, hasta) => {
  return async (dispatch) => {

      const collectionRef = collection(FirebaseDB, '/pedidos');

      const q =  query(collectionRef, where("fechaEntrega", ">=", desde), where("fechaEntrega", "<=", hasta), orderBy("fechaEntrega", "desc")); //Aqui van los filtros

   
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })
          dispatch(cargarTodosPedidos(pedidos));
          setTimeout(() =>{
            document.getElementById("filtrarFechasEstadisticas").click();
          }, 500)
      } )

  }
}

export const startCargandoPedidosNuevos = () => {
  return async (dispatch, getState) => {
      const { perfil, displayName } = getState().auth;
      const collectionRef = collection(FirebaseDB, '/pedidos');
      const q =  query(collectionRef, where("status", "!=", "completo"), orderBy("status"), orderBy("limite", "asc")); //Aqui van los filtros
  
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          let nuevos = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })

          if (displayName === "Suc Villa"){
            nuevos = pedidos.filter( pedido => pedido.produccion === "Suc Villa" || pedido.registro === "Suc Villa");
          } else if (perfil === "Vendedor") {
            nuevos = pedidos.filter( pedido => pedido.vendedorInfo?.nombre === displayName && pedido.status === "nuevo");
          } else {
            nuevos = pedidos.filter( pedido => ((perfil === "Produccion" || perfil === "Administrador" || perfil === "Empleado" || perfil === "Sucursal" || perfil === "Repartidor") && pedido.status === "nuevo") || ((perfil === "Produccion" || perfil === "Administrador" || perfil === "Empleado" || perfil === "Sucursal") && displayName === pedido.produccion && ( pedido.listo === "No" || pedido.listo === "Incompleto" )));
          }


          // nuevos = pedidos.filter( pedido => ((perfil === "Produccion" || perfil === "Administrador" || perfil === "Empleado" || perfil === "Sucursal") && pedido.status === "nuevo") || ((perfil === "Produccion" || perfil === "Administrador" || perfil === "Empleado" || perfil === "Sucursal") && displayName === pedido.produccion && ( pedido.listo === "No" || pedido.listo === "Incompleto" )));
          dispatch(cargarPedidosNuevos(nuevos));
      } )

  }
}

export const startCargandoPedidosAsignados = () => {
  return async (dispatch, getState) => {

      const { displayName, perfil } = getState().auth;
      // const pedidos = await cargarAsignados(perfil, displayName);
      // dispatch(cargarPedidosAsignados(pedidos));
      const collectionRef = collection(FirebaseDB, '/pedidos');

      let q =  query(collectionRef, where("repartidor", "!=", "Libre")); //Aqui van los filtros

  
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          let asignados = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })
          if (displayName === "Suc Villa"){
            asignados = pedidos.filter( pedido => (pedido.produccion === "Suc Villa" || pedido.registro === "Suc Villa") && ( pedido.status === "asignado" || pedido.status === "ruta" || pedido.status === "parcial"));
          } else if ( perfil === "Vendedor") {
            asignados = pedidos.filter (pedido => pedido.vendedorInfo?.nombre === displayName && (pedido.status === "asignado" || pedido.status === "ruta" || pedido.status === "parcial"));
          } else {
            asignados = pedidos.filter( pedido => pedido.status === "asignado" || pedido.status === "ruta" || pedido.status === "parcial");
          }

          dispatch(cargarPedidosAsignados(asignados));
      });
  }
}

export const startCargandoPedidosCompletos = (desde, hasta) => {
  return async (dispatch, getState) => {

    // const { perfil, displayName } = getState().auth;

    // let q = null;
    // const collectionRef = collection(FirebaseDB, '/pedidos');
    // if (perfil === "Repartidor") {
    //      q =  query(collectionRef, where("repartidor", "==", displayName),  where("status", "==", "completo"), orderBy("fechaEntrega", "desc")); //Aqui van los filtros
    // } else if (perfil === "Produccion" || perfil === "Sucursal") {
    //      q =  query(collectionRef, where("produccion", "==", displayName),  where("status", "==", "completo"), orderBy("fechaEntrega", "desc"));
    // }  else {
    //      q =  query(collectionRef, where("status", "==", "completo"), orderBy("fechaEntrega", "desc")); //Aqui van los filtros
    // }

    const collectionRef = collection(FirebaseDB, '/pedidos');
    const q =  query(collectionRef, where("status", "==", "completo"), where("fechaEntrega", ">=", desde), where("fechaEntrega", "<=", hasta), orderBy("fechaEntrega", "desc")); //Aqui van los filtros

    onSnapshot(q, (snapshot) => {
      let pedidos = [];
      snapshot.docs.forEach((doc) => {
          pedidos.push({uid: doc.id, ...doc.data()});
      })
      dispatch(cargarPedidosCompletos(pedidos));
      
      setTimeout(() =>{
        document.getElementById("filtrarFechas").click();
      }, 500)
  } )


  }
}

export const startCargandoPedidosNoPagados = () => {
  return async (dispatch, getState) => {

    console.log("VOY A VOLVER A CARGAR LA BD");

    const collectionRef = collection(FirebaseDB, '/pedidos');
    const q =  query(collectionRef, where("status", "==", "completo"), where("pagado", "==", "No"), orderBy("fechaEntrega", "desc")); //Aqui van los filtros

    onSnapshot(q, (snapshot) => {
      let pedidos = [];
      snapshot.docs.forEach((doc) => {
          pedidos.push({uid: doc.id, ...doc.data()});
      });

      let noPagados = pedidos.filter( pedido => pedido.detallesPago.documento);

      dispatch(cargarPedidosNoPagados(noPagados));


      // Swal.fire('¡Importante!', 'La lista de NO PAGADOS es completa, no toma en cuenta los filtros de fecha, repartidores o tipo de pedido.', 'warning');

  } )


  }
}

export const startCargandoSinDocumento = () => {
  return async (dispatch, getState) => {


    const collectionRef = collection(FirebaseDB, '/pedidos');
    const q =  query(collectionRef, where("status", "==", "completo"), where("pagado", "==", "No"), orderBy("fechaEntrega", "desc")); //Aqui van los filtros

    onSnapshot(q, (snapshot) => {
      let pedidos = [];
      snapshot.docs.forEach((doc) => {
          pedidos.push({uid: doc.id, ...doc.data()});
      });

      let sinDocumento = pedidos.filter( pedido => !pedido.detallesPago.documento);

      dispatch(cargarPedidosNoPagados(sinDocumento));

  } )


  }
}



export const startAsignacionProduccion = (id, productor) => {
  return async (dispatch, getState) => {


      const pedidoDoc = doc(FirebaseDB, "pedidos", id);
      const productorAsignado = {produccion: productor, status: 'produccion', listo: "No", sucursal: productor};

      const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
          },
          buttonsStyling: false
        })
        
        swalWithBootstrapButtons.fire({
          title: '¿Quieres tomar este pedido?',
          text: "Quedarás registrado como el responsable de la producción del pedido",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, tomar',
          cancelButtonText: 'No, cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
           updateDoc(pedidoDoc, productorAsignado);
            swalWithBootstrapButtons.fire(
              'Tomado',
              'Has tomado este pedido para producción',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
              return;
          }
        });

  }
}

export const startMaterialIncompleto = ( id ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Falta material?',
      text: "Confirmar que falta material para completar este pedido",
      icon: 'warning',
      html: '<textarea id="observacionesIncompleto" name="observacionesIncompleto" class="modal__parcialObservaciones" placeholder="Descripción de faltantes"></textarea>',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        let textAreaValue = document.getElementById("observacionesIncompleto").value;
        updateDoc(pedidoDoc, {incompletoObs: textAreaValue, listo: "Incompleto", produccionEnTiempo: true});
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Pedido se reporta como incompleto',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}

export const startPedidoListo = ( id, repatidor, limite, listo, displayName ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);

    let fechaEntrega = new Date().getTime();
    let produccionEnTiempo;
    let limiteProduccion = limite - 3600000;
    if (fechaEntrega < limiteProduccion ) {
      produccionEnTiempo = true;
    } else {
      produccionEnTiempo = false;
    }

    let nuevoStatus = "pendiente";
    let nuevoLimite = limite;
    let repartidorFinal = repatidor;
    const ahora = new Date().getTime() + 18000000;
    if ( repatidor !== "Libre" ) {
      nuevoStatus = "asignado";
    }
    if (repatidor === "Cliente") {
      repartidorFinal = displayName;
    }

    if ( listo === "Incompleto" && ahora > limite) {
        nuevoLimite = ahora;     
    }

    const statusNuevo = {status: nuevoStatus, listo: "Sí", limite: nuevoLimite, repartidor: repartidorFinal, produccionEnTiempo};

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Pedido listo para recolección?',
      text: "Confirmar que este pedido está listo para recolección",
      html: '<input type="text" id="numeroPedido" name="numeroPedido" required class="modal__inputNumeroPedido" placeholder="Anota el número de pedido o factura"></textarea>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const numeroPedido = document.getElementById("numeroPedido").value;
        if (!numeroPedido) {
          // alert("Por favor ingresa el número de pedido o número de factura para continuar")
          Swal.fire('', 'Por favor ingresa el número de pedido o número de factura para continuar', 'warning');
          return;
        }
       updateDoc(pedidoDoc, {...statusNuevo, numeroPedido});
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Este pedido se encuentra pendiente de recolección',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}




export const startAsignacionPedido = (id, repartidor) => {
    return async (dispatch, getState) => {


        const pedidoDoc = doc(FirebaseDB, "pedidos", id);
        const repatidorAsignado = {repartidor, status: 'asignado'};

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: '¿Quieres asignarte este pedido?',
            text: "Quedarás registrado como el responsable de la entrega",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, asígnar',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
             updateDoc(pedidoDoc, repatidorAsignado);
              swalWithBootstrapButtons.fire(
                'Asignado',
                'Este pedido se ta ha asignado',
                'success'
              )
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
          });

    }
}


export const startCambiarStatusRutaEntrega = ( id ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);
    const statusNuevo = {status: 'ruta'};

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Pedido en ruta de entrega?',
      text: "confirmar que este pedido está en ruta de entrega",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
       updateDoc(pedidoDoc, statusNuevo);
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Este pedido se encuentra en ruta de entrega',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}


export const startCompletarEntrega = ( id, limite ) => {
  return async (dispatch, getState) => {
    const { loading } = getState().pedidos;
    console.log("Status: ", loading)
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);
    let fechaEntrega = new Date().getTime();
    let entregaStatus = "";
    let enTiempo;
    if (fechaEntrega < limite ) {
      entregaStatus = "En tiempo";
      enTiempo = true;
    } else {
      entregaStatus = "Fuera de tiempo";
      enTiempo = false;
    }

    const statusNuevo = {status: 'completo', fechaEntrega, entregaStatus, enTiempo };

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })





    
    swalWithBootstrapButtons.fire({
      title: '¿Completar el pedido?',
      text: "Confirmar que este pedido fue entregado con éxito",
      html: '<div id="modal__evidenciaContainer"><label id="modal__evidenciaLabel">Evidencia de entrega: </label> <input type="file" id="imagen-completarPedido" multiple name="imagen-completarPedido" className="modal__input-file">   <label id="modal__cantidadLabel">Cantidad a pagar:</label> <input id="cantidad" type="number" placeholder="$" min="0" />  <select name="metodo" id="metodo"> <option value="" selected>Método de Pago</option> <option value="Efectivo">Efectivo</option> <option value="Transferencia">Transferencia</option> <option value="Cheque">Cheque</option> <option value="Otro">Otro</option> </select>  </div>    <textarea id="observacionesCompletado" name="observacionesCompletado" class="modal__parcialObservaciones" placeholder="Anota las observaciones del caso"></textarea>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then( async (result) => {
     
     
      if (result.isConfirmed) {

     dispatch(isLoading(true));
      let textAreaValue = document.getElementById("observacionesCompletado").value;
      let cantidadValue = document.getElementById("cantidad").value;
      let metodoValue = document.getElementById("metodo").value;
      const imagenesInput = document.getElementById("imagen-completarPedido").files;


      if (cantidadValue < 1) {
        Swal.fire('', 'Se require una cantidad válida', 'warning');
        dispatch(isLoading(false));
        return;
      };

      if (metodoValue === "") {
        Swal.fire('', 'Se require definir método de pago', 'warning');
        dispatch(isLoading(false));
        return;
      };

        if (imagenesInput.length === 0) {
          Swal.fire('', 'Se require imágen de la entrega', 'warning');
          dispatch(isLoading(false));
          return;
        };

        const fileUploadPromises = [];

        for (const file of imagenesInput) {
           fileUploadPromises.push( fileUpload(file));
        }
     
        const evidenciaEntrega = await Promise.all( fileUploadPromises );






        if (evidenciaEntrega.length === 0) {
          Swal.fire('', 'Se require imágen de la entrega', 'warning');
          dispatch(isLoading(false));
          return;
        };

        
       

      let observacionesFinales;
      if (textAreaValue === '') {
        observacionesFinales = "Sin observaciones";
      } else {
        observacionesFinales = textAreaValue;
      }

      let statusPago; 

      if (metodoValue === "Efectivo") {
        statusPago = {
          pagado: "Sí",
          detallesPago: {
            cantidad: cantidadValue,
            metodo: metodoValue,
            fecha: Date.now(),
            transferencia: "NA",
            cotejado: false,
            documento: false
          }
        }
      } else {
        statusPago = {
          pagado: "No",
          detallesPago: {
            cantidad: cantidadValue,
            metodo: metodoValue,
            fecha: "",
            transferencia: "",
            coejado: false,
            documento: false
          }
        }
      }

       updateDoc(pedidoDoc, {...statusNuevo, ...statusPago, comentariosFinales: observacionesFinales, evidenciaEntrega})
          .then( () => {
            dispatch(isLoading(false));
            swalWithBootstrapButtons.fire('Confirmado','Pedido Entregado con Éxito', 'success');
          }).catch (err => {
            console.log(err);
            dispatch(isLoading(false));
          });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }

 }

export const startCierreParcial = ( id, limite ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Cerrar parcialmente el pedido?',
      text: "Confirmar que este pedido fue entregado parcialmente",
      icon: 'warning',
      html: '<textarea id="observacionesParcial" name="observacionesParcial" class="modal__parcialObservaciones" placeholder="Anota las observaciones del caso"></textarea>',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        let textAreaValue = document.getElementById("observacionesParcial").value;

        let fechaEntrega = new Date().getTime();
        let entregaStatus;
        if (fechaEntrega < limite ) {
          entregaStatus = "Entrega parcial en tiempo";
        } else {
          entregaStatus = "Entrega parcial fuera de tiempo";
        }

        updateDoc(pedidoDoc, {status: 'parcial', fechaEntrega, entregaStatus, comentariosParcial: textAreaValue});
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Pedido cerrado parcialmente',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}


export const startUploadingFiles = (files = []) => {
  return async(dispatch, getState) => {

   const fileUploadPromises = [];

   for (const file of files) {
      fileUploadPromises.push( fileUpload(file));
   }

   dispatch(isLoading(true));

   const photosUrls = await Promise.all( fileUploadPromises );

   dispatch(isLoading(false));

   dispatch(cargarImagenes(photosUrls));
   
   

  }
}

export const startUploadingIdcfdi = (files = []) => {
  return async(dispatch, getState) => {

   const fileUploadPromises = [];

   for (const file of files) {
      fileUploadPromises.push( fileUpload(file));
   }

   dispatch(isLoading(true));

   const photosUrls = await Promise.all( fileUploadPromises );

   dispatch(isLoading(false));


   dispatch(cargarImagenIdcfdi(photosUrls));
   
   

  }
}

export const startUploadingEvidence = (files = []) => {
  return async() => {

   const fileUploadPromises = [];

   for (const file of files) {
      fileUploadPromises.push( fileUpload(file));
   }

   const photosUrls = await Promise.all( fileUploadPromises );
   
   return photosUrls;

  }
}


export const startModificarPedido = ( pedido, id ) => {
  return async (dispacth) => {

    const docRef = doc( FirebaseDB, `pedidos/${ id }`)
    const nuevoLimite = isNaN(pedido.limite) ? new Date(pedido.limite).getTime() : pedido.limite;
    if (pedido.factura === 'No') {
      pedido.direccionFactura = '';
      pedido.rfc = '';
    }
    delete pedido.limite;
    const pedidoAct = {
      status: pedido.repartidor === "Libre" ? "nuevo" : "asignado",
      limite: nuevoLimite,
      produccion: pedido.sucursal,
      ...pedido
    }

    await setDoc(docRef, pedidoAct, {merge: true});
    Swal.fire( 'Pedido Modificado','Tu pedido ha sido modificado satisfactoriamente', 'success');
    dispacth(limpiarPedidoSeleccionado());
    dispacth(limpiarImagenes());
    
  }
}

export const startModificarPedidoCliente = ( pedido, id ) => {
  return async (dispacth) => {

    const docRef = doc( FirebaseDB, `pedidos/${ id }`)
    if (pedido.factura === 'No') {
      pedido.direccionFactura = '';
      pedido.rfc = '';
    }
    const pedidoAct = {
      ...pedido
    }

    await setDoc(docRef, pedidoAct, {merge: true});
    Swal.fire( 'Pedido Modificado','Tu pedido ha sido modificado satisfactoriamente', 'success');
    dispacth(limpiarPedidoSeleccionado());
    dispacth(limpiarImagenes());
    
  }
}

export const startActualizarPagos = ( pedido, id, informacionPago, cotejado, noPagadosFlag ) => {
  return async (dispacth) => {

  
    const docRef = doc( FirebaseDB, `pedidos/${ id }`)
    let date = new Date(informacionPago.fechaPago).getTime() + 36000000;


    

    await updateDoc(docRef, {
      pagado: informacionPago.pagado,
      detallesPago: {
        cantidad: informacionPago.cantidad,
        metodo: informacionPago.metodoPago,
        fecha: date,
        transferencia: informacionPago.transferencia,
        cotejado,
        documento: true
    },
    }) 
    .then(() => {
      if (!noPagadosFlag) {
        document.getElementById("filtrarFechas").click();
      }
    })

    Swal.fire( 'Actualizado','Se actualizaron los datos repecto al pago de este pedido', 'success')
    dispacth(limpiarPedidoSeleccionado());


    
  }
}

export const startCotejarPago = ( id, informacionPago ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);
    let nuevosDetalles = {
      cantidad: informacionPago.cantidad,
      metodo: informacionPago.metodo,
      fecha: informacionPago.fecha,
      transferencia: informacionPago.transferencia,
      cotejado: true,
      documento: true
    }
    

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Deseas cotejar?',
      text: "Confirmar que se entregó el pago de este pedido",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, cotejar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
        console.log("Nuevos Detalles: ", nuevosDetalles);
             
        updateDoc(pedidoDoc, {detallesPago: nuevosDetalles}).then( () => {
          document.getElementById("filtrarFechas").click();
        })
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'El pago de este pedido ha sido cotejado',
          'success'
        )

        
          
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}

export const startEntregarDocumento = ( id, informacionPago ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);
    let nuevosDetalles = {
      cantidad: informacionPago.cantidad,
      metodo: informacionPago.metodo,
      fecha: informacionPago.fecha,
      transferencia: informacionPago.transferencia,
      cotejado: false,
      documento: true
    }
    

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Se entregó el documento?',
      text: "Confirmar que se entregó el documento de este pedido",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, se entregó',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
             
        updateDoc(pedidoDoc, {detallesPago: nuevosDetalles}).then( () => {
          console.log("Hola, pase por aqui");
          document.getElementById("filtrarFechas").click();
        })
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'El pago de este pedido ha sido cotejado',
          'success'
        )

        
          
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}



export const startEliminarPedido = ( id ) => {
  return ( dispatch ) => {
    const docRef = doc( FirebaseDB, `pedidos/${ id }`)

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Eliminar pedido?',
      text: "Confirmar que deseas eliminar este pedido",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
       deleteDoc(docRef);
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Este pedido ha sido eliminado',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });

  }
}

export const startActivarNotificaciones = () => {
  return async () => {
    const currentToken = await obtenerToken();
    console.log(currentToken);
 }
}


export const startSuscripcion = () => {
  return async () => {

  suscribe();

 }
}


export const startNuevoGastoExtra = (valores) => {
  return async (dispatch, getState) => {

      const { displayName } = getState().auth;
      const {cantidad,
             concepto,
             evidencia, 
            } = valores;

      const nuevoGasto = {
          cantidad,
          concepto,
          evidencia,
          fecha: new Date().getTime(),
          repartidor: displayName
          }




      const newDoc = doc(collection(FirebaseDB, `/gastos`))
              // await setDoc(newDoc, nuevoPedido);
              // Swal.fire( 'Pedido Registrado','Tu pedido ha sido registrado satisfactoriamente', 'success');


        await setDoc(newDoc, nuevoGasto).then(() => {
          Swal.fire( 'Gasto Extra Registrado','Tu gasto extra ha sido registrado satisfactoriamente', 'success');
          // sendNotificationsNuevoPedido(nuevoPedido.cliente, nuevoPedido.repartidor);
          dispatch(limpiarImagenes());
          document.getElementById("filtrarGastos").click();
        }).catch( (error) => {
          console.log(error);
          Swal.fire( 'Error al registrar', '', 'error');
          dispatch(limpiarImagenes());
        })

   
  



  }
}

export const startCargandoGastosExtras = (desde, hasta) => {
  return async (dispatch, getState) => {

      const collectionRef = collection(FirebaseDB, '/gastos');
      const { displayName, perfil } = getState().auth;
      let q = query(collectionRef, where("fecha", ">=", desde), where("fecha", "<=", hasta), orderBy("fecha", "desc"));
      if ( perfil === "Repartidor") {
         q =  query(collectionRef, where("repartidor", "==", displayName), where("fecha", ">=", desde), where("fecha", "<=", hasta), orderBy("fecha", "desc"), limit(50)); //Aqui van los filtros
      }


  
      onSnapshot(q, (snapshot) => {
          let gastos = [];
          snapshot.docs.forEach((doc) => {
              gastos.push({uid: doc.id, ...doc.data()});
          })
          dispatch(gatosExtras(gastos));
          setTimeout(() =>{
            document.getElementById("filtrarGastos").click();
          }, 500)
      } )

  }
}


export const startEliminarGastoExtra = ( id ) => {
  return ( dispatch ) => {
    const docRef = doc( FirebaseDB, `gastos/${ id }`)

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Eliminar gasto extra?',
      text: "Confirmar que deseas eliminar este gasto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, confirmar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
       deleteDoc(docRef).then(()=> {
        document.getElementById("filtrarGastos").click();
       })
       
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Este gasto ha sido eliminado',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });

  }
}

export const startCotejarPagoExtra = ( id ) => {
  return async (dispatch, getState) => {
    const gastoDoc = doc(FirebaseDB, "gastos", id);


    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Se entregó el recibo?',
      text: "Confirmar que se entregó el recibo de este gasto extra",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, se entregó',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
             
        updateDoc(gastoDoc, {cotejado: true}).then( () => {
          document.getElementById("filtrarGastos").click();
        })
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'El gasto extra ha sido cotejado',
          'success'
        )

        
          
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}

export const startComprobarEvidencia = ( id ) => {
  return async (dispatch, getState) => {
    const pedidoDoc = doc(FirebaseDB, "pedidos", id);
    // const statusNuevo = {evidenciaComprobada: true};

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
        denyButton: 'btn btn-warning'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Evidencia correcta?',
      text: "Confirmar que se revisó la evidencia y está correcta",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sí, correcta',
      denyButtonText: `No, incorrecta`,
      cancelButtonText: 'Cancelar',
      reverseButtons: false
    }).then((result) => {
      if (result.isConfirmed) {
       updateDoc(pedidoDoc, {evidenciaComprobada: true, evidenciaCorrecta: true});
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Se confirmó que la evidencia es correcta',
          'success'
        )
      } else if (result.isDenied) {
        updateDoc(pedidoDoc, {evidenciaComprobada: true, evidenciaCorrecta: false});
        swalWithBootstrapButtons.fire(
          'Confirmado',
          'Se confirmó que la evidencia no existe o contiene errores',
          'warning'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}


export const startCargandoPedidosCliente = () => {
  return async (dispatch, getState) => {

      const { displayName } = getState().auth;

      if (displayName === null) {
        return;
      }
      // const pedidos = await cargarAsignados(perfil, displayName);
      // dispatch(cargarPedidosAsignados(pedidos));
      const collectionRef = collection(FirebaseDB, '/pedidos');

      let q =  query(collectionRef, where("registro", "==", displayName), where("status", "!=", "completo")); //Aqui van los filtros

  
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          // let asignados = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })

          dispatch(cargarPedidosCliente(pedidos));
      });
  }
}

export const startCargandoPedidosVendedores = (desde, hasta) => {
  return async (dispatch) => {

      const collectionRef = collection(FirebaseDB, '/pedidos');

      const q =  query(collectionRef, where("fechaEntrega", ">=", desde), where("fechaEntrega", "<=", hasta), where("vendedor", "==", true),orderBy("fechaEntrega", "desc")); //Aqui van los filtros

   
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })
          dispatch(cargarPedidosVendedores(pedidos));
          setTimeout(() =>{
            document.getElementById("filtrarFechasEstadisticas").click();
          }, 500)
      } )

  }
}

export const startCargandoPedidosNoCotejados = ( vendedor ) => {
  return async (dispatch) => {

      const collectionRef = collection(FirebaseDB, '/pedidos');

      const q =  query(collectionRef, where("vendedorInfo.nombre", "==", vendedor), where("detallesPago.cotejado", "==", false),orderBy("fechaEntrega", "desc")); //Aqui van los filtros

   
      onSnapshot(q, (snapshot) => {
          let pedidos = [];
          snapshot.docs.forEach((doc) => {
              pedidos.push({uid: doc.id, ...doc.data()});
          })
          dispatch(cargarPedidosNoCotejados(pedidos));
          setTimeout(() =>{
            document.getElementById("filtrarFechasEstadisticas").click();
          }, 500)
      } )

  }
}

export const startCargarVendedores = () => {
  return async (dispatch) => {

      const collectionRef = collection(FirebaseDB, '/vendedores');

      const q =  query(collectionRef); //Aqui van los filtros

   
      onSnapshot(q, (snapshot) => {
          let vendedores = [];
          snapshot.docs.forEach((doc) => {
              vendedores.push({id: doc.id, ...doc.data()});
          })
          dispatch(cargarVendedores(vendedores));
      } )

  }
}

export const startModificarComision = ( actual, id ) => {
  return async (dispatch, getState) => {
    const vendedorDoc = doc(FirebaseDB, "vendedores", id);
    const inputValue = actual;
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Modificar Comisión?',
      text: "Cambiar la comisión de este vendedor",
      icon: 'warning',
      input: "text",
      inputLabel: "Nueva comisión: ",
      inputValue,
      showCancelButton: true,
      confirmButtonText: 'Sí, modificar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result, value) => {
      if (result.isConfirmed) {
              
        let valor = document.getElementById("swal2-input").value;
        let nuevaComision = parseFloat(valor);
             
        updateDoc(vendedorDoc, {comision: nuevaComision});

        swalWithBootstrapButtons.fire(
          'Confirmado',
          'La comisión de este vendedor ha sido modificada',
          'success'
        )

        
          
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}

export const startModificarMeta = ( actual, id ) => {
  return async (dispatch, getState) => {
    const vendedorDoc = doc(FirebaseDB, "vendedores", id);
    const inputValue = actual;
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Modificar Meta mensual?',
      text: "Cambiar la meta mensual de este vendedor",
      icon: 'warning',
      input: "text",
      inputLabel: "Nueva meta mensual: ",
      inputValue,
      showCancelButton: true,
      confirmButtonText: 'Sí, modificar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result, value) => {
      if (result.isConfirmed) {
              
        let valor = document.getElementById("swal2-input").value;
        let nuevaMeta = parseFloat(valor);
             
        updateDoc(vendedorDoc, {meta: nuevaMeta});

        swalWithBootstrapButtons.fire(
          'Confirmado',
          'La meta mensual de este vendedor ha sido modificada',
          'success'
        )

        
          
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
          return;
      }
    });
  }
}
