import { createSlice } from "@reduxjs/toolkit";


export const pedidosSlice = createSlice({
    name: 'pedidos',
    initialState: {
        todosPedidos: [],
        pedidosPendientes: [],
        pedidosNuevos: [],
        pedidosAsignados: [],
        pedidosCliente: [],
        pedidosCompletos: [],
        pedidosNoPagados: [],
        pedidosNoCotejados: [],
        imagenes: [],
        imagenID: [],
        pedidosSinEntregar: [],
        gastosExtras: [],
        vendedores: [],
        loading: false,
        pedidoModificar: {
            cliente: '',
            tipo: '',
            direccion: '',
            municipio: '',
            rangoPrecio: '',
            tipoPedido: '',
            pedido: '',
            imagenes: [],
            limite: '',
            comentarios: '',
            factura: '',
            formaPago: '',
            costoExtra: '',
            rfc: '',
            direccionFactura: '',
            cfdi: '',
            repartidor: '',
            sucursal: '',
            uid: '',
            pagado: 'No',   
            detallesPago: {
                cantidad: 0,
                metodo: "",
                fecha: "",
                transferencia: ""
            }

        },
    },
    reducers: {
        pedidoSeleccionado: (state, action) => {
            state.pedidoModificar.cliente = action.payload.cliente;
            state.pedidoModificar.tipo = action.payload.tipo;
            state.pedidoModificar.direccion = action.payload.direccion;
            state.pedidoModificar.municipio = action.payload.municipio;
            state.pedidoModificar.rangoPrecio = action.payload.rangoPrecio;
            state.pedidoModificar.tipoPedido = action.payload.tipoPedido;
            state.pedidoModificar.pedido = action.payload.pedido;
            state.pedidoModificar.imagenes = action.payload.imagenes;
            state.pedidoModificar.imagenID = action.payload.imagenID;
            state.pedidoModificar.limite = action.payload.limite;
            state.pedidoModificar.comentarios = action.payload.comentarios;
            state.pedidoModificar.factura = action.payload.factura;
            state.pedidoModificar.formaPago = action.payload.formaPago;
            state.pedidoModificar.metodoPago = action.payload.metodoPago;
            state.pedidoModificar.costoExtra = action.payload.costoExtra;
            state.pedidoModificar.rfc = action.payload.rfc;
            state.pedidoModificar.direccionFactura = action.payload.direccionFactura;
            state.pedidoModificar.cfdi = action.payload.cfdi;
            state.pedidoModificar.repartidor = action.payload.repartidor;
            state.pedidoModificar.sucursal = action.payload.sucursal;
            state.pedidoModificar.uid = action.payload.uid;
            state.pedidoModificar.pagado = action.payload.pagado;
            state.pedidoModificar.detallesPago.cantidad = action.payload.detallesPago?.cantidad;
            state.pedidoModificar.detallesPago.fecha = action.payload.detallesPago?.fecha;
            state.pedidoModificar.detallesPago.metodo = action.payload.detallesPago?.metodo;
            state.pedidoModificar.detallesPago.transferencia = action.payload.detallesPago?.transferencia;

        },
        limpiarPedidoSeleccionado: (state, action) => {
            state.pedidoModificar.cliente = '';
            state.pedidoModificar.tipo = '';
            state.pedidoModificar.direccion = '';
            state.pedidoModificar.municipio = '';
            state.pedidoModificar.rangoPrecio = '';
            state.pedidoModificar.tipoPedido = '';
            state.pedidoModificar.pedido = '';
            state.pedidoModificar.imagenes = [];
            state.pedidoModificar.imagenID = [];
            state.pedidoModificar.limite = '';
            state.pedidoModificar.comentarios = '';
            state.pedidoModificar.factura = '';
            state.pedidoModificar.formaPago = '';
            state.pedidoModificar.metodoPago = '';
            state.pedidoModificar.costoExtra = '';
            state.pedidoModificar.rfc = '';
            state.pedidoModificar.direccionFactura = '';
            state.pedidoModificar.cfdi = '';
            state.pedidoModificar.repartidor = '';
            state.pedidoModificar.sucursal = '';
            state.pedidoModificar.uid = '';
            state.pedidoModificar.pagado = "";
            // state.pedidoModificar.detallesPago.cantidad = 0;
            // state.pedidoModificar.detallesPago.fecha = "";
            // state.pedidoModificar.detallesPago.metodo = "";
            // state.pedidoModificar.detallesPago.transferencia = "";
        },
        cargarPedidosPendientes: (state, action) => {
            state.pedidosPendientes = action.payload;
        },

        cargarTodosPedidos: (state, action) => {
            state.todosPedidos = action.payload;
        },
        cargarPedidosSinEntregar: (state, action) => {
            state.pedidosSinEntregar = action.payload;
        },
        cargarPedidosNuevos: (state, action) => {
            state.pedidosNuevos = action.payload;
        },
        cargarPedidosAsignados: (state, action) => {
            state.pedidosAsignados = action.payload;
        },
        cargarPedidosCliente: (state, action) => {
            state.pedidosCliente = action.payload;
        },
        cargarPedidosCompletos: (state, action) => {
            state.pedidosCompletos = action.payload;
        },
        cargarPedidosNoPagados: (state, action) => {
            state.pedidosNoPagados = action.payload;
        },
        cargarPedidosNoCotejados: (state, action) => {
            state.pedidosNoCotejados = action.payload;
        },
        cargarImagenes: (state, action) => {
            state.imagenes = action.payload;
        },
        cargarImagenIdcfdi: (state, action) => {
            state.imagenID = action.payload;
        },
        limpiarImagenes: (state, action) => {
            state.imagenes = [];
        },
        limpiarImagenIdcfdi: (state, action) => {
            state.imagenID = [];
        },
        isLoading: (state, action) => {
            state.loading = action.payload;
        },
        eliminarPedido: (state, action) => {

        },
        gatosExtras: (state, action) => {
            state.gastosExtras = action.payload;
        },
        cargarPedidosVendedores: (state, action) => {
            state.pedidosVendedores = action.payload;
        },
        cargarVendedores: (state, action) => {
            state.vendedores = action.payload;
        },
    }
});


export const { generarNuevoPedido,
               cargarPedidosPendientes, 
               cargarPedidosNuevos,
               cargarPedidosAsignados, 
               cargarPedidosCliente,
               cargarPedidosCompletos, 
               cargarPedidosNoPagados,
               cargarPedidosNoCotejados,
               actualizarPedido, 
               eliminarPedido, 
               cargarImagenes, 
               cargarImagenIdcfdi,
               limpiarImagenes, 
               limpiarImagenIdcfdi,
               pedidoSeleccionado,
               limpiarPedidoSeleccionado,
               cargarPedidosSinEntregar,
               linkActual,
               cargarTodosPedidos,
               isLoading,
               gatosExtras,
               cargarVendedores,
               cargarPedidosVendedores
            } = pedidosSlice.actions;