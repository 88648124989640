import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { Formik, Field } from 'formik'
import { startCreatingUserWithEmailPassword } from '../../redux/store/auth/thunks'
import { useHistory } from "react-router-dom";


export const RegisterClientScreen = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useSelector( state => state.auth);
  const isCheckingAuthentification = useMemo( () => status === 'checking', [status]);


  return (
    <>
      <div className='auth__imgContainer'>
        <img src={logo} className='auth__logo' alt='logo' />
      </div>
      <h3 className='auth__title'>Registrar Cliente</h3>
      
      <Formik
      initialValues={{
        displayName: '',
        email: '',
        tipo: "Cliente",
        password: '',
        password2: '',
        direccion: '', 
        municipio: '',
        telefono: '',
      }}
      validate= {(valores) => {
        let errores = {};

        // Validación Nombre
        if (!valores.displayName) {
          errores.displayName = "El nombre es requerido";
        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.displayName)) {
          errores.displayName = "El nombre solo puede contener letras y espacios"
        }


        // Validación Email
        if (!valores.email) {
          errores.email = "El email es requerido";
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.email)) {
          errores.email = "El formato del email no es correcto"
        }

        // Validación Password
        if (!valores.password) {
          errores.password = "La constraseña es requerida";
        } else if (valores.password.length <= 5) {
          errores.password = "La contraseña debe ser mayor de 5 caracteres";
        }

        // Validación Password2
        if (valores.password2 !== valores.password) {
          errores.password2 = "Las contraseñas no coinciden";
        }

        if (!valores.direccion) {
          errores.direccion = "La dirección es requerida";
        } 

        if (valores.municipio === '') {
          errores.municipio = "El municipio es requerido";
        } 
        
        if (!valores.telefono) {
          errores.telefono = "El teléfono es requerido";
        } else if (valores.telefono.length !== 10) {
          errores.telefono = "El número telefónico debe ser a 10 dígitos";
        }

      // Validación Tipo
    //   if (valores.tipo === '') {
    //     errores.tipo = "El tipo de usuario es requerido";
    //   }

        return errores;
      }}
      onSubmit={(valores, {resetForm}) => {
        valores.telefono = parseInt(valores.telefono);
        resetForm();
        dispatch(startCreatingUserWithEmailPassword(valores));
        history.push("/auth/login");
      }}>

        {({ handleSubmit, values, errors, touched, handleChange, handleBlur }) => (
                <form onSubmit={handleSubmit} className="animate__animated animate__fadeIn">
                  <input
                      type="text"
                      placeholder="Nombre del Cliente"
                      className='auth__input'
                      name="displayName"
                      value={values.displayName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {touched.displayName && errors.displayName && <div className='auth__error'>{errors.displayName}</div>}

        
                  <input
                    type="email"
                    placeholder="Correo"
                    className='auth__input'
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  {touched.email && errors.email && <div className='auth__error'>{errors.email}</div>}      

       
                  <input
                    type="password"
                    placeholder="Contraseña"
                    className='auth__input'
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                   {touched.password && errors.password && <div className='auth__error'>{errors.password}</div>}        
        
                  <input
                    type="password"
                    placeholder="Confirmar Contraseña"
                    className='auth__input'
                    name="password2"
                    value={values.password2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                   {touched.password2 && errors.password2 && <div className='auth__error'>{errors.password2}</div>}      

                    <input
                      type="text"
                      placeholder="Dirección para envíos"
                      className='auth__input'
                      name="direccion"
                      value={values.direccion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    { touched.direccion && errors.direccion && <div className='auth__error'>{errors.direccion}</div>} 

                    <Field as="select" name="municipio" className='settings__selectInput' style={{marginBottom: "16px"}}>
                    <option value=''>Municipio</option>
                    <option value="Colima">Colima</option>
                    <option value="Villa de Álvarez">Villa de Álvarez</option>
                    <option value="Comala">Comala</option>
                    <option value="Cuauhtémoc">Cuauhtémoc</option>
                    <option value="Coquimatlán">Coquimatlán</option>
                    <option value="Tecomán">Tecomán</option>
                    <option value="Armería">Armería</option>
                    <option value="Ixtlahuacán">Ixtlahuacán</option>
                    <option value="Minatitlán">Minatitlán</option>
                    <option value="Manzanillo">Manzanillo</option>
                    <option value="Otro">Otro</option>
                  </Field>  
                  {touched.municipio && errors.municipio && <div style={{marginTop: '-14px'}} className='generate__error '>{errors.municipio}</div>}   

                    <input
                      type="text"
                      placeholder="Teléfono de contacto"
                      className='auth__input'
                      name="telefono"
                      value={values.telefono}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                     {touched.telefono && errors.telefono && <div className='auth__error'>{errors.telefono}</div>}   

                  <Field style={{display: "none"}} as="select" name="tipo" className='settings__selectInput'>
                    <option value='Clinte'>Cliente</option>
                  </Field>  
                  {touched.tipo && errors.tipo && <div className='auth__error'>{errors.tipo}</div>}   

                  <button
                    type='submit'
                    className='btn-custom btn-primary-custom'
                    disabled={isCheckingAuthentification}
                    >
                      Registrar
                  </button>
                  {/* <div className='auth__firebase-error'>{errorMessage}</div> */}
                  <Link to="/" className='auth__newUser'>Regresar a Inicio</Link>    
        
                </form>
        )}

      </Formik>


    </>
  )
}
