import React from 'react'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { startCotejarPagoExtra, startEliminarGastoExtra } from '../../redux/store/pedidos/thunks';

export default function TablaGastosExtras({ gastos }) {

  const dispatch = useDispatch();

  const eliminarGasto = ( id ) => {
    dispatch(startEliminarGastoExtra(id));
  }

  const cotejarGasto = ( id ) => {
    dispatch(startCotejarPagoExtra(id));
  }



  return (
    <table  className="table table__table">
        <thead>
          <tr style={{backgroundColor: "#8fc74a" }}>
            <th style={{textAlign: 'center', color: "#000"}} scope="col">Fecha</th>
            <th style={{textAlign: 'center', color: "#000"}} scope="col">Repartidor</th>
            <th style={{textAlign: 'center', color: "#000" }} scope="col">Cantidad</th>
            <th style={{textAlign: 'center', color: "#000"}}  scope="col">Concepto</th>
            <th style={{textAlign: 'center', color: "#000"}}  scope="col">Evidencia</th>
            <th style={{textAlign: 'center', color: "#000"}}  scope="col">Eliminar</th>
            <th style={{textAlign: 'center', color: "#000"}}  scope="col">Cotejado</th>

            {/* <th style={{textAlign: 'center', color: "#000"}} scope="col">Evidencia</th> */}
          </tr>
        </thead>

        <tbody>

            {
            gastos.map( gasto => (
                <tr key={gasto.uid}>
                    
                    <td style={{color: "#000"}}>{moment(gasto.fecha).format('dddd, DD [de] MMMM [-] LT ')}</td>
                    <td style={{color: "#000"}}>{gasto.repartidor}</td>
                    <td style={{color: "#000"}}>${gasto.cantidad}</td>
                    <td style={{color: "#000"}}>{gasto.concepto}</td>
                    <td style={{color: "#000"}}>  <a href={gasto.evidencia} target="blank"><i className="fa-solid fa-receipt table__entregableSi" ></i></a></td>
                    <td style={{color: "#000"}}> <i style={{color: "red"}} onClick={() => eliminarGasto(gasto.uid)} className="fa-solid fa-trash table__entregableSi table__detallesBtn" ></i></td>
                    <td> {!gasto.cotejado ? (<i onClick={() => cotejarGasto(gasto.uid)} style={{color: "gray"}} className="fa-solid fa-file-invoice-dollar table__entregableSi table__detallesBtn" ></i>) : (<i  style={{color: "green"}} className="fa-solid fa-circle-check table__entregableSi table__detallesBtn" ></i>)} </td>
                 </tr>
              ))
            }


            </tbody>
    </table>

  )
}
