import React from 'react'
import { semaforoCumplimiento } from '../../helpers/semaforoCumplimiento';

export const EstadisticasPorProductor = ({ pedidosCompletos }) => {

    let productores = [];

    let pedidosProducidos = pedidosCompletos.filter( pedido => pedido.produccionEnTiempo === true || pedido.produccionEnTiempo === false );

    for (const pedido of pedidosProducidos) {
  
      if (!productores.includes(pedido.produccion) && pedido.produccion !== undefined) {
        productores.push(pedido.produccion);
      }
  
    }


  
    let estadisticaPorProduccion = [];
  
    productores.forEach( productor => {
      const producidosIndividual = pedidosCompletos.filter( pedido => pedido.produccion === productor);
      const enTiempoIndividual = producidosIndividual.filter( pedido => pedido.produccionEnTiempo === true);
  
      let nuevoRepa = {
        productor,
        total: producidosIndividual.length,
        enTiempo: enTiempoIndividual.length,
        porcentaje: (enTiempoIndividual.length / producidosIndividual.length) * 100
      }
  
      estadisticaPorProduccion.push(nuevoRepa);
  
    });

  
    estadisticaPorProduccion.sort(function (a, b) {
        if (a.porcentaje > b.porcentaje) {
          return -1;
        }
        if (a.porcentaje < b.porcentaje) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

  return (
    <>

<h1 className='settings__title' style={{marginTop: "64px"}}>Producción por Sucursal</h1>

<table className="table table__tableStats">
  <thead>
    <tr>
      <th scope="col">Sucursal</th>
      <th scope="col">En Tiempo</th>
      <th scope="col">Fuera de Tiempo</th>
      <th scope="col">Total</th>
      <th scope="col">Porcentaje</th>
    </tr>
  </thead>
  <tbody>
    {estadisticaPorProduccion.map( productor => {
        return (
            <tr key={productor.productor}>
            <th scope="row">{productor.productor}</th>
            <td>{productor.enTiempo}</td>
            <td>{productor.total - productor.enTiempo}</td>
            <td>{productor.total}</td>
            <td colSpan="2">
            <div className="progress table__progressBar">
                <div className="progress-bar" role="progressbar" style={{width: `${productor.porcentaje}%`, backgroundColor: semaforoCumplimiento(productor.porcentaje)}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{`${Math.round(productor.porcentaje * 100) / 100}%`}</div>
            </div>
            <div className='table__progressNumber' style={{color: semaforoCumplimiento(productor.porcentaje)}}>{`${Math.round(productor.porcentaje * 100) / 100}%`}</div>
            </td>
        </tr>
        )
    })}

  </tbody>
</table>



    </>
  )
}
