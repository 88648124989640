import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { Formik, Field } from 'formik'
import { startCreatingUserWithEmailPassword } from '../../redux/store/auth/thunks'


export const RegisterScreen = () => {

  const dispatch = useDispatch();
  const { status } = useSelector( state => state.auth);
  const isCheckingAuthentification = useMemo( () => status === 'checking', [status]);


  return (
    <>
      <div className='auth__imgContainer'>
        <img src={logo} className='auth__logo' alt='logo' />
      </div>
      <h3 className='auth__title'>Registrar Usuario</h3>
      
      <Formik
      initialValues={{
        displayName: '',
        // username: '',
        email: '',
        tipo: '',
        password: '',
        password2: ''
      }}
      validate= {(valores) => {
        let errores = {};

        // Validación Nombre
        if (!valores.displayName) {
          errores.displayName = "El nombre es requerido";
        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.displayName)) {
          errores.displayName = "El nombre solo puede contener letras y espacios"
        }

        // Validación Usuario
        // if (!valores.username) {
        //   errores.username = "El usuario es requerido";
        // } else if (valores.username.length <= 5) {
        //   errores.username = "El usuario debe ser mayor de 5 caracteres";
        // }

        // Validación Email
        if (!valores.email) {
          errores.email = "El email es requerido";
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.email)) {
          errores.email = "El formato del email no es correcto"
        }

        // Validación Password
        if (!valores.password) {
          errores.password = "La constraseña es requerida";
        } else if (valores.password.length <= 5) {
          errores.password = "La contraseña debe ser mayor de 5 caracteres";
        }

        // Validación Password2
        if (valores.password2 !== valores.password) {
          errores.password2 = "Las contraseñas no coinciden";
        }

      // Validación Password2
      if (valores.tipo === '') {
        errores.tipo = "El tipo de usuario es requerido";
      }

        return errores;
      }}
      onSubmit={(valores, {resetForm}) => {
        resetForm();
        dispatch(startCreatingUserWithEmailPassword(valores));
      }}>

        {({ handleSubmit, values, errors, touched, handleChange, handleBlur }) => (
                <form onSubmit={handleSubmit} className="animate__animated animate__fadeIn">
                  <input
                      type="text"
                      placeholder="Nombre"
                      className='auth__input'
                      name="displayName"
                      value={values.displayName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {touched.displayName && errors.displayName && <div className='auth__error'>{errors.displayName}</div>}

                  {/* <input
                      type="text"
                      placeholder="Usuario"
                      className='auth__input'
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                   {touched.username && errors.username && <div className='auth__error'>{errors.username}</div>} */}
        
                  <input
                    type="email"
                    placeholder="Correo"
                    className='auth__input'
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  {touched.email && errors.email && <div className='auth__error'>{errors.email}</div>}      

       
                  <input
                    type="password"
                    placeholder="Contraseña"
                    className='auth__input'
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                   {touched.password && errors.password && <div className='auth__error'>{errors.password}</div>}        
        
                  <input
                    type="password"
                    placeholder="Confirmar Contraseña"
                    className='auth__input'
                    name="password2"
                    value={values.password2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                   {touched.password2 && errors.password2 && <div className='auth__error'>{errors.password2}</div>}         

                  <Field style={{marginBottom: "20px"}} as="select" name="tipo" className='settings__selectInput'>
                    <option value=''>Tipo de Usuario</option>
                    <option value="Repartidor">Repartidor</option>
                    <option value="Empleado">Receptor de Pedidos</option>
                    <option value="Produccion">Produccion</option>
                    <option value="Vendedor">Vendedor</option>
                  </Field>  
                  {touched.tipo && errors.tipo && <div className='auth__error'>{errors.tipo}</div>}   

                  <button
                    type='submit'
                    className='btn-custom btn-primary-custom'
                    disabled={isCheckingAuthentification}
                    >
                      Registrar
                  </button>
                  {/* <div className='auth__firebase-error'>{errorMessage}</div> */}
                  <Link to="/" className='auth__newUser'>Regresar a Inicio</Link>    
        
                </form>
        )}

      </Formik>


    </>
  )
}
