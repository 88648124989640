import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { useForm } from '../../hooks/useForm'
// import { login } from '../../redux/actions/auth'
import { startResetPassword } from '../../redux/store/auth/thunks'

export const ResetPassword = () => {

  const { status, errorMessage } = useSelector( state => state.auth);
  const isCheckingAuthentification = useMemo( () => status === 'checking', [status]);
  
  const dispatch = useDispatch();
  
  const {email, onInputChange } = useForm({
    email: '',
  });

  // const { usuario, password } = values;


  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(startResetPassword({ email }));
  }

  if ( status === "authenticated" ) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div className='auth__imgContainer'>
        <img src={logo} className='auth__logo' alt='logo' />
      </div>
      <h3 className='auth__title'>¿Olvidaste tu contraseña?</h3>

      <form onSubmit={handleLogin} className="animate__animated animate__fadeIn">
        <input
            type="email"
            placeholder="Email"
            name="email"
            className='auth__input'
            value={email}
            onChange={onInputChange}
          />

        <button
          type='submit'
          className='btn-custom btn-primary-custom'
          disabled={isCheckingAuthentification}
          >
            Cambiar Contraseña
        </button>
        <div className='auth__firebase-error'>{errorMessage}</div>
        <Link to="/auth/login" className='auth__newUser'>Regresar a Login</Link>    


      </form>

    </>
  )
}
