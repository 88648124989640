import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux/'
// import { Redirect } from 'react-router-dom'
import { startCargandoPedidosCliente } from '../../redux/store/pedidos/thunks'
import { CargandoPedido } from '../support/CargandoPedido'
import { NoItems } from '../support/NoItems'
import { TablaPedidos } from '../support/TablaPedidos'
import { NavbarCliente } from '../support/NavbarCliente'

export const HistorialPedidosCliente = () => {

  const dispatch = useDispatch();
  const { pedidosCliente, loading } = useSelector(state => state.pedidos);
  // const { perfil } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(startCargandoPedidosCliente());
  }, [dispatch])


  // if ( perfil === "Produccion" ) {
  //   return <Redirect to="/produccion" />
  // }
 
  
  return (
    <div>
      <NavbarCliente />
        <h1 className='settings__title'>Pedidos Pendientes</h1>
        <div className='dashboard__pedidos'>
          {
             pedidosCliente.length > 0
             ? <TablaPedidos pedidos={ pedidosCliente } isAsignados={false} isCompletos={false} isCliente={true}/>
             : <NoItems tipoPedido='cliente' />
          }     
        </div>
        {loading ? <CargandoPedido /> : null}
    </div>
  )
}
