

export const fileUpload = async ( file ) => {
 
    if (!file) throw new Error ("No hay archivo a subir");

    const cloudUrl = 'https://api.cloudinary.com/v1_1/dcl5ststo/upload';

    const formData = new FormData();
    formData.append("upload_preset", "globaclean");
    formData.append("file", file)

    try {

        const resp = await fetch(cloudUrl, {
            method: 'POST',
            body: formData
        });

        if (!resp.ok) throw new Error("No se pudo subir imágen");

        const cloudResp = await resp.json();

        return cloudResp.secure_url;
     
        
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}