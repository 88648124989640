import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startCargandoPedidosSinEntregar } from '../../redux/store/pedidos/thunks';

export const PedidosSinEntregar = () => {

    const dispatch = useDispatch();
    const { pedidosSinEntregar } = useSelector(state => state.pedidos);


    const nuevos = pedidosSinEntregar.filter( pedido => pedido.status === "nuevo");
    const produccion = pedidosSinEntregar.filter( pedido => pedido.status === "produccion" && pedido.listo === "No");
    const incompletos = pedidosSinEntregar.filter( pedido => pedido.listo === "Incompleto");
    const pendientes = pedidosSinEntregar.filter( pedido => pedido.status === "pendiente");
    const asignados = pedidosSinEntregar.filter( pedido => pedido.status === "asignado");
    const ruta = pedidosSinEntregar.filter( pedido => pedido.status === "ruta");
    const parcial = pedidosSinEntregar.filter( pedido => pedido.status === "parcial");



    useEffect(() => {
        dispatch(startCargandoPedidosSinEntregar());
      }, [dispatch])
    
  return (
    <>
     <h1 className='settings__title' style={{marginTop: "64px"}}>Pedidos Sin Entregar</h1>

     <table className="table table__tableStats table__sinEntregar">
        <thead>
            <tr>
                <th scope="col">Status</th>
                <th scope="col">Cantidad</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">Nuevos</th>
                <th>{nuevos.length}</th>
            </tr>

            <tr>
                <th scope="row">En Producción</th>
                <th>{produccion.length}</th>
            </tr>

            <tr>
                <th scope="row">Incompletos</th>
                <th>{incompletos.length}</th>
            </tr>

            <tr>
                <th scope="row">Pendientes</th>
                <th>{pendientes.length}</th>
            </tr>

            <tr>
                <th scope="row">Asignados</th>
                <th>{asignados.length}</th>
            </tr>

            <tr>
                <th scope="row">En Ruta</th>
                <th>{ruta.length}</th>
            </tr>

            <tr>
                <th scope="row">Cierre Parcial</th>
                <th>{parcial.length}</th>
            </tr>

        </tbody>
    </table>


    </>
  )
}
