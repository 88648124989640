import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux/'
// import { Redirect } from 'react-router-dom'
import { startCargandoPedidosAsignados } from '../../redux/store/pedidos/thunks'
import { CargandoPedido } from '../support/CargandoPedido'
import { Navbar2 } from '../support/Navbar2'
import { NoItems } from '../support/NoItems'
import { TablaPedidos } from '../support/TablaPedidos'

export const AsignadosScreen = () => {

  const dispatch = useDispatch();
  const { pedidosAsignados, loading } = useSelector(state => state.pedidos);
  // const { perfil } = useSelector(state => state.auth);


  useEffect(() => {
    dispatch(startCargandoPedidosAsignados());
  }, [dispatch])





  // if ( perfil === "Produccion" ) {
  //   return <Redirect to="/produccion" />
  // }
 
  
  return (
    <div>
        <Navbar2 />
        <h1 className='settings__title'>Pedidos Asignados</h1>
        <div className='dashboard__pedidos'>
          {
             pedidosAsignados.length > 0
             ? <TablaPedidos pedidos={ pedidosAsignados } isAsignados={true} isCompletos={false} />
             : <NoItems tipoPedido='asignados' />
          }     
        </div>
        {loading ? <CargandoPedido /> : null}
    </div>
  )
}
