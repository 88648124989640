import React from 'react'
import { useSelector } from 'react-redux';

export default function EstadisticasPagosEfectivo({ pedidosCompletos, gastos }) {


const { perfil, displayName } = useSelector(state => state.auth);


let repartidores = [];

for (const pedido of pedidosCompletos) {

  if (!repartidores.includes(pedido.repartidor)) {
    repartidores.push(pedido.repartidor);
  }

}  

let estadisticaPorRepartidor = [];

  
repartidores.forEach(repartidor => {
  const completosIndividual = pedidosCompletos.filter( pedido => pedido.repartidor === repartidor);
  const efectivoIndividual = completosIndividual.filter( pedido => pedido.pagado && pedido.detallesPago.metodo === "Efectivo");
  const pagosCotejados = completosIndividual.filter (pedido => pedido.pagado && (pedido.detallesPago.metodo === "Efectivo" && pedido.detallesPago.cotejado));
  const gastosExtrasIndividual = gastos.filter( gasto => gasto.repartidor === repartidor);

  let pagadoEnEfectivo = 0;
  efectivoIndividual.forEach( pagado => {
    pagadoEnEfectivo = pagadoEnEfectivo + parseFloat(pagado.detallesPago.cantidad);
  })

  let cantidadCotejada = 0;
  pagosCotejados.forEach( pagado => {
    cantidadCotejada = cantidadCotejada + parseFloat(pagado.detallesPago.cantidad);
  })

  let cantidadGastosExtra = 0;
  gastosExtrasIndividual.forEach( gasto => {
    cantidadGastosExtra = cantidadGastosExtra + parseFloat(gasto.cantidad);
  })


  let nuevoRepa = {
    repartidor,
    total: completosIndividual.length,
    totalEnEfectivo: efectivoIndividual.length,
    pagosCotejados: pagosCotejados.length,
    cantidadEnEfectivo: pagadoEnEfectivo,
    cantidadCotejada,
    cantidadPorCotejar: pagadoEnEfectivo - cantidadCotejada,
    gastosExtra: cantidadGastosExtra,
    efectivoActual: pagadoEnEfectivo - cantidadCotejada - cantidadGastosExtra
    // porcentaje: (enTiempoIndividual.length / completosIndividual.length) * 100
  }

  estadisticaPorRepartidor.push(nuevoRepa);

});

if (perfil === "Repartidor") {
  estadisticaPorRepartidor = estadisticaPorRepartidor.filter( pedido => pedido.repartidor === displayName);
}

estadisticaPorRepartidor.sort(function (a, b) {
    if (a.totalEnEfectivo > b.totalEnEfectivo) {
      return -1;
    }
    if (a.totalEnEfectivo < b.totalEnEfectivo) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });

  return (
    <>

        <h1 className='settings__title' style={{marginTop: "64px"}}>Pedidos pagados en efectivo por repartidor</h1>

        <table className="table table__tableStats">
        <thead>
            <tr>
            <th scope="col">Repartidor</th>
            <th scope="col">Pagados en Efectivo</th>
            <th scope="col">Pedidos Cotejados</th>
            <th scope="col">Efectivo</th>
            <th scope="col">Cotejado</th>
            <th scope="col">Por Cotejar</th>
            <th scope="col">Gastos Extra</th>
            <th scope="col">Efectivo Actual</th>
            </tr>
        </thead>
        <tbody>
            {estadisticaPorRepartidor.map( repartidor => {
                return (
                    <tr key={repartidor.repartidor}>
                    <th scope="row">{repartidor.repartidor}</th>
                    <td>{ repartidor.totalEnEfectivo }</td>
                    <td>{repartidor.pagosCotejados}</td>
                    <td>${ Math.round(repartidor.cantidadEnEfectivo * 100) / 100 }</td>
                    <td>${Math.round(repartidor.cantidadCotejada * 100) / 100  }</td>
                    <td>${ Math.round(repartidor.cantidadPorCotejar * 100) / 100  }</td>
                    <td>${ Math.round(repartidor.gastosExtra * 100) / 100  }</td>
                    <td>${repartidor.efectivoActual > 0 ? Math.round(repartidor.efectivoActual * 100) / 100 : 0 }</td>
                </tr>
                )
            })}

        </tbody>
        </table>



    </>
  )
}
