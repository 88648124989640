import React from 'react'
import { startModificarComision, startModificarMeta } from '../../redux/store/pedidos/thunks';
import { useDispatch } from 'react-redux';

export const VendedoresAdmin = ( { vendedores } ) => {
    console.log("Que trae Vendedores", vendedores);
    const dispatch = useDispatch();

    const modificarComision = ( actual, id ) => {
        dispatch(startModificarComision( actual, id ));
    }
     
    const modificarMeta = (actual, id) => {
        dispatch(startModificarMeta( actual, id ));
    }

  return (
    <>
      <h1 className='settings__title' style={{marginTop: "64px"}}>Administrador de Vendedores</h1>

      <table class="table table__tableStats table-dark">
  <thead>
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col">Comisión</th>
      <th scope="col">Meta Mensual</th>
      <th scope="col">Editar</th>
    </tr>
  </thead>
  <tbody>
    {vendedores.map( vendedor => {
        return (
            <tr>
            <th scope="row">{vendedor.displayName}</th>
            <td> {vendedor.comision}%  </td>
            <td> ${vendedor.meta}  </td>
            <td><button className='btn__modificarMetaComision' onClick={() => modificarComision(vendedor.comision, vendedor.id)}>Comsión</button> <button className='btn__modificarMetaComision' onClick={() => modificarMeta(vendedor.meta, vendedor.id)}> Meta</button></td>
          </tr>
         )})}

    {/* <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr> */}
  </tbody>
</table>

    </>

  )
}
