import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import { LoginScreen } from '../components/auth/LoginScreen'
import { RegisterScreen } from '../components/auth/RegisterScreen'
import { ResetPassword } from '../components/auth/ResetPassword'
import { RegisterClientScreen } from '../components/auth/RegisterClientScreen'
import { GenerarSinRegistro } from '../components/pedidos/GenerarSinRegistro'

export const AuthRouter = () => {
  return (
    <div className='auth__main'>
      <div className='auth__box-container'>
        <Switch>
            <Route
                path='/auth/login'
                exact
                component={LoginScreen}
            />

            <Route
                path='/auth/register'
                exact
                component={RegisterScreen}
            />

            <Route
                path='/auth/client'
                exact
                component={RegisterClientScreen}
            />

            <Route
                path='/auth/resetpassword'
                exact
                component={ResetPassword}
            />

            <Route
                path='/auth/pedidoSinRegistro'
                exact
                component={GenerarSinRegistro}
            />

            <Redirect 
                to='/auth/login'
            />

        </Switch>
     </div>
    </div>
  )
}
