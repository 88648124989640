
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startCargandoPedidosPendientes } from '../../redux/store/pedidos/thunks';
import { Navbar2 } from '../support/Navbar2';
import { NoItems } from '../support/NoItems';
import { TablaPedidos } from '../support/TablaPedidos'
import { Redirect } from 'react-router-dom'
import { logout } from '../../redux/store/auth/authSlice';
import OneSignal from 'react-onesignal';

export const DashboardScreen = () => {

  const dispatch = useDispatch();
  const { pedidosPendientes } = useSelector(state => state.pedidos);
  const { perfil, displayName } = useSelector(state => state.auth);

  if (displayName === null) {
    dispatch(logout("Ingresa con tu email y contraseña creados"));
  }

  useEffect(() => {



    if (perfil !== "Cliente") {

      console.log("ONE SIGNAL");
        OneSignal.init({
          appId: "d4c5a23f-40ba-4ed6-952f-18a125ccaf04"
          }).then(() => {
          console.log("Todo ok")
          }).catch( err => console.log(err))

    }

    }, [perfil]);
  
  

  useEffect(() => {
    dispatch(startCargandoPedidosPendientes());
  }, [dispatch])

  if ( perfil === "Cliente" ) {
    return <Redirect to="/generarCliente" />
  }
 

  return (
    <div className='dashboard__container'>
        <Navbar2 />
        <h1 className='settings__title'>Pedidos Pendientes</h1>
        <div className='dashboard__pedidos'>
          {
             pedidosPendientes.length > 0
             ? <TablaPedidos pedidos={ pedidosPendientes } isAsignados={false} isCompletos={false} />
             : <NoItems tipoPedido='pendientes' />
          }
        </div>
    </div>
  )
}
