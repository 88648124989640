import React, {  useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { startCargandoGastosExtras, startCargandoTodosPedidos } from '../../redux/store/pedidos/thunks';
import { EstadisticasPorProductor } from '../support/EstadisticasPorProductor';
import { EstadisticasPorRepartidor } from '../support/EstadisticasPorRepartidor';
import { Navbar2 } from '../support/Navbar2'
import { PedidosSinEntregar } from '../support/PedidosSinEntregar';
import { TotalEntregados } from '../support/TotalEntregados';
import { TotalProducidos } from '../support/TotalProducidos';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
import { CargandoPedido } from '../support/CargandoPedido';
import EstadisticasPagosGenerales from '../support/EstadisticasPagosGenerales';
import EstadisticasPagosEfectivo from '../support/EstadisticasPagosEfectivo';
import EstadisticasMetodosRepartidor from '../support/EstadisticasMetodosRepartidor';

moment.locale('es');

export const StatdisticsSreen = () => {

  const initialDesde = moment().startOf('week').format("yyyy-MM-DD");
  const initialHasta = moment().endOf('week').format("yyyy-MM-DD");
  const dispatch = useDispatch();
  const { todosPedidos, loading, gastosExtras } = useSelector(state => state.pedidos);
  const { perfil, displayName } = useSelector(state => state.auth);
  const [ fechaDesde, setFechaDesde ] = useState(initialDesde);
  const [ fechaHasta, setFechaHasta ] = useState(initialHasta);
  const [ cambioFecha, setCambioFecha] = useState(true);
  const [ pedidosCompletosFiltrados, setPedidosCompletosFiltrados ] = useState([]);
  const [ pedidosCompletosFiltradosFechaPago, setPedidosCompletosFiltradosFechaPago ] = useState([]);
  const [ pedidosCompletosParaPagos, setPedidosCompletosParaPagos ] = useState([]);
  const [ pedidosCompletosParaPagosPorFecha, setPedidosCompletosParaPagosPorFecha ] = useState([]);
  const [ todosPedidosFiltrados, setTodosPedidosFiltrados ] = useState(todosPedidos);
  const [ gastosExtrasFiltrados, setGastosExtrasFiltrados ] = useState(gastosExtras);



  const seleccionarDesde = (event) => {
    if (event.target.value !== fechaDesde) {
      setFechaDesde(event.target.value);
      setCambioFecha(true);
    }
  }

  const seleccionarHasta = (event) => {
    if (event.target.value !== fechaHasta) {
      setFechaHasta(event.target.value)
      setCambioFecha(true)
    }
  }

  const filtrarFechas = () => {

    let desde = new Date(fechaDesde).getTime() + 18000000; 
    let hasta = new Date(fechaHasta).getTime() + 104400000;
    if (hasta < desde) {
      Swal.fire('¡Error!', 'La fecha final no puede ser menor que la inicial','error');
      return;
    }

    if (cambioFecha) {
      // dispatch(startCargandoPedidosCompletos(desde, hasta));
      dispatch(startCargandoTodosPedidos(desde, hasta));
      dispatch(startCargandoGastosExtras(desde, hasta));
      setCambioFecha(false);
    }

    filtrarDatos();



  }

  const filtrarDatos = () => {

    let filtradosCompletos = [];
    let filtradosCompletosFechaPago = [];
    let filtradosTodos = [];
    let gastosFiltrados = [];

    filtradosTodos = todosPedidos;
    filtradosCompletos = todosPedidos.filter( pedido => pedido.status === "completo");
    filtradosCompletosFechaPago = filtradosCompletos.filter( pedido => pedido.pagado );

    gastosFiltrados = gastosExtras;
    let filtradosPedidosExternos = filtradosCompletos.filter( pedido => !pedido.tipoPedido || pedido.tipoPedido !== "Interno");
    let filtradosPedidosExternosFechaPago = filtradosCompletosFechaPago.filter( pedido => pedido.tipoPedido && pedido.tipoPedido !== "Interno");
    setPedidosCompletosFiltrados(filtradosCompletos);
    setPedidosCompletosFiltradosFechaPago(filtradosCompletosFechaPago);
    setTodosPedidosFiltrados(filtradosTodos);
    setGastosExtrasFiltrados(gastosFiltrados);
    setPedidosCompletosParaPagos(filtradosPedidosExternos);
    setPedidosCompletosParaPagosPorFecha(filtradosPedidosExternosFechaPago);

  }

  const semanaAnterior = () => {
    let desde = moment(fechaDesde).subtract(7, 'days');
    let hasta = moment(fechaHasta).subtract(7, 'days');
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setCambioFecha(true);
  }

  const semanaSiguiente = () => {
    let desde = moment(fechaDesde).add(7, 'days');
    let hasta = moment(fechaHasta).add(7, 'days');
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setCambioFecha(true);
  }



  return (
    <div style={{height: "auto"}}>
      <Navbar2 />

      <div className='stadistics__rangoContainer'>

        
        <div className='stadistics__rangoFechas'>
          <label className='generate__label'>
            Desde:
            <input 
              type="date" 
              name="desde" 
              className='stadistics__input-limite'
              value={fechaDesde}
              onChange={seleccionarDesde}
            />
          </label>
        </div>


        <div className='stadistics__arrowsContainer'>
            <i className="fa-solid fa-circle-arrow-left stadistics__arrows" onClick={semanaAnterior}></i>
            <i className="fa-solid fa-circle-arrow-right stadistics__arrows" onClick={semanaSiguiente}></i>
        </div>
     

        <div className='stadistics__rangoFechas'>
          <label className='generate__label'>
            Hasta:
            <input 
              type="date" 
              name="hasta" 
              className='stadistics__input-limite'
              value={fechaHasta}
              onChange={seleccionarHasta}
            />
          </label>
        </div>



       <button id='filtrarFechasEstadisticas' className='stadistics__FiltrarBtn' onClick={filtrarFechas}>Filtrar</button>


      </div>


      <PedidosSinEntregar />

      {perfil !== "Repartidor" && (<TotalProducidos pedidosCompletos = {todosPedidosFiltrados} perfil={perfil} displayName={displayName} />)}
      {(perfil !== "Repartidor" && perfil !== "Produccion"  && perfil !== "Sucursal") && <EstadisticasPorProductor pedidosCompletos = {todosPedidosFiltrados} />}

      <TotalEntregados pedidosCompletos={pedidosCompletosFiltrados} />
      {(perfil !== "Repartidor" && perfil !== "Produccion" && perfil !== "Sucursal") && <EstadisticasPorRepartidor pedidosCompletos = {pedidosCompletosFiltrados} />}

      {/* Estadisticas de Pagos */}

      {(perfil === "Administrador" || perfil === "Repartidor") && <h1 className='settings__title' style={{marginTop: "128px", fontSize: "30px"}}>ESTADÍSTICAS DE PAGOS</h1>}
      {perfil === "Administrador" && <EstadisticasPagosGenerales pedidosCompletos = {pedidosCompletosParaPagos} entregados={true} title="Pedidos Entregados Esta Semana (Pagos)" />}
      {perfil === "Administrador" && <EstadisticasPagosGenerales pedidosCompletos = {pedidosCompletosParaPagosPorFecha} entregados={false} title="Pedidos Pagados Esta Semana" />}
      {(perfil === "Administrador" || perfil === "Repartidor") && <EstadisticasPagosEfectivo pedidosCompletos = {pedidosCompletosParaPagos} gastos={gastosExtrasFiltrados}/>}
      {(perfil === "Administrador") && <EstadisticasMetodosRepartidor pedidosCompletos = {pedidosCompletosParaPagos} gastos={gastosExtrasFiltrados} />}

      {/* Estadisticas de Pagos */}
     
    <br /><br /><br />
    {loading ? <CargandoPedido /> : null}

    </div>
  )
}
