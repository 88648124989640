import { collection, query, where, getDocs } from "firebase/firestore/lite"
import { FirebaseDB } from "../firebase/config"


export const cargarUsuario = async (uid) => {


    const collectionRef = collection(FirebaseDB, '/usuarios');
    const q =  query(collectionRef, where("uid", "==", uid)); //Aqui van los filtros
    const docs = await getDocs(q); //Aqui van los filtros


    const usuario = [];
    docs.forEach( doc => {
        usuario.push({uid: doc.id, docID: doc.id, ...doc.data()});
    })


    return usuario;
}