

import { configureStore, compose } from '@reduxjs/toolkit'
import { authSlice } from './auth/authSlice'
import { pedidosSlice } from './pedidos/pedidosSlice'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    pedidos: pedidosSlice.reducer,
  },
  composeEnhancers,
})