import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { Redirect } from "react-router-dom";
import { startCargandoPedidosNuevos } from '../../redux/store/pedidos/thunks';
import { Navbar2 } from '../support/Navbar2';
import { NoItems } from '../support/NoItems';
import { TablaPedidos } from '../support/TablaPedidos'

export const ProduccionScreen = () => {

  const dispatch = useDispatch();
  // const { perfil } = useSelector(state => state.auth);
  const { pedidosNuevos } = useSelector(state => state.pedidos);

  useEffect(() => {
    dispatch(startCargandoPedidosNuevos());
  }, [dispatch])

  // if ( perfil === "Repartidor") {
  //   return <Redirect to="/" />
  // }


  return (
    <div className='dashboard__container'>
        <Navbar2 />
        <h1 className='settings__title'>Pedidos Nuevos</h1>
        <div className='dashboard__pedidos'>
          {
             pedidosNuevos.length > 0
             ? <TablaPedidos pedidos={ pedidosNuevos } isAsignados={false} isCompletos={false} isProduccion={true} />
             : <NoItems tipoPedido='nuevos' />
          }
        </div>
    </div>
  )
}
