

export const iconoEntregable = ( listo, status, enTiempo ) => {


    if (status === "completo" && enTiempo) {
        return "fa-solid fa-check table__entregableSi"
      }

    if (status === "completo" && !enTiempo) {
        return "fa-solid fa-xmark table__enTiempoNo"
    }

    if (listo === "Nuevo" && status !== "completo") {
        return "fa-solid fa-folder-plus table__entregableNuevo"
    }

    if (listo === "Sí" && status !== "completo") {
        return "fa-solid fa-circle-check table__entregableSi"
    }

    if (listo === "No" && status !== "completo") {
        return "fa-solid fa-screwdriver-wrench table__entregableNo"
    }

    if (listo === "Incompleto" && status !== "completo") {
        return "fa-solid fa-triangle-exclamation table__entregableIncompleto"
    }

    return;

}