import React, {  useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
import { startCargandoPedidosVendedores } from '../../redux/store/pedidos/thunks';
import { Navbar2 } from '../support/Navbar2';
import { EstadisticasPorVendedor } from '../support/EstadisticaPorVendedor';
import { VendedorIndividual } from '../support/VendedorIndividual';
import { TablaPedidos } from '../support/TablaPedidos';
import { NoItems } from '../support/NoItems';
moment.locale('es');

export const VendedoresScreen = () => {

    const dispatch = useDispatch();
    const initialDesde = moment().startOf('month').format("yyyy-MM-DD");
    const initialHasta = moment().endOf('month').format("yyyy-MM-DD");


    const [ fechaActual, setFechaActual ] = useState(new Date());
    const [ fechaDesde, setFechaDesde ] = useState(initialDesde);
    const [ fechaHasta, setFechaHasta ] = useState(initialHasta);
    const [ pedidosPorVendedor, setPedidosPorVendedor ] = useState([]);
    const [ cambioFecha, setCambioFecha] = useState(true);
    const { pedidosVendedores } = useSelector(state => state.pedidos);
    const { perfil, uid } = useSelector( state => state.auth);

    console.log("Fecha actual: ", fechaActual);
    let inicioMes = moment().startOf('month').format("yyyy-MM-DD")
    console.log("Inicio de mes: ", inicioMes);
    console.log("AQUI ESTAN LOS PEDIDOS QUE REGRESARON: ", pedidosVendedores);





    const seleccionarDesde = (event) => {
        if (event.target.value !== fechaDesde) {
          setFechaDesde(event.target.value);
          setCambioFecha(true);
        }
      }
    
      const seleccionarHasta = (event) => {
        if (event.target.value !== fechaHasta) {
          setFechaHasta(event.target.value)
          setCambioFecha(true)
        }
      }
    
      const filtrarFechas = () => {
    
        let desde = new Date(fechaDesde).getTime() + 18000000; 
        let hasta = new Date(fechaHasta).getTime() + 104400000;
        if (hasta < desde) {
          Swal.fire('¡Error!', 'La fecha final no puede ser menor que la inicial','error');
          return;
        }
    
        if (cambioFecha) {
          dispatch(startCargandoPedidosVendedores(desde, hasta));
          setCambioFecha(false);
        }


    
        filtrarDatos();
    }


    const filtrarDatos = () => {

      console.log("Hola, filtro datos");
      let pedidosIndividuales = pedidosVendedores.filter( pedido => pedido.vendedorInfo.id === uid);
      console.log("Individuales desde filtrar: ", pedidosIndividuales);
      setPedidosPorVendedor(pedidosIndividuales);
   
      }


    const semanaAnterior = () => {

    let nuevoMes = moment(fechaActual).subtract(1, 'month');

    let desde = moment(nuevoMes).startOf('month').format("yyyy-MM-DD");;
    let hasta = moment(nuevoMes).endOf('month').format("yyyy-MM-DD");;
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setFechaActual(nuevoMes);
    setCambioFecha(true);
    }

    const semanaSiguiente = () => {
      let nuevoMes = moment(fechaActual).add(1, 'month');

      let desde = moment(nuevoMes).startOf('month').format("yyyy-MM-DD");;
      let hasta = moment(nuevoMes).endOf('month').format("yyyy-MM-DD");;

    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setFechaActual(nuevoMes);
    setCambioFecha(true);
      }

  return (
    <>
    <Navbar2 />

    <div className='stadistics__rangoContainer'>

      
      <div className='stadistics__rangoFechas'>
        <label className='generate__label'>
          Desde:
          <input 
            type="date" 
            name="desde" 
            disabled="true"
            className='stadistics__input-limite'
            value={fechaDesde}
            onChange={seleccionarDesde}
          />
        </label>
      </div>


      <div className='stadistics__arrowsContainer'>
          <i className="fa-solid fa-circle-arrow-left stadistics__arrows" onClick={semanaAnterior}></i>
          <i className="fa-solid fa-circle-arrow-right stadistics__arrows" onClick={semanaSiguiente}></i>
      </div>
   

      <div className='stadistics__rangoFechas'>
        <label className='generate__label'>
          Hasta:
          <input 
            type="date" 
            name="hasta" 
            disabled="true"
            className='stadistics__input-limite'
            value={fechaHasta}
            onChange={seleccionarHasta}
          />
        </label>
      </div>



     <button id='filtrarFechasEstadisticas' className='stadistics__FiltrarBtn' onClick={filtrarFechas}>Filtrar</button>


    </div>


    { perfil === "Vendedor" && <VendedorIndividual pedidosCompletos={pedidosPorVendedor} /> }

  
    { (perfil === "Administrador") && <EstadisticasPorVendedor pedidosCompletos={pedidosVendedores} /> }

    <h1 className='settings__title' style={{marginTop: "64px"}}>Pedidos completos de vendedores</h1>
     <div className='dashboard__pedidos'>
          {
             (pedidosVendedores?.length > 0 && pedidosVendedores !== undefined)
             ? <TablaPedidos pedidos={pedidosVendedores} isCompletos={true} isVendidos={true}  />
             : <NoItems tipoPedido='vendidos' />
          }     
        </div>





    </>

  )
}
