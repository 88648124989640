import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startGenerarNuevoPedido, startUploadingFiles } from '../../redux/store/pedidos/thunks';
import { Redirect, useHistory } from "react-router-dom";
import { Formik, Field } from 'formik';
import { Navbar2 } from '../support/Navbar2';


export const GenerateScreen = () => {

  const dispatch = useDispatch();
  const [facturaRequerida, setFacturaRequerida] = useState(false);

  const { perfil, displayName, uid } = useSelector(state => state.auth);
  const { imagenes } = useSelector( state => state.pedidos);
  const history = useHistory();


  if ( perfil === "Repartidor" || perfil === "Produccion") {
    return <Redirect to="/" />
  }

  const onFileInputChange = ({ target }) => {
    if (target.files === 0) return;
    dispatch(startUploadingFiles(target.files));
  }

  return (
    <div>
      <Navbar2 />
      <h1 className='settings__title'>Generar Nuevo Pedido</h1>
      <div className='generate__container'>

        <Formik 
          initialValues={{
            cliente: '',
            tipo: '',
            direccion: '',
            municipio: '',
            rangoPrecio: '',
            tipoPedido: '',
            pedido: '',
            limite: '',
            comentarios: '',
            factura: 'No',
            formaPago: 'Una Exhibición',
            metodoPago: 'Efectivo',
            costoExtra: 'No',
            rfc: '',
            direccionFactura: '',
            cfdi: '',
            repartidor: 'Libre',
            sucursal: 'Libre',
            vendedor: perfil === 'Vendedor' ? true : false,
            vendedorInfo: perfil === 'Vendedor' ? {nombre: displayName, id: uid } : { nombre: '', id: '' },
            imagenes: []
          }}
          validate={(valores) => {
            let errores = {};
            const lim = new Date(valores.limite).getTime();
            const ahora = new Date().getTime();
            const tiempo = lim - ahora;

     
            if (!valores.cliente) {
              errores.cliente = "El nombre del cliente es requerido"
            }

            if (!valores.tipo) {
              errores.tipo = "Requerido"
            }

            if (!valores.direccion) {
              errores.direccion = "La dirección del cliente es requerida"
            }

            if (!valores.municipio) {
              errores.municipio = "Requerido"
            }

            if (!valores.tipoPedido) {
              errores.tipoPedido = "Requerido"
            }

            if (!valores.pedido) {
              errores.pedido = "La información del pedido es requerida"
            }

            if (!valores.limite) {
              errores.limite = "El limite de entrega es requerido"
            } else if (tiempo < 3600000) {
              errores.limite = "El limite debe ser mayor a una hora"
            }

            if (valores.formaPago === "PPD") {
              valores.metodoPago = "Por Definir"
            }

            if (valores.factura === "Sí") {
              setFacturaRequerida(true);
            } else if (valores.factura === "No") {
              setFacturaRequerida(false);
            }

        
            // if (!valores.rfc && valores.factura === "Sí") {
            //   errores.rfc = "El RFC del cliente es requerido"
            // }

            if (valores.cfdi === '' && valores.factura === "Sí") {
              errores.cfdi = "Requerido"
            }

            // if (!valores.direccionFactura && valores.factura === "Sí") {
            //   errores.direccionFactura = "La dirección de facturación es requerida"
            // }

            if (valores.repartidor !== "Libre" && valores.sucursal === "Libre") {
              errores.sucursal = "Asignar repartidor, requiere asignar sucursal"
            }

            if (valores.repartidor === "Libre" && valores.sucursal !== "Libre") {
              errores.repartidor = "Asignar sucursal, requiere asignar repartidor"
            }

            return errores;
          }}
          onSubmit={(valores, { resetForm }) => {
            valores.imagenes = imagenes;
            let pantalla = '/produccion';
            if (valores.repartidor !== "Libre") {
              pantalla = "/asignados"
            }

            dispatch(startGenerarNuevoPedido(valores));
            resetForm();
            history.push(pantalla);
          }}
        >
          {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
            <form className='generate__form' onSubmit={handleSubmit}>
            <div className='generate__form-section'>
              <p className='generate__sectionName'>Datos del Cliente</p>
              <div>
                <label className='generate__label'>
                  Cliente: 
                  <input
                    type="text"
                    placeholder="Ingresa el nombre del cliente" 
                    name="cliente" 
                    className='generate__input'
                    value={values.cliente}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                   {touched.cliente && errors.cliente && <div className='generate__error '>{errors.cliente}</div>}
                </label>

                <div className='generate__selectValidation'>
                <Field as="select" name="tipo" className='settings__selectInput'>
                    <option value=''>Tipo de Usuario</option>
                    <option value="VIP">VIP</option>
                    <option value="Moderado">Moderado</option>
                    <option value="Bajo">Bajo</option>
                  </Field>  
                  {touched.tipo && errors.tipo && <div style={{marginTop: '0'}} className='generate__error '>{errors.tipo}</div>}   
                </div>

              </div>
  
              <div>
                <label className='generate__label'>
                  Dirección: 
                  <input 
                    type="text" 
                    placeholder="Ingresa la dirección del cliente" 
                    name="direccion" 
                    className='generate__input' 
                    value={values.direccion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.direccion && errors.direccion && <div className='generate__error'>{errors.direccion}</div>}
                </label>

                <div className='generate__selectValidation'>
                <Field as="select" name="municipio" className='settings__selectInput'>
                    <option value=''>Municipio</option>
                    <option value="Colima">Colima</option>
                    <option value="Villa de Álvarez">Villa de Álvarez</option>
                    <option value="Comala">Comala</option>
                    <option value="Cuauhtémoc">Cuauhtémoc</option>
                    <option value="Coquimatlán">Coquimatlán</option>
                    <option value="Tecomán">Tecomán</option>
                    <option value="Armería">Armería</option>
                    <option value="Ixtlahuacán">Ixtlahuacán</option>
                    <option value="Minatitlán">Minatitlán</option>
                    <option value="Manzanillo">Manzanillo</option>
                    <option value="Otro">Otro</option>
                  </Field>  
                  {touched.municipio && errors.municipio && <div style={{marginTop: '0'}} className='generate__error '>{errors.municipio}</div>}   
                </div>

              </div>

              <div className='generate__rangoTipo' >
                <div className='generate__selectValidation'>
                  <Field as="select" name="rangoPrecio" className='settings__selectInput'>
                      <option value=''>Rango de Precio</option>
                      <option value="Precio 1">Precio 1</option>
                      <option value="Precio 2">Precio 2</option>
                      <option value="Precio 3">Precio 3</option>
                      <option value="Precio 4">Precio 4</option>
                      <option value="Precio 1 sin descuento">Precio 1 sin descuento</option>
                      <option value="Precio 2 sin descuento">Precio 2 sin descuento</option>
                      <option value="Precio 3 sin descuento">Precio 3 sin descuento</option>
                      <option value="Precio 4 sin descuento">Precio 4 sin descuento</option>
                      <option value="Precio modificable">Precio modificable</option>
                    </Field>  
                    {/* {touched.municipio && errors.municipio && <div style={{marginTop: '0'}} className='generate__error '>{errors.municipio}</div>}    */}
                  </div>

                  <div className='generate__selectValidation'>
                  <Field as="select" name="tipoPedido" className='settings__selectInput'>
                      <option value=''>Tipo de Pedido</option>
                      <option value="Externo">Externo</option>
                      <option value="Interno">Interno</option>
                    </Field>  
                    {touched.tipoPedido && errors.tipoPedido && <div style={{marginTop: '0'}} className='generate__error '>{errors.municipio}</div>}   
                  </div>
              </div>
  
              <hr />
            </div>
  
  
            <div className='generate__form-section'>
              <p className='generate__sectionName'>Datos del Pedido</p>
              <div>
                <label className='generate__label'>
                  Pedido: 
                  <textarea 
                    type="text" 
                    name="pedido" 
                    className='generate__textbox' 
                    value={values.pedido}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                   {touched.pedido && errors.pedido && <div style={{marginTop: '0'}} className='generate__error '>{errors.pedido}</div>}
                </label>
              </div>
  
              <div>
                <label className='generate__label'>
                  Subir: 
                  <input 
                    type="file" 
                    multiple
                    name="imagen-pedido" 
                    className='generate__input-file' 
                    onChange={ onFileInputChange }
                  />
                </label>
              </div>
  
              <div className='generate__pedido-elements'>
                <label className='generate__label'>
                  Límite de Entrega: 
                  <input 
                    type="datetime-local" 
                    name="limite" 
                    className='generate__input-limite'
                    value={values.limite}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                 {touched.limite && errors.limite && <div style={{marginTop: '0'}} className='generate__error'>{errors.limite}</div>}
                </label>
              </div>

              <div style={{marginTop: '20px'}}>
                <label className='generate__label'>
                  Comentarios Adicionales: 
                  <textarea 
                    type="text" 
                    name="comentarios" 
                    className='generate__textboxComentarios' 
                    value={values.comentarios}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                 </label>
              </div>
  
              <hr />
            </div>
  
  
            <div className='generate__form-section'>
              <p className='generate__sectionName'>Datos del Pago</p>
              <div className='generate__pago-selects'>
  
              <div className='generate__labeledSelect'>
                  <p className='generate__label-selects'>
                    Requiere Factura:
                  </p>
                  <Field as="select" name="factura" className='settings__selectInput'>
                    <option value="No">No</option>
                    <option value="Sí">Sí</option>
                  </Field>  
                </div>
  
                <div className='generate__labeledSelect'>
                  <p className='generate__label-selects'>
                    Forma de Pago:
                  </p>
                  <Field as="select" name="formaPago" className='settings__selectInput'>
                    <option value="Una Exhibición">Una Exhibición</option>
                    <option value="PPD">PPD</option>
                    <option value="Por Definir">Por Definir</option>
                  </Field>  
                </div>

                <div className='generate__labeledSelect'>
                  <p className='generate__label-selects'>
                    Método de Pago:
                  </p>
                  <Field as="select" name="metodoPago" className='settings__selectInput'>
                  <option value="Efectivo">Efectivo</option>
                    <option value="Transferencia">Transferencia</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Por Definir">Por Definir</option>
                  </Field>  
                </div>
  
  
                <div className='generate__labeledSelect'>
                    <p className='generate__label-selects'>
                    Costo Extra:
                    </p>
                    <Field as="select" name="costoExtra" className='settings__selectInput'>
                      <option value="No">No</option>
                      <option value="$10">$10</option>
                      <option value="$20">$20</option>
                      <option value="$30">$30</option>
                      <option value="$40">$40</option>
                      <option value="$50">$50</option>
                    </Field>  
                  </div>
  
              </div>  
  
              {facturaRequerida && (
                <div className='generate__factura-inputs'>
    
                <label className='generate__labelFactura'>
                  RFC - Razón Social: 
                  <input 
                      type="text" 
                      placeholder="Ingresa RFC o razón social para facturación" 
                      name="rfc" 
                      className='generate__inputFactura'
                      value={values.rfc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.rfc && errors.rfc && <div className='generate__error '>{errors.rfc}</div>}
                </label>
    
                <label className='generate__labelFactura'>
                  Dirección de Facturación: 
                  <input 
                    type="text" 
                    placeholder="Ingresa la dirección de facturación" 
                    name="direccionFactura" 
                    className='generate__inputFactura'
                    value={values.direccionFactura}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.direccionFactura && errors.direccionFactura && <div className='generate__error'>{errors.direccionFactura}</div>}
                </label>

                <div style={{flexDirection: "column", justifyContent: "flex-start"}} className='generate__labelFactuar generate__noMT'>
                  <Field as="select" name="cfdi" className='settings__selectInput'>
                    <option value=''>Uso de CFDI</option>
                    <option value="Gastos en general">Gastos en general</option>
                    <option value="Adquisión de mercancias">Adquisión de mercancias</option>
                  </Field>  
                  {touched.cfdi && errors.cfdi && <div style={{marginTop: '0'}} className='generate__error'>{errors.cfdi}</div>}

                </div>
    
                </div>
              )}

  
  
            <hr />
            </div>
  
            <div className='generate__form-section'>
              <p className='generate__sectionName'>Datos de asignación</p>
  
              <div className='generate__pago-selects'>
                <div className='generate__labeledSelect'>
                    <p className='generate__label-selects'>
                      Asignar Repartidor:
                    </p>
                  <Field as="select" name="repartidor" className='settings__selectInput'>
                    <option value="Libre">Libre</option>
                    <option value="Héctor">Héctor</option>
                    <option value="Martín">Martín</option>
                    <option value="Emergente">Emergente</option>
                    <option value="Cliente">Cliente</option>
                  </Field>  
                  {touched.repartidor && errors.repartidor && <div style={{marginTop: '0'}} className='generate__error'>{errors.repartidor}</div>}
                </div>
  
                <div className='generate__labeledSelect'>
                    <p className='generate__label-selects'>
                      Asignar Sucursal:
                    </p>
                    <Field as="select" name="sucursal" className='settings__selectInput'>
                      <option value="Libre">Libre</option>
                      <option value="Matriz">Matriz</option>
                      <option value="Suc Pablo">Suc Pablo</option>
                      <option value="Suc Villa">Suc Villa</option>
                      <option value="Suc Oriente">Suc Oriente</option>
                      <option value="Bodega">Bodega</option>
                    </Field>  
                  {touched.sucursal && errors.sucursal && <div style={{marginTop: '0'}} className='generate__error'>{errors.sucursal}</div>}
                </div>
              </div>
              <hr />
            </div>
  
  
            <button
              type='submit'
              className='generate__btn generate__btn-primary'
              >
                Generar
            </button>
            </form>
          )}


        </Formik>

      </div>
    </div>
  )
}
