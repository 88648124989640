// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore} from 'firebase/firestore/lite';
import { getMessaging, getToken } from "firebase/messaging";
import 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDlxCkrps5m6dhQzrrHB710vK866g9hg3Q",
  authDomain: "globaclean-pedidos.firebaseapp.com",
  projectId: "globaclean-pedidos",
  storageBucket: "globaclean-pedidos.appspot.com",
  messagingSenderId: "253045586215",
  appId: "1:253045586215:web:a93d81a82979707520d102"
};

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);

export const FirebaseAuth = getAuth(FirebaseApp);


export const FirebaseDB = getFirestore(FirebaseApp);

const messaging = getMessaging(FirebaseApp)

export const obtenerToken = () =>
Notification.requestPermission()
    .then((permiso) => {
      if (permiso === "granted") {
        console.log("granted")
        getToken(messaging, { vapidKey: "BPTeDSPQbSyRNXOpZU1-Hc5-1kwuwpuZbbQwnang-3bkX-5koXfuadYbYBIUdyTLCCrcZ8m8KdSNR1yMg0YqkLE"})
            .then((currentToken) => {
              if (currentToken) {
                console.log("Token: ", currentToken)
                return currentToken
              } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
              // ...
            });
        
      } else if (permiso === "denied") {
        console.log("Denied!")
      }
    })

export const suscribe = () => {

      // These registration tokens come from the client FCM SDKs.
const registrationTokens = [
  'YfCWH67tNzq5t6txzY6oLpH:APA91bEyohkZnCINsp6Vau4t40_bLOos1BlrTsIrGBcPxS8Le4hRT24QsfXN0tGJxNGUbjTRfrJ86NeGQQHxA21z1ByRIXbZ4HTIRBZ3QKGYb3ncVVAf_VncuDnC0Mboii_KNMFN2olk',
];

// Subscribe the devices corresponding to the registration tokens to the
// topic.
messaging.getInstance().subscribeToTopic(registrationTokens, "notificaciones-pedidos")
  .then((response) => {
    // See the MessagingTopicManagementResponse reference documentation
    // for the contents of response.
    console.log('Successfully subscribed to topic:', response);
  })
  .catch((error) => {
    console.log('Error subscribing to topic:', error);
  });

}



