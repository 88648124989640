import React from 'react'

export const NoItems = ( { tipoPedido } ) => {
  return (
    <>

    <div className='generalSettings__noItemsContainer'>
         <p className=''>No hay pedidos {tipoPedido} en estos momentos </p>
    </div>
        
    </>
  )
}
