
import { useState } from 'react';


export const useForm = ( initialForm = {}, formValidations = {} ) => {


  
    const [ formState, setFormState ] = useState( initialForm );
    // const[formValidation, setFormValidation] = useState({});




    const onInputChange = ({ target }) => {
        const { name, value } = target;

        setFormState({
            ...formState,
            [ name ]: value
        });
    }


    const onNoRegisterLogin = () => {
        setFormState({
            ...formState,
            email: "invitado.globaclean@gmail.com",
            password: "soyinvitado"
        });

        setTimeout( () => {
            document.getElementById("login").click();
        }, 1000)


    }

    const onResetForm = () => {
        setFormState( initialForm );
    }


    // const createValidators = () => {
    //     const formCheckValues = {};

    //     for (const formField of Object.keys(formValidation)) {
    //         const [fn, errorMessage] = formValidation(formField);

    //         formCheckValues[`${ formField }Valid`] = fn( formState[formField]) ? null : errorMessage;
    //     }

    //     setFormValidation( formCheckValues);
    // }

    return {
        ...formState,
        formState,
        onInputChange,
        onNoRegisterLogin,
        onResetForm,
        // ...formValidation
    }
}