import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/'
// import { Redirect } from 'react-router-dom'
import { startCargandoPedidosCompletos } from '../../redux/store/pedidos/thunks'
import { Navbar2 } from '../support/Navbar2'
import { NoItems } from '../support/NoItems'
import { TablaPedidos } from '../support/TablaPedidos'
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
import { NavbarCliente } from '../support/NavbarCliente'
moment.locale('es');

export const CompletosClienteScreen = () => {

  const dispatch = useDispatch();
  const { pedidosCompletos } = useSelector(state => state.pedidos);
  const initialDesde = moment().startOf('week').format("yyyy-MM-DD");
  const initialHasta = moment().endOf('week').format("yyyy-MM-DD");


  const [ fechaDesde, setFechaDesde ] = useState(initialDesde);
  const [ fechaHasta, setFechaHasta ] = useState(initialHasta);
  const [ cambioFecha, setCambioFecha] = useState(true);
  const [ pedidosCompletosFiltrados, setPedidosCompletosFiltrados ] = useState(pedidosCompletos);
  const { displayName } = useSelector(state => state.auth);

  let nuevosPedidosCompletos = [];
 

  nuevosPedidosCompletos = pedidosCompletosFiltrados.filter( pedido => pedido.registro === displayName);



  const seleccionarDesde = (event) => {
    if (event.target.value !== fechaDesde) {
      setFechaDesde(event.target.value);
      setCambioFecha(true);
    }
  }

  const seleccionarHasta = (event) => {
    if (event.target.value !== fechaHasta) {
      setFechaHasta(event.target.value)
      setCambioFecha(true)
    }
  }

  const filtrarFechas = () => {

    let desde = new Date(fechaDesde).getTime() + 18000000; 
    let hasta = new Date(fechaHasta).getTime() + 104400000;

    if (hasta < desde) {
      Swal.fire('¡Error!', 'La fecha final no puede ser menor que la inicial','error');
      return;
    }

    if (cambioFecha) {
      dispatch(startCargandoPedidosCompletos(desde, hasta));
      setCambioFecha(false);
    } 

    filtrarDatos();


  }

  const filtrarDatos = () => {
    let filtradosCompletos = [];
    if (displayName !== null) {
      filtradosCompletos = pedidosCompletos.filter( pedido => (pedido.registro === displayName));
    }

    setPedidosCompletosFiltrados(filtradosCompletos);
  }

  const semanaAnterior = () => {
    let desde = moment(fechaDesde).subtract(7, 'days');
    let hasta = moment(fechaHasta).subtract(7, 'days');
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setCambioFecha(true);
  }

  const semanaSiguiente = () => {
    let desde = moment(fechaDesde).add(7, 'days');
    let hasta = moment(fechaHasta).add(7, 'days');
    setFechaDesde(moment(desde).format("yyyy-MM-DD"));
    setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
    setCambioFecha(true);
  }





  return (
    <div>
      <NavbarCliente />
        <div className='stadistics__rangoContainer'>

            
            <div className='stadistics__rangoFechas'>
              <label className='generate__label'>
                Desde:
                <input 
                  type="date" 
                  name="desde" 
                  className='stadistics__input-limite'
                  value={fechaDesde}
                  onChange={seleccionarDesde}
                />
              </label>
            </div>


            <div className='stadistics__arrowsContainer'>
                <i className="fa-solid fa-circle-arrow-left stadistics__arrows" onClick={semanaAnterior}></i>
                <i className="fa-solid fa-circle-arrow-right stadistics__arrows" onClick={semanaSiguiente}></i>
            </div>
        

            <div className='stadistics__rangoFechas'>
              <label className='generate__label'>
                Hasta:
                <input 
                  type="date" 
                  name="hasta" 
                  className='stadistics__input-limite'
                  value={fechaHasta}
                  onChange={seleccionarHasta}
                />
              </label>
            </div>


          <button id="filtrarFechas" className='stadistics__FiltrarBtn' onClick={filtrarFechas}>Filtrar</button>


          </div>

        <h1 className='settings__title'>Pedidos Completos</h1>

        <div className='dashboard__pedidos'>
          {
             pedidosCompletos.length > 0
             ? <TablaPedidos pedidos={ nuevosPedidosCompletos } isAsignados={false} isCompletos={true} isCliente={true} />
             : <NoItems tipoPedido='completos' />
          }
        </div>
    </div>
  )
}
