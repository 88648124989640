import { collection, doc, setDoc } from "firebase/firestore/lite";
import Swal from "sweetalert2";
import { FirebaseDB } from "../../../firebase/config";
import { loginWithEmailPassword, logoutFirebase, registerUserWithEmailPassword, resetPassword } from "../../../firebase/providers";
import { cargarUsuario } from "../../../helpers/cargarUsuario";
import { checkingCredentials, isLoadingNoReg, login, logout, setCurrentUserDocID } from "./authSlice"



export const checkingAuthentication = (username, password) => {
    return async(dispatch) => {
        dispatch( checkingCredentials());
    }
}

export const startCreatingUserWithEmailPassword = ({ email, password, displayName, tipo, meta, comision, direccion, municipio, telefono, datosFactura}) => {
    return async (dispatch) => {


         dispatch (checkingCredentials());
        const { ok, uid, errorMessage } = await registerUserWithEmailPassword( email, password, displayName, tipo, meta, comision, direccion, municipio, telefono);

        if (!ok) {
            if (errorMessage === "Firebase: Error (auth/email-already-in-use).") {
                Swal.fire( 'Error','Este usuario ya se encuentra registrado', 'error');
                return dispatch(logout("Este usuario ya se encuentra registrado"));
            }
        }
       
        Swal.fire( 'Usuario Registrado','El nuevo usuario ha sido registrado satisfactoriamente', 'success');
        dispatch(startGenerarNuevoUsuario(uid, email, displayName, tipo, meta, comision, direccion, municipio, telefono, datosFactura));
        dispatch(startGenerarNuevoVendedor(uid, email, displayName));

        dispatch(login({ uid, displayName, email }));
     


    }
}


export const startLoginWithEmailPassword = ({email, password}) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());

        const resp = await loginWithEmailPassword({ email, password })
        const {ok, errorMessage} = resp;


        if (!ok) {
            if (errorMessage === "Firebase: Error (auth/user-not-found).") {
                return dispatch(logout("El usuario o contraseña son incorrectos"))
            } else if (errorMessage === "Firebase: Error (auth/wrong-password).") {
                return dispatch(logout("El usuario o contraseña son incorrectos"))
            } else {
                return dispatch( logout( errorMessage ));
            }
        }


        const usuarioLogueado = await cargarUsuario(resp.uid);
        let perfil = usuarioLogueado[0].perfil;
        let meta = usuarioLogueado[0].meta;
        let comision = usuarioLogueado[0].comision;
        let direccion = usuarioLogueado[0].direccion;
        let municipio = usuarioLogueado[0].municipio;
        let telefono = usuarioLogueado[0].telefono;
        let datosFactura = usuarioLogueado[0].datosFactura;
        let rfc = usuarioLogueado[0].rfc;
        let idcfdi = usuarioLogueado[0].idcfdi;
        let imagenidcfdi = usuarioLogueado[0].imagenidcfdi;
        let docID = usuarioLogueado[0].docID;
        dispatch(setCurrentUserDocID(docID));
        const { uid, displayName } = resp;
        dispatch(login({uid, email, displayName, perfil, meta, comision, direccion, municipio, telefono, datosFactura, rfc, idcfdi, imagenidcfdi, docID}));
        // dispatch(setPerfil(usuarioLogueado[0].perfil));
    }

}


export const startLogout = () => {
    return async(dispatch) => {
        await logoutFirebase();

        dispatch(logout());

    }
}


export const startGenerarNuevoUsuario = (uid, email, displayName, tipo, direccion, municipio, telefono) => {
    return async (dispatch) => {

        if (direccion === undefined || municipio === undefined || telefono === undefined) {
            direccion = "";
            municipio = "";
            telefono = "";
        }


        const nuevoUsuario = {
            uid,
            email,
            displayName,
            perfil: tipo,
            meta: 80000,
            comision: 10,
            direccion,
            municipio,
            telefono,
            datosFactura: false
        }


        const newDoc = doc(collection(FirebaseDB, `/usuarios/`))
        const setDocResp = await setDoc(newDoc, nuevoUsuario);

        console.log("Nuevo Usuario ID de Documento: ", setDocResp);

    }
}

export const startGenerarNuevoVendedor = (uid, email, displayName) => {
    return async (dispatch) => {

   
        const nuevoUsuario = {
            uid,
            email,
            displayName,
            meta: 80000,
            comision: 10,
          }


        const newDoc = doc(collection(FirebaseDB, `/vendedores/`))
        const setDocResp = await setDoc(newDoc, nuevoUsuario);

        console.log("Nuevo Vendedor Registrado: ", setDocResp);

    }
}


export const getPerfil = () => {
    return async (dispatch) => {

    }
}


export const startResetPassword = ( email ) => {
    return async () => {
        await resetPassword( email )
    }
}

export const startLoadingNoReg = (isLoad) => {
    return async(dispatch) => {
        dispatch(isLoadingNoReg(isLoad));
    }
}


export const startAgregarDatosFacturacion = (id, rfc, idcfdi) => {
    return async (dispatch) => {

   
        const usuarioAct = {
            datosFactura: true,
            rfc,
            idcfdi,
        }



        // const newDoc = doc(collection(FirebaseDB, `/usuarios/`))
        const usuarioDoc = doc(FirebaseDB, "/usuarios/", id);
        const setDocResp = await setDoc(usuarioDoc, usuarioAct);

        console.log("Actualzados Facturación: ", setDocResp);

    }
}

