import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/'
// import { Redirect } from 'react-router-dom'
import { startCargandoPedidosNoPagados, startCargandoSinDocumento } from '../../redux/store/pedidos/thunks'
import { Navbar2 } from '../support/Navbar2'
import { NoItems } from '../support/NoItems'
import { TablaPedidos } from '../support/TablaPedidos'
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export const NoPagadosScreen = () => {

  const dispatch = useDispatch();
  const { pedidosNoPagados } = useSelector(state => state.pedidos);


  const [ noPagados, setNoPagados ] = useState(pedidosNoPagados);
  const { perfil } = useSelector(state => state.auth);

 


  const filtrarNoPagados = () => {

    dispatch(startCargandoPedidosNoPagados())
    filtrarDatosNoPagados();

    
      setTimeout(() =>{
      document.getElementById("noPedidosMostrar").click();
    }, 1000)

  }

  const filtrarSinDocumento = () => {

    dispatch(startCargandoSinDocumento())
    filtrarDatosNoPagados();

    
      setTimeout(() =>{
      document.getElementById("noPedidosMostrar").click();
    }, 1000)

  }

  let filtrarDatosNoPagados = () => {
    let filtradosCompletos = [];
    filtradosCompletos = pedidosNoPagados;

    setNoPagados(filtradosCompletos);

  }

  return (
    <div>
        <Navbar2 />
        <div className='stadistics__rangoContainer'>


          {perfil === "Administrador" && (<button id="filtrarNoPedidos" className='stadistics__FiltrarNoPagados' onClick={filtrarNoPagados}>No Pagados</button>)}
          {perfil === "Administrador" && (<button id="filtrarSinDocumento" className='stadistics__FiltrarSinDocumento' onClick={filtrarSinDocumento}>Sin Documento</button>)}
          <button id="noPedidosMostrar" style={{display: "none"}} className='stadistics__FiltrarNoPagados' onClick={filtrarDatosNoPagados}>Mostrar</button>


          </div>

        <h1 className='settings__title'>Pedidos No Pagados</h1>

        <div className='dashboard__pedidos'>
          {
             pedidosNoPagados.length > 0
             ? <TablaPedidos pedidos={ pedidosNoPagados } isAsignados={false} isCompletos={true} isNoPagados={true} />
             : <NoItems tipoPedido='no pagados' />
          }
        </div>
    </div>
  )
}
