export const semaforoCumplimiento = ( porcentaje ) => {

    if (porcentaje >= 90) {
        return '#008700';

    }

    if (porcentaje >= 80 && porcentaje < 90) {
      return '#d0bf00';
    }

    if (porcentaje < 80) {
        return '#bb4800';
    }

}