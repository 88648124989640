import React from 'react'
import { useSelector } from 'react-redux';

export default function EstadisticasMetodosRepartidor({ pedidosCompletos, gastos }) {


const { perfil, displayName } = useSelector(state => state.auth);

let repartidores = [];

for (const pedido of pedidosCompletos) {

  if (!repartidores.includes(pedido.repartidor)) {
    repartidores.push(pedido.repartidor);
  }

}  

let estadisticaPorRepartidor = [];

  
repartidores.forEach(repartidor => {
  const completosIndividual = pedidosCompletos.filter( pedido => pedido.repartidor === repartidor);
  const efectivoIndividual = completosIndividual.filter( pedido => pedido.pagado && pedido.detallesPago.metodo === "Efectivo");
  const transferenciaIndividual = completosIndividual.filter( pedido => pedido.pagado && pedido.detallesPago.metodo === "Transferencia");
  const chequeIndividual = completosIndividual.filter( pedido => pedido.pagado && pedido.detallesPago.metodo === "Cheque");
  const otroIndividual = completosIndividual.filter( pedido => pedido.pagado && pedido.detallesPago.metodo === "Otro");
  const gastosExtrasIndividual = gastos.filter( gasto => gasto.repartidor === repartidor);
//   const pagosEfectivoCotejados = completosIndividual.filter (pedido => pedido.pagado && (pedido.detallesPago.metodo === "Efectivo" && pedido.detallesPago.cotejado));

  let pagadoEnEfectivo = 0;
  let efectivoCotejado = 0;
  let documentosEfectivoCotejados = 0;
  efectivoIndividual.forEach( pagado => {
    pagadoEnEfectivo = pagadoEnEfectivo + parseFloat(pagado.detallesPago.cantidad);
    if (pagado.detallesPago.cotejado) {
        efectivoCotejado = efectivoCotejado + parseFloat(pagado.detallesPago.cantidad)
        documentosEfectivoCotejados = documentosEfectivoCotejados + 1;
    }
  })

  let pagadoEnTransferencia = 0;
  let transferenciaCotejado = 0;
  let documentosTransferenciaCotejados = 0;
  transferenciaIndividual.forEach( pagado => {
    pagadoEnTransferencia = pagadoEnTransferencia + parseFloat(pagado.detallesPago.cantidad);
    if (pagado.detallesPago.cotejado) {
        transferenciaCotejado = transferenciaCotejado + parseFloat(pagado.detallesPago.cantidad)
        documentosTransferenciaCotejados = documentosTransferenciaCotejados + 1;
    }
  })

  let pagadoEnCheque = 0;
  let chequeCotejado = 0;
  let documentosChequeCotejados = 0;
  chequeIndividual.forEach( pagado => {
    pagadoEnCheque = pagadoEnCheque + parseFloat(pagado.detallesPago.cantidad);
    if (pagado.detallesPago.cotejado) {
        chequeCotejado = chequeCotejado + parseFloat(pagado.detallesPago.cantidad)
        documentosChequeCotejados = documentosChequeCotejados + 1;
    }
  })

  let pagadoEnOtro = 0;
  let otroCotejado = 0;
  let documentosOtroCotejados = 0;
  otroIndividual.forEach( pagado => {
    pagadoEnOtro = pagadoEnOtro + parseFloat(pagado.detallesPago.cantidad);
    if (pagado.detallesPago.cotejado) {
        otroCotejado = otroCotejado + parseFloat(pagado.detallesPago.cantidad)
        documentosOtroCotejados = documentosOtroCotejados + 1;
    }
  })

  let cantidadGastosExtra = 0;
  gastosExtrasIndividual.forEach( gasto => {
    cantidadGastosExtra = cantidadGastosExtra + parseFloat(gasto.cantidad);
  })

  let documentosCotejados = documentosEfectivoCotejados + documentosTransferenciaCotejados + documentosChequeCotejados + documentosOtroCotejados;





  let nuevoRepa = {
    repartidor,
    totalEnEfectivo: efectivoIndividual.length,
    cantidadEnEfectivo: pagadoEnEfectivo,
    efectivoCotejado,
    docEfecPorCotejar: efectivoIndividual.length - documentosEfectivoCotejados,
    totalEnTransferencia: transferenciaIndividual.length,
    cantidadEnTransferencia: pagadoEnTransferencia,
    docTransPorCotejar: transferenciaIndividual.length - documentosTransferenciaCotejados,
    transferenciaCotejado,
    totalEnCheque: chequeIndividual.length,
    cantidadEnCheque: pagadoEnCheque,
    docChequePorCotejar: chequeIndividual.length - documentosChequeCotejados,
    chequeCotejado,
    totalEnOtro: otroIndividual.length,
    cantidadEnOtro: pagadoEnOtro,
    docOtroPorCotejar: otroIndividual.length - documentosOtroCotejados,
    otroCotejado,
    total: completosIndividual.length,
    cantidad: pagadoEnEfectivo + pagadoEnTransferencia + pagadoEnCheque + pagadoEnOtro,
    docTotalPorCotejar: completosIndividual.length - documentosCotejados,
    totalCotejado: efectivoCotejado + transferenciaCotejado + chequeCotejado + otroCotejado,
    gastosExtra: cantidadGastosExtra,
    efectivoActual: pagadoEnEfectivo - efectivoCotejado - cantidadGastosExtra,
    efectivoReal: pagadoEnEfectivo - cantidadGastosExtra
  }

  estadisticaPorRepartidor.push(nuevoRepa);

});

if (perfil === "Repartidor") {
  estadisticaPorRepartidor = estadisticaPorRepartidor.filter( pedido => pedido.repartidor === displayName);
}

estadisticaPorRepartidor.sort(function (a, b) {
    if (a.totalEnEfectivo > b.totalEnEfectivo) {
      return -1;
    }
    if (a.totalEnEfectivo < b.totalEnEfectivo) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });

  return (
    <>

        <h1 className='settings__title' style={{marginTop: "64px"}}>Pedidos por Método de Pago por Repartidor</h1>

        <table className="table table__tableStats">
        <thead>
            <tr>
            <th scope="col">Repartidor</th>
            <th scope="col">Pedidos Efectivo</th>
            <th scope="col">Cantidad Efectivo</th>
            <th scope="col">Gastos Extra</th>
            <th scope="col">Efectivo Real</th>
            <th scope="col">Docs Por Cotejar (Efectivo)</th>
            <th scope="col">Cotejado Efectivo</th>
            <th scope="col">Efectivo Actual</th>
            <th scope="col">Pedidos Transferencia</th>
            <th scope="col">Cantidad Transferencia</th>
            <th scope="col">Docs Por Cotejar (Transferencia)</th>
            <th scope="col">Cotejado Transferencia</th>
            <th scope="col">Pedidos Cheque</th>
            <th scope="col">Cantidad Cheque</th>
            <th scope="col">Docs Por Cotejar (Cheques)</th>
            <th scope="col">Cotejado Cheque</th>
            <th scope="col">Pedidos Otro</th>
            <th scope="col">Cantidad Otro</th>
            <th scope="col">Docs Por Cotejar (Otros)</th>
            <th scope="col">Cotejado Otro</th>
            <th scope="col">Pedidos Total</th>
            <th scope="col">Cantidad Total</th>
            <th scope="col">Docs Por Cotejar (Total)</th>
            <th scope="col">Cotejado Total</th>
            </tr>
        </thead>
        <tbody>
            {estadisticaPorRepartidor.map( repartidor => {
                return (
                    <tr key={repartidor.repartidor}>
                    <th scope="row">{repartidor.repartidor}</th>
                    <td>{ repartidor.totalEnEfectivo  }</td>
                    <td>${Math.round(repartidor.cantidadEnEfectivo * 100) / 100 }</td>
                    <td>${Math.round(repartidor.gastosExtra * 100) / 100 }</td>
                    <td>${Math.round(repartidor.efectivoReal * 100) / 100 }</td>
                    <td>{ repartidor.docEfecPorCotejar }</td>
                    <td>${Math.round(repartidor.efectivoCotejado * 100) / 100 }</td>
                    <td>${repartidor.efectivoActual > 0 ? Math.round(repartidor.efectivoActual * 100) / 100 : 0 }</td>
                    <td>{ repartidor.totalEnTransferencia  }</td>
                    <td>${Math.round(repartidor.cantidadEnTransferencia * 100) / 100 }</td>
                    <td>{ repartidor.docTransPorCotejar }</td>
                    <td>${Math.round(repartidor.transferenciaCotejado * 100) / 100 }</td>
                    <td>{ repartidor.totalEnCheque  }</td>
                    <td>${Math.round(repartidor.cantidadEnCheque * 100) / 100 }</td>
                    <td>{ repartidor.docChequePorCotejar }</td>
                    <td>${Math.round(repartidor.chequeCotejado * 100) / 100 }</td>
                    <td>{ repartidor.totalEnOtro  }</td>
                    <td>${Math.round(repartidor.cantidadEnOtro * 100) / 100 }</td>
                    <td>{ repartidor.docOtroPorCotejar }</td>
                    <td>${Math.round(repartidor.otroCotejado * 100) / 100 }</td>
                    <td>{repartidor.total}</td>
                    <td>${Math.round(repartidor.cantidad * 100) / 100 }</td>
                    <td>{ repartidor.docTotalPorCotejar }</td>
                    <td>${Math.round(repartidor.totalCotejado * 100) / 100 }</td>
                </tr>
                )
            })}

        </tbody>
        </table>



    </>
  )
}
