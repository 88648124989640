import React, { useState } from 'react';
import { useSelector } from 'react-redux/'
import { Modal } from './Modal';
import moment from 'moment';
import 'moment/locale/es';
import { semaforo } from '../../helpers/semaforo';
import { iconoEntregable } from '../../helpers/iconoEntregable';
import { PagosModal } from './PagosModal';
moment.locale('es');


export const TablaPedidos = ({ pedidos = [], isAsignados, isCompletos, isProduccion, isCliente, isNoPagados, isVendidos }) => {


  const [openModal, setOpenModal] = useState(false);
  const [openPagosModal, setOpenPagosModal] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState({});
  const { perfil } = useSelector(state => state.auth);

  const verDetalles = (pendiente) => {
    setSelectedPedido(pendiente);
    setOpenModal(true);
  }

  const verDetallesPago = (pendiente) => {
    setSelectedPedido(pendiente);
    setOpenPagosModal(true);
  }

  const entregableIcon = (listo, status, enTiempo) => {
    const icon = iconoEntregable(listo, status, enTiempo);
    return icon;
  }

  return (
    <div>

      <table className="table table__table">
        <thead>
          <tr style={{backgroundColor: "#8fc74a" }}>
            <th style={{textAlign: 'center', color: "#000" }} scope="col">Cliente</th>
            {!isCompletos && (<th style={{textAlign: 'center', color: "#000" }} scope="col">Dirección</th>)}
            {!isCompletos && (<th style={{textAlign: 'center', color: "#000" }} scope="col">Municipio</th>)}
            {!isCompletos && (<th style={{textAlign: 'center', color: "#000"}} className="table__ocultar" scope="col">Vencimiento</th>)}
            {(isAsignados || isCompletos) && !isProduccion && !isCliente && !isVendidos  ? (<th style={{textAlign: 'center', color: "#000"}} scope="col">Asignado a</th>) : null} 
            {isVendidos ? (<th style={{textAlign: 'center', color: "#000"}} scope="col">Vendido por</th>) : null} 
            {isCompletos && (<th style={{textAlign: 'center', color: "#000"}} className="table__ocultar" scope="col">Fecha de Entrega</th>)}
            {/* {isCompletos && (<th style={{textAlign: 'center', color: "#000"}} scope="col">Status de Entrega</th>)} */}
            {!isVendidos && (<th style={{textAlign: 'center', color: "#000"}} className="table__ocultar" scope="col">Status</th>)}
            {/* {(isAsignados || isCompletos) && (<th style={{textAlign: 'center', color: "#000"}} scope="col">Nota o Factura</th>)} */}
            {!isCliente && (<th style={{textAlign: 'center', color: "#000"}} className="table__ocultar" scope="col">Nota o Factura</th>) }
            <th style={{textAlign: 'center', color: "#000"}}  scope="col">Detalles</th>
            {(!isCompletos && !isCliente) && (<th style={{textAlign: 'center', color: "#000"}} scope="col">Entregable</th>)}
            {(isCompletos && !isCliente) && (<th style={{textAlign: 'center', color: "#000"}} scope="col">En tiempo</th>)}
            {(isCompletos && !isCliente) && (<th style={{textAlign: 'center', color: "#000"}} scope="col">Evidencia</th>)}
            {(isCompletos && !isCliente) &&(<th style={{textAlign: 'center', color: "#000"}} scope="col">Comprobada</th>)}
            {(isCompletos && (perfil === "Administrador" || perfil === "Repartidor" || perfil === "Vendedor")) && (<th style={{textAlign: 'center', color: "#000"}} scope="col">Pagado</th>)}

          </tr>
        </thead>
        <tbody>

          {
            pedidos.map( pendiente => (
              <tr key={pendiente.uid} style={{backgroundColor: semaforo(pendiente.limite, pendiente.status, pendiente.listo)}}>

                <td> {!pendiente.tipoPedido ? "[NC] " : pendiente.tipoPedido === "Externo" ? "[EXT] " : pendiente.tipoPedido === "Interno" ?  "[INT] " : "[WEB] "  }{pendiente.cliente} <b>({pendiente.tipo})</b></td>
                {pendiente.status !== "completo" ? (<td>{pendiente.direccion}</td>) : null}
                {pendiente.status !== "completo" ? (<td>{pendiente.municipio}</td>) : null}
                {pendiente.status !== "completo" ? (<td className="table__ocultar">{moment(pendiente.limite).format('dddd, DD [de] MMMM [-] LT ')}</td>) : null}
                {(pendiente.status === "asignado" || pendiente.status === "ruta" || pendiente.status === "completo" || pendiente.status === "parcial") && !isProduccion && !isCliente & !isVendidos ? ( <td> {pendiente.repartidor}</td>) : null}   
                {isVendidos ? (<td>{pendiente.vendedorInfo.nombre}</td>) : null}


                { pendiente.status === "completo" ? (<td className="table__ocultar">{moment(pendiente.fechaEntrega).format('dddd, DD [de] MMMM [-] LT ')}</td>) : null}
                {/* { pendiente.status === "completo" ? (<td>{pendiente.entregaStatus}</td>) : null} */}

                {!isVendidos && (<td className="table__ocultar" style={{color: "#FFF"}}> {pendiente.status === "asignado" && isCliente ? "EN RUTA DE ENTREGA" : pendiente.status}</td> )}
                {/* {(pendiente.listo === "Sí" && pendiente.status !== "pendiente") ||  pendiente.status === "produccion" || pendiente.listo === "Nuevo" ? (<td>{pendiente.numeroPedido}</td>) : null} */}
                {!isCliente && (<td className="table__ocultar" style={{color: "#FFF"}}>{pendiente.numeroPedido}</td>)}
                <td><i onClick={() => verDetalles(pendiente)} className="fa-solid fa-circle-info table__detallesBtn"></i></td>
                {(pendiente.status !== "completo" && !isCliente) && (<td style={{backgroundColor: "#FFF"}}><i className={entregableIcon(pendiente.listo, pendiente.status, pendiente.enTiempo)}></i></td>)}
                {(pendiente.status === "completo" && !isCliente) && (<td style={{backgroundColor: "#FFF"}}><i className={entregableIcon(pendiente.listo, pendiente.status, pendiente.enTiempo)}></i></td>)}
                {(pendiente.status === "completo" && !isCliente) &&  (<td style={{backgroundColor: "#FFF"}}>{pendiente.evidenciaEntrega.length > 0 ? (<i className="fa-solid fa-check table__entregableSi" ></i>) : (<i className="fa-solid fa-xmark table__enTiempoNo" ></i>)}</td>)}
                {(pendiente.status === "completo" && !isCliente) &&  (<td style={{backgroundColor: "#FFF"}}>{((pendiente.evidenciaComprobada && pendiente.evidenciaCorrecta) || pendiente.tipoPedido === "Interno") ? (<i className="fa-solid fa-check table__entregableSi" ></i>) : (pendiente.evidenciaComprobada && !pendiente.evidenciaCorrecta) ? (<i className="fa-solid fa-xmark table__enTiempoNo" ></i>) : (<i className="fa-solid fa-clipboard-question table__sinComprobar " ></i>) }</td>)}
                {(pendiente.status === "completo" && perfil === "Administrador"  && pendiente.pagado) ? <td style={{backgroundColor: (pendiente.pagado === "Sí" && pendiente.detallesPago.cotejado) ? "#0EAB06" : (pendiente.pagado === "Sí" && !pendiente.detallesPago.cotejado) ? "#D4DB04" : (pendiente.pagado ==="No" && !pendiente.detallesPago.documento) ? "#808080" :  "#C62704"} }>{pendiente.pagado === "Sí" ? <i onClick={() => verDetallesPago(pendiente)} className="fa-solid fa-money-bill-transfer table__detallesBtn"></i> : !pendiente.detallesPago.documento ? <i onClick={() => verDetallesPago(pendiente)} className="fa-solid  fa-file-contract table__detallesBtn"></i> : <i onClick={() => verDetallesPago(pendiente)} className="fa-solid fa-comment-dollar table__detallesBtn"></i>}</td> : null}
                {(pendiente.status === "completo" && (perfil === "Repartidor" || perfil === "Vendedor")  && pendiente.pagado) ? <td style={{backgroundColor: (pendiente.detallesPago.cotejado || pendiente.detallesPago.documento)  ? "#0EAB06" : pendiente.pagado === "Sí"  ? "#D4DB04" : "#808080"} }>{pendiente.pagado === "Sí" ? <i onClick={() => verDetallesPago(pendiente)} className="fa-solid fa-money-bill-transfer table__detallesBtn"></i> : <i onClick={() => verDetallesPago(pendiente)} className="fa-solid fa-file-contract table__detallesBtn"></i>}</td> : null}


                  
            
             </tr>
           
            ))
          }


        </tbody>
      </table>

      {openModal && <Modal pedido={selectedPedido} closeModal={setOpenModal} />}
      {openPagosModal && <PagosModal pedido={selectedPedido} closeModal={setOpenPagosModal} noPagadosFlag={isNoPagados} />}

    </div>
  )
}
