import React from 'react'
import {  useSelector, useDispatch } from 'react-redux/';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import Countdown from 'react-countdown';
import { semaforo } from '../../helpers/semaforo';
import { startAsignacionPedido, startAsignacionProduccion, startCambiarStatusRutaEntrega, startCierreParcial, startCompletarEntrega, startComprobarEvidencia, startEliminarPedido, startMaterialIncompleto, startPedidoListo } from '../../redux/store/pedidos/thunks';
import { pedidoSeleccionado } from '../../redux/store/pedidos/pedidosSlice';
import imagenPedido from "../../assets/solicitud-pedido.png";
import imagenEvidencia from "../../assets/evidencia-icono.png";
import imagenIDCFDI from "../../assets/imagen-idcfdi.png";
moment.locale('es');


export const Modal = ({ closeModal, pedido }) => {

  const dispatch = useDispatch();
  const { perfil, displayName } = useSelector(state => state.auth);
  const history = useHistory();

  dispatch(pedidoSeleccionado(pedido));
  
  const asignarProductor = () => {
    dispatch(startAsignacionProduccion(pedido.uid, displayName));
    closeModal(false);
  }

  const pedidoListo = () => {
    dispatch(startPedidoListo(pedido.uid, pedido.repartidor, pedido.limite, pedido.listo, displayName))
    closeModal(false);
  }

  const asignarRepartidor = () => {
    dispatch(startAsignacionPedido(pedido.uid, displayName));
    closeModal(false);
  }

  const enRutaDeEntrega = () => {
    dispatch(startCambiarStatusRutaEntrega(pedido.uid))
    closeModal(false);
  }

  const completarEntrega = () => {
    dispatch(startCompletarEntrega(pedido.uid, pedido.limite))
    closeModal(false);
  }

  const cierreParcial = () => {
    dispatch(startCierreParcial(pedido.uid, pedido.limite))
    closeModal(false);
  }

  const openImage = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const modificarPedido = () => {
    history.push("/modificar");
  } 

  const modificarPedidoCliente = () => {
    history.push("/modificarCliente");
  } 


  const materialIncompleto = () => {
    dispatch(startMaterialIncompleto(pedido.uid))
    closeModal(false);
  }

  const eliminarPedido = () => {
    dispatch(startEliminarPedido(pedido.uid))
    closeModal(false);
  }

  const comprobarEvidencia = () => {
    dispatch(startComprobarEvidencia(pedido.uid))
    closeModal(false);
  }

  return (
    <div className='modal__backgroundModal'>
        <div className='modal__container'>
            <div className='modal__closeModalXButton'>
                <button onClick={() => closeModal(false)}>X</button>
            </div>
            <div className='modal__header'>
              <h1 className='modal__title'>{pedido.cliente} ({pedido.tipo})</h1>
              {pedido.listo === "Nuevo" || pedido.listo === "No"  ? (<p  style={{textAlign: "center", color: semaforo(pedido.limite, pedido.status)}}><b>Límite de Producción:</b><br /> {<Countdown date={pedido.limite - 3600000} daysInHours overtime={true} />}</p>) : null}
              {(pedido.status !== "completo" && pedido.listo === "Sí") && (<p  style={{textAlign: "center", color: semaforo(pedido.limite, pedido.status)}}><b>Límite de Entrega:</b><br /> {<Countdown date={pedido.limite} daysInHours overtime={true} />}</p>)}
            </div>
            
            <div className='modal__body'>

             <p><b>Fecha:</b> {moment(pedido.fecha).format('dddd, DD [de] MMMM[,] LT ')}</p>
              {pedido.listo === "Sí" && (<p><b>No. Pedido:</b> {pedido.numeroPedido}</p>)} 
              <p><b>Dirección:</b> {pedido.direccion}</p>
              <p><b>Municipio:</b> {pedido.municipio}</p>
              {pedido.tipo === "Cliente" && (<p><b>Teléfono: </b> {pedido.telefono}</p>)}
              {pedido.tipo === "Cliente" && (<p><b>Email: </b> {pedido.email}</p>)}
              <p><b>Rango de Precio:</b> {pedido.rangoPrecio}</p>
              <p><b>Fecha Límite de Entrega:</b> {moment(pedido.limite).format('dddd, DD [de] MMMM[,] LT ')}</p>
              <p><b>Tipo de Pedido:</b> {pedido.tipoPedido}</p>
              <div className='modal__separador'></div>

              <p><b>Forma de Pago: </b> {pedido.formaPago}</p>
              <p><b>Método de Pago: </b> {pedido.metodoPago}</p>
              <p><b>Costo Extra:</b> {pedido.costoExtra}</p>
              <p><b>Factura:</b> {pedido.factura}</p>
              {pedido.factura === "Sí" ? (<p><b>RFC Cliente:</b> {pedido.rfc}</p>) : null}   
              {pedido.factura === "Sí" ? (<p><b>idCFDI:</b> {pedido.idcfdi}</p>) : null}   

              {
                pedido.imagenID?.length > 0 && (
                  <div>
                  <p><b>Constancia: </b></p>
                  {
                  pedido.imagenID.map( (img, index) => 
                    (<img style={{ width: "40px", margin: "5px 10px 10px 10px", cursor: "pointer", textAlign: "left"}} onClick={() => openImage(img)} src={imagenIDCFDI} key={index} alt="img idcfdi" />)
                  )
                  }
                </div>
                )
              }
              {/* {pedido.factura === "Sí" ? (<p><b>Dirección de Factura:</b> {pedido.direccionFactura}</p>) : null}   
              {pedido.factura === "Sí" ? (<p><b>CFDI:</b> {pedido.cfdi}</p>) : null}    */}
              <div className='modal__separador'></div>
  
              <p><b>Registró: </b> {pedido.registro}</p>
              {pedido.listo !== "Nuevo" && (<p><b>Producción: </b> {pedido.produccion}</p>)} 
              {pedido.repartidor !== "Libre" && (<p><b>Repartidor: </b> {pedido.repartidor}</p>)} 
              <p><b>Vendedor: </b> {pedido.vendedor ? 'Sí' : 'No'}</p>
              {pedido.vendededor && (<p><b>Vendedor Nombre: </b> {pedido.vendedorInfo?.nombre}</p>)}
              {pedido.vendededor && (<p><b>Vendedor ID: </b> {pedido.vendedorInfo?.id}</p>)}

              <p><b>Status:</b> {pedido.status}</p>
              <div className='modal__separador'></div>
     
              {pedido.status !== "parcial" && pedido.status !== "completo" && pedido.comentarios ? (<p><b>Comentarios: </b> {pedido.comentarios}</p>) : null} 


              
              

              {pedido.status === "parcial" || pedido.status === "completo" ? (<p><b>Fecha de Entrega: </b> {moment(pedido.fechaEntrega).format('dddd, DD [de] MMMM [-] LT ')}</p>) : null} 
              {pedido.status === "parcial" || pedido.status === "completo" ? (<p><b>Status de Entrega: </b> {pedido.entregaStatus}</p>) : null}   
              <div style={{height: "5px"}}></div> 
              {pedido.status === "completo" ? (<p><b>Observaciones: </b> {pedido.comentariosFinales}</p>) : null}    
              {pedido.listo === "Incompleto" && (<p><b>Descripción de faltantes: </b> {pedido.incompletoObs}</p>)} 
              {pedido.status === "parcial" && (<p><b>Comentarios Cierre Parcial: </b> {pedido.comentariosParcial}</p>)} 
              <div style={{height: "5px"}}></div> 
              <p style={{whiteSpace: "pre-wrap"}}><b>Pedido:</b><br />{pedido.pedido} </p>

               {
                pedido.imagenes.length > 0 && (
                  <div className='modal__imagenesContainer'>
                  {
                  pedido.imagenes.map( (img, index) => 
                    (<img style={{ width: "70px", margin: "20px 10px 10px 10px", cursor: "pointer"}} onClick={() => openImage(img)} src={imagenPedido} key={index} alt="img pedido" />)
                  )
                  }
                </div>
                )
              }

              {pedido.status === "completo" && (<p><b>Evidencia de entrega:</b></p>)}  

              {
                pedido.status === "completo" && (
                  <div className='modal__imagenesContainer'>
                  {
                  pedido.evidenciaEntrega.map( (img, index) => 
                    (<img style={{ width: "70px", margin: "20px 10px 10px 10px", cursor: "pointer"}} onClick={() => openImage(img)} src={imagenEvidencia} key={index} alt="img evidencia" />)
                  )
                  }
                </div>
                )
              }

            </div>





            <div className='modal__actions'>

              {
              (perfil === "Produccion"  || perfil === "Sucursal") && pedido.status === "nuevo"
              ? (<button className='modal__btnAsignar' onClick={() => asignarProductor()}>Tomar Producción</button>) 
              : null
              }

              {
              (perfil === "Produccion" || perfil === "Sucursal") && (pedido.listo === "No" || pedido.listo === "Incompleto") && pedido.status !== "nuevo" && (pedido.registro === displayName || pedido.produccion === displayName)
              ? (<button className='modal__btnAsignar' onClick={() => pedidoListo()}>Pedido Listo</button>) 
              : null
              }

              {
              (perfil === "Produccion" || perfil === "Sucursal") && (pedido.listo === "No" || pedido.listo === "Incompleto") && (pedido.registro === displayName || pedido.produccion === displayName)
              ? (<button className='modal__btnIncompleto' onClick={() => materialIncompleto()}>Material Incompleto</button>) 
              : null
              }

              {
              ((perfil === "Empleado" || perfil === "Administrador" || perfil === "Sucursal") && pedido.registro === displayName) && pedido.status !== "completo"
              ? (<button className='modal__btnModificar' onClick={() => modificarPedido()}>Modificar</button>)
              : null
              }

              {
                (perfil === "Cliente" && pedido.registro === displayName && pedido.status === "nuevo") 
                ? (<button className='modal__btnModificar' onClick={() => modificarPedidoCliente()}>Modificar</button>)
                : null
              }

              {
              perfil === "Repartidor" && (pedido.status === "pendiente" || pedido.status === "produccion")
              ? (<button className='modal__btnAsignar' onClick={() => asignarRepartidor()}>Tomar Reparto</button>) 
              : null
              }


              {
                (perfil === 'Repartidor' || pedido.repartidor === displayName) && (pedido.status === "asignado" || pedido.status === "ruta" || pedido.status === "parcial") 
                ? (
                <div className='modal__asignadoBtnContainer'>
                <button disabled={pedido.status === "ruta" || pedido.status === "parcial"|| pedido.listo === "No" || pedido.listo === "Incompleto" || perfil === "Sucursal"} className='modal__btnModificar' onClick={() => enRutaDeEntrega()}>En ruta</button>
                <button disabled={pedido.listo === "No" || pedido.listo === "Incompleto" || pedido.status === "parcial"} className='modal__btnParcial' onClick={ () => cierreParcial() }>Cierre Parcial</button>
                <button disabled={pedido.listo === "No" || pedido.listo === "Incompleto"} className='modal__btnAsignar' onClick={ () => completarEntrega() }>Entregado</button>
                </div>
                  )
                
                : null
              }

{/* {
              perfil === "Repartidor" && pedido.status === "completo"
              ? (<button className='modal__btnAsignar' onClick={() => completarEntrega()}>Subir Evidencia</button>) 
              : null
              } */}



              {/* {
                perfil === 'Repartidor' && pedido.status === "parcial" 
                ? (
                <div className='modal__asignadoBtnContainer'>
                <button className='modal__btnAsignar' onClick={ () => completarEntrega() }>Entregado</button>
                </div>
                  )
                
                : null
              }
               */}
              
            </div>

            <div className='modal__eliminarBtnContainer'>
            {( pedido.status === "completo" && ((perfil === "Empleado" || perfil === "Administrador")  || (perfil === "Sucursal" && pedido.registro === displayName)))  && (<button className='modal__btnAsignar' onClick={ () => comprobarEvidencia() }>Comprobar Evidencia</button>) }
              {((perfil === "Empleado" || perfil === "Administrador")  || (perfil === "Sucursal" && pedido.registro === displayName) || (perfil === "Cliente" && pedido.registro === displayName && pedido.status === "nuevo"))  && (<button className='modal__btnEliminar' onClick={ () => eliminarPedido() }>Eliminar Pedido</button>) }
            </div>

        </div>
    </div>
  )
}
