import React, { useMemo, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { useForm } from '../../hooks/useForm'
// import { login } from '../../redux/actions/auth'
import { startLoginWithEmailPassword } from '../../redux/store/auth/thunks'


export const LoginScreen = () => {

  const { status, errorMessage } = useSelector( state => state.auth);
  const isCheckingAuthentification = useMemo( () => status === 'checking', [status]);
  
  const dispatch = useDispatch();
  
  const {email, password, onInputChange, onNoRegisterLogin }= useForm({
    email: '',
    password: '',
  });

  // const { usuario, password } = values;




  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(startLoginWithEmailPassword({email, password}));
  }

  if ( status === "authenticated" ) {
    return <Redirect to="/" />
  }

  

  return (
    <>
    
      {/* {loadingNoReg ? <CargandoLoginSinRegistro /> : null} */}
      <div className='auth__imgContainer'>
        <img src={logo} className='auth__logo' alt='logo' />
      </div>
      <h3 className='auth__title'>Iniciar Sesión</h3>

      <form onSubmit={handleLogin} className="animate__animated animate__fadeIn">
        <input
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            className='auth__input'
            value={email}
            onChange={onInputChange}
          />

        <input
          type="password"
          id='password'
          placeholder="Contraseña"
          name="password"
          className='auth__input'
          value={password}
          onChange={onInputChange}
        />

        <button
          type='submit'
          id="login"
          className='btn-custom btn-primary-custom'
          disabled={isCheckingAuthentification}
          >
            Ingresar
        </button>
        <div className='auth__firebase-error'>{errorMessage}</div>


         <Link to="/auth/resetpassword" className='auth__newUser'>¿Olvidaste tu contraseña?</Link>    
         <Link to="/auth/client" className='auth__newUser auth__newUserCliente'>¡Regístrate como cliente!</Link>    

      </form>

      <button
          className='btn-custom btn__sinRegistro'
          onClick={onNoRegisterLogin}
          >
            Ingresa como invitado
        </button>

    </>
  )
}
