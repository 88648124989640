import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navbar2 } from '../support/Navbar2'
import { Formik, Field } from 'formik';
import { startCargandoGastosExtras, startNuevoGastoExtra, startUploadingFiles } from '../../redux/store/pedidos/thunks';
import { Redirect } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import TablaGastosExtras from '../support/TablaGastosExtras';
import moment from 'moment';
import 'moment/locale/es';
import { CargandoPedido } from '../support/CargandoPedido';

export const GastosExtrasScreen = () => {

    const dispatch = useDispatch();
    const { imagenes, gastosExtras, loading } = useSelector( state => state.pedidos);

    const initialDesde = moment().startOf('week').format("yyyy-MM-DD");
    const initialHasta = moment().endOf('week').format("yyyy-MM-DD");
    const [ cambioFecha, setCambioFecha] = useState(true);
    const [ fechaDesde, setFechaDesde ] = useState(initialDesde);
    const [ fechaHasta, setFechaHasta ] = useState(initialHasta);
    const [ repartidorSeleccionado, setRepartidorSeleccionado ] = useState("Todos");
    const [ pedidosCompletosFiltrados, setPedidosCompletosFiltrados ] = useState(gastosExtras);
    const { perfil } = useSelector(state => state.auth);




    // useEffect(() => {
    //   dispatch(startCargandoGastosExtras());
    // }, [dispatch])

    if ( perfil !== "Repartidor" && perfil !== "Administrador") {
      return <Redirect to="/" />
    }


    const onFileInputChange = ({ target }) => {
        if (target.files === 0) return;
        dispatch(startUploadingFiles(target.files));
      }

      const seleccionarDesde = (event) => {
        if (event.target.value !== fechaDesde) {
          setFechaDesde(event.target.value);
          setCambioFecha(true);
        }
      }
    
      const seleccionarHasta = (event) => {
        if (event.target.value !== fechaHasta) {
          setFechaHasta(event.target.value)
          setCambioFecha(true)
        }
      }
    
      const changeRepartidor = (event) => {
        setRepartidorSeleccionado(event.target.value);
      }


      const filtrarFechas = () => {

        let desde = new Date(fechaDesde).getTime() + 18000000; 
        let hasta = new Date(fechaHasta).getTime() + 104400000;

        if (hasta < desde) {
          Swal.fire('¡Error!', 'La fecha final no puede ser menor que la inicial','error');
          return;
        }


        if (cambioFecha) {
          dispatch(startCargandoGastosExtras(desde, hasta));
          setCambioFecha(false);
        } 
    
        filtrarDatos();


      }

      const filtrarDatos = () => {
        let filtradosCompletos = [];
        let repartidor = repartidorSeleccionado;

        if (repartidor === "Todos") {
          filtradosCompletos = gastosExtras;
        } else if (repartidor === "Otros") {
          filtradosCompletos = gastosExtras.filter( pedido => (pedido.repartidor !== "Martín" && pedido.repartidor !== "Héctor" && pedido.repartidor !== "Emergente" && pedido.repartidor !== "Diego de la Cruz " && pedido.repartidor !== "Francisco Rangel "));
        } else {
          filtradosCompletos = gastosExtras.filter( pedido => pedido.repartidor === repartidor);
        }
   
    
    
        setPedidosCompletosFiltrados(filtradosCompletos);
      }
    
      const semanaAnterior = () => {
        let desde = moment(fechaDesde).subtract(7, 'days');
        let hasta = moment(fechaHasta).subtract(7, 'days');
        setFechaDesde(moment(desde).format("yyyy-MM-DD"));
        setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
        setCambioFecha(true)
      }
    
      const semanaSiguiente = () => {
        let desde = moment(fechaDesde).add(7, 'days');
        let hasta = moment(fechaHasta).add(7, 'days');
        setFechaDesde(moment(desde).format("yyyy-MM-DD"));
        setFechaHasta(moment(hasta).format("yyyy-MM-DD"));
        setCambioFecha(true)
      }

 
  


  return (
    <>


        <Navbar2 />

        {loading ? (<CargandoPedido />) : null}



        {perfil === "Repartidor" && (

          <>
            <h1 className='settings__title'>Registro de Gastos Extras</h1>
            <Formik 
            initialValues={{
              
              cantidad: 0,
              concepto: "",
              evidencia: []

              }}
            validate={(valores) => {
              let errores = {};

              if (!valores.cantidad) {
                errores.cantidad = "Requerido"
              }
      
              if (valores.concepto === "") {
                errores.concepto = "Requerido"
              }

              // if (valores.evidencia.length === 0) {
              //   errores.evidencia = "Requerido"
              // }

              return errores;
            }}
            onSubmit={(valores, { resetForm }) => {
              valores.evidencia = imagenes;
              if (imagenes.length === 0) {
                Swal.fire( 'Error', 'Se requiere subir evidencia', 'error');
                return;
              }
              dispatch(startNuevoGastoExtra(valores));
              resetForm();

            }}
          >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) => (
              <form className='generate__form' onSubmit={handleSubmit}>
    
               <div className='generate__form-section'>
                 <div className='modalPagos__factura-inputs' style={{paddingLeft: "30%"}}>

     
                  <label style={{marginTop: "0"}} className='generate__labelFactura modalPagos__labelFactura'>
                    Cantidad: 
                    <input 
                        type="number" 
                        placeholder="" 
                        name="cantidad" 
                        className='generate__inputFactura'
                        value={values.cantidad}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{width: "110px"}}
                      />
                      {touched.cantidad && errors.cantidad && <div className='generate__error '>{errors.cantidad}</div>}
                  </label>
      

                  <div style={{flexDirection: "column", justifyContent: "flex-start"}} className='generate__labelFactuar generate__noMT'>
                    <Field as="select" name="concepto" className='settings__selectInput metodoSelect'>
                      <option value=''>Concepto</option>
                      <option value="Tienda Departamental">Tienda Departamental</option>
                      <option value="Tienda de Conveniencia">Tienda de Conveniencia</option>
                      <option value="Ferretería">Ferretería</option>
                      <option value="Pedidos Especiales">Pedidos Especiales</option>
                      <option value="Bolsas">Bolsas</option>
                      <option value="Otro">Otro</option>
                    </Field>  
                    {touched.concepto && errors.concepto && <div style={{marginTop: '0'}} className='generate__error'>{errors.concepto}</div>}
                  </div>

                  <div style={{justifyContent: "flex-start", marginTop: "20px"}}>
                <label className='generate__label'>
                  Evidencia: 
                  <input 
                    type="file" 
                    multiple
                    name="evidencia" 
                    className='generate__input-file' 
                    onChange={ onFileInputChange }
                    onBlur={handleBlur}
                    
                  />
                </label>
                <br />
                {touched.evidencia && imagenes.length === 0 && <div className='generate__error '>Requerido</div>}
                 </div>
                                          
                  </div>
                    
              </div>
    
        
              <button
                type='submit'
                className='generate__btn generate__btn-primary'
                style={{width: "30%", marginLeft: "30%"}}
                >
                  Registrar Gasto Extra
              </button>
              </form>

            )}
            </Formik>
          </>

        )}





          <br />
          <h1 className='settings__title'>Listado de Gastos Extras</h1>
          <div className='stadistics__rangoContainer'>

            
            <div className='stadistics__rangoFechas'>
              <label className='generate__label'>
                Desde:
                <input 
                  type="date" 
                  name="desde" 
                  className='stadistics__input-limite'
                  value={fechaDesde}
                  onChange={seleccionarDesde}
                />
              </label>
            </div>


            <div className='stadistics__arrowsContainer'>
                <i className="fa-solid fa-circle-arrow-left stadistics__arrows" onClick={semanaAnterior}></i>
                <i className="fa-solid fa-circle-arrow-right stadistics__arrows" onClick={semanaSiguiente}></i>
            </div>


            <div className='stadistics__rangoFechas'>
              <label className='generate__label'>
                Hasta:
                <input 
                  type="date" 
                  name="hasta" 
                  className='stadistics__input-limite'
                  value={fechaHasta}
                  onChange={seleccionarHasta}
                />
              </label>
            </div>

            {(perfil !== "Repartidor" && perfil !== "Sucursal") && (

                <div className='stadistics__rangoFechas repartidorInput'>
                <label className='generate__label'>
                  Repartidor:
                  <select name="select" onChange={changeRepartidor} className="stadistics__input-limite">
                    <option value="Todos" selected>Todos</option>
                    <option value="Diego de la Cruz ">Diego</option>
                    <option value="Francisco Rangel ">Francisco</option>              
                    <option value="Héctor">Héctor</option>
                    <option value="Emergente">Emergente</option>
                    <option value="Martín">Martín</option>
                    <option value="Otros">Otros</option>
                  </select>
                </label>
                </div>
            )}



            <button id="filtrarGastos" className='stadistics__FiltrarBtn' onClick={filtrarFechas}>Filtrar</button>


            </div>
            {pedidosCompletosFiltrados.length > 0 ? (<TablaGastosExtras gastos={pedidosCompletosFiltrados} />) : <p className='generalSettings__noItemsContainer'>No hay gastos extras para mostrar</p> }
           <br />
           <br />

    </>

  )
}
