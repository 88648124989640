export const sendNotificationsNuevoPedido = ( cliente, repartidor ) => {

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Basic N2FjOWVkMGYtNmRkMC00MWJiLTljZGEtY2IzMmU2OWYzZDc0',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      included_segments: ['Subscribed Users'],
      "app_id": "d4c5a23f-40ba-4ed6-952f-18a125ccaf04",
      contents: {en: `NUEVO PEDIDO PARA ${cliente} ASIGNADO A ${repartidor}`, es: `NUEVO PEDIDO PARA ${cliente} ASIGNADO A ${repartidor}`},
      name: "Nuevo Pedido"
    })
  };
  
  fetch('https://onesignal.com/api/v1/notifications', options)
    .then(response => response.json())
    .then(response => console.log(response))
    .catch(err => console.error(err));
}