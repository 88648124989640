import React from 'react'

export const TotalProducidos = ({ pedidosCompletos, perfil, displayName }) => {

let pedidosProducidos = pedidosCompletos.filter( pedido => pedido.produccionEnTiempo === true || pedido.produccionEnTiempo === false );


if (perfil === "Produccion" || perfil === "Sucursal") {
    pedidosProducidos = pedidosCompletos.filter( pedido => pedido.produccion === displayName);
}

const enTiempo = pedidosProducidos.filter( pedido => pedido.produccionEnTiempo === true);
const despuesLimite = pedidosProducidos.filter( pedido => pedido.produccionEnTiempo === false);
let porcentajeCompletosEnTiempo = (enTiempo.length / pedidosProducidos.length) * 100;
if (isNaN(porcentajeCompletosEnTiempo)) {
    porcentajeCompletosEnTiempo = 0;
}
const widthPorcentaje = porcentajeCompletosEnTiempo.toString() + '%';

  return (
    <>

<div className='stadistics__completosContainer' style={{backgroundColor: "#000000"}}>
        <div className='stadistics__completosHeader'>
            <p className='stadistics__completosTitle'>PEDIDOS PRODUCIDOS</p>
        </div>
        <div className='stadistics__completosBody'>
            <div className='stadistics__completosBodyField'>
              <p className='stadistics__completosBodyFieldTitle'>Total</p>
              <p className='stadistics__completosBodyFieldData'>{pedidosProducidos.length}</p>
            </div>
            <div className='stadistics__completosBodyField'>
              <p className='stadistics__completosBodyFieldTitle'>En Tiempo</p>
              <p className='stadistics__completosBodyFieldData'>{enTiempo.length}</p>
            </div>
            <div className='stadistics__completosBodyField'>
              <p className='stadistics__completosBodyFieldTitle'>Fuera de Tiempo</p>
              <p className='stadistics__completosBodyFieldData'>{despuesLimite.length}</p>
            </div>
          </div>
          <div className='stadistics__completosFooter'>
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{width: widthPorcentaje, backgroundColor: "#008700"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{`${Math.round(porcentajeCompletosEnTiempo * 100) / 100}% producidos en tiempo`}</div>
         </div>
          </div>
      </div>



    </>
  )
}
