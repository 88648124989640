export const semaforo = (fecha, status, listo) => {
    const ahora = new Date().getTime();
    const faltan = fecha - ahora;

    if (status === "nuevo" && faltan > 7200000 ) {
      return "#069988";
    }

    if (status === "nuevo" && faltan > 0 && faltan <= 72000000) {
      return "#D0BF00";
    }

    if (status === "nuevo" && faltan < 0) {
      return "#b80000";
    }

    if (listo === "Incompleto") {
      return "#6a6868";
    }
    
    if (status === "produccion" || listo === "No") {
      return "#000000";
    }

    if (faltan <= 0 && status !== "parcial" && status !== "completo") {
      return '#b80000';
    }

    if (faltan > 0 && faltan <= 5400000 && status !== "parcial" && status !== "completo") {
      return '#bb4800';
    }

    if (faltan > 5400000 && faltan <= 10800000 && status !== "parcial" && status !== "completo" ) {
      return '#d0bf00';
    }

    if (faltan > 10800000 && status !== "parcial" && status !== "completo") {
      return '#008700';
    }

    if (status === "parcial") {
      return '#5b077f';
    }

    if (status === "completo") {
      return '#0c0182';
    }
}