import React from 'react'
import { semaforoCumplimiento } from '../../helpers/semaforoCumplimiento';

export default function EstadisticasPagosGenerales({pedidosCompletos, entregados, title}) {



    let metodos = ["Efectivo", "Transferencia", "Cheque", "Otro"];
    let estadisticaPorMetodo = [];

    metodos.forEach(metodo => {
        const metodoIndividual = pedidosCompletos.filter( pedido => pedido.pagado && pedido.detallesPago.metodo === metodo);
        // const enTiempoIndividual = completosIndividual.filter( pedido => pedido.enTiempo === true);

        const pagados = pedidosCompletos.filter( pedido => pedido.pagado === "Sí" && pedido.detallesPago.metodo === metodo);
        const noPagados = pedidosCompletos.filter( pedido => pedido.pagado  === "No" && pedido.detallesPago.metodo === metodo);

        let totalCantidadPorMetodo = 0;
        metodoIndividual.forEach( metInd => {
            totalCantidadPorMetodo = totalCantidadPorMetodo + parseFloat(metInd.detallesPago.cantidad);
        })

        let totalPagado = 0;
        pagados.forEach( pagado => {
            totalPagado = totalPagado + parseFloat(pagado.detallesPago.cantidad);
        })

        let totalNoPagado = 0;
        noPagados.forEach( noPagado => {
            totalNoPagado = totalNoPagado + parseFloat(noPagado.detallesPago.cantidad);
        })
  
        let nuevoMetodo = {
          metodo,
          pedidosTotal: metodoIndividual.length,
          pedidosPagados: pagados.length,
          totalPagado,
          totalNoPagado,
          cantidad: totalCantidadPorMetodo,
          porcentaje: (totalPagado / totalCantidadPorMetodo) * 100

        }
    
        estadisticaPorMetodo.push(nuevoMetodo);
    
      });



  return (

    <>
        <h1 className='settings__title' style={{marginTop: "64px"}}>{ title }</h1>

        <table className="table table__tableStats">
        <thead>
            <tr>
            <th scope="col">Método de Pago</th>
            <th scope="col">Total Pedidos</th>
            {entregados && <th scope="col">Pedidos Pagados</th>}
            {entregados && <th scope="col">Pagado</th> }
            {entregados && <th scope="col">No Pagado</th>}
            <th scope="col">Cantidad</th>
            {entregados && <th scope="col">Porcentaje</th>} 
            {/* <th scope="col">Total</th>
            <th scope="col">Porcentaje</th> */}
            </tr>
        </thead>
        <tbody>
            
            {estadisticaPorMetodo.map( metodo => {
                return (
                    <tr key={metodo.metodo}>
                    <th scope="row">{metodo.metodo}</th>
                    <td>{metodo.pedidosTotal}</td>
                    {entregados && <td>{metodo.pedidosPagados}</td>}
                    {entregados && <td>${ Math.round(metodo.totalPagado * 100) / 100 }</td>}
                    {entregados && <td>${ Math.round(metodo.totalNoPagado * 100) / 100  }</td>}
                    <td>${ Math.round(metodo.cantidad * 100) / 100 }</td>

                    {entregados && (
                        <td colSpan="2">
                            <div className="progress table__progressBar">
                                <div className="progress-bar" role="progressbar" style={{width: `${metodo.porcentaje}%`, backgroundColor: semaforoCumplimiento(metodo.porcentaje)}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{`${Math.round(metodo.porcentaje * 100) / 100}%`}</div>
                            </div>
                            <div className='table__progressNumber' style={{color: semaforoCumplimiento(metodo.porcentaje)}}>{`${Math.round(metodo.porcentaje * 100) / 100}%`}</div>
                        </td>
                    )}
                </tr>
                )
            })}

        </tbody>
        </table>

</>
  )
}
