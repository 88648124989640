import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { startLogout } from '../../redux/store/auth/thunks'

// import { startActivarNotificaciones, startSuscripcion } from '../../redux/store/pedidos/thunks'
// import { getAuth, signInAnonymously} from "firebase/auth";

export const NavbarCliente = () => {

    const { status, displayName, docID } = useSelector(state => state.auth);
    const dispatch = useDispatch();
  
    const logout = () => {
      dispatch(startLogout());
    }

    // const mandarNotificacion = () => {
    //   sendNotificationsNuevoPedido();
    // }

    // const fakeLogin = () => {
    //   signInAnonymously(getAuth()).then( usuario => console.log("Fake login: ", usuario))
    // }

    // const suscribirte = () => {
    //   dispatch(startSuscripcion());
    // }


  return (
    <>

<nav style={{backgroundColor: "#8fc74a"}} className="navbar navbar-expand-lg">
  <div className="container-fluid">
     <Link to='/'><img src={logo} className='navbar__logo' alt="logo" /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="fa-solid fa-bars"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">

      <ul className="navbar-nav me-auto mb-2 mb-lg-0">

        {/* <li className="nav-item"><NavLink className='nav-link' activeClassName="active" to='/produccion'>Producción</NavLink></li> */}
        <li className="nav-item"><NavLink className='nav-link' activeClassName="active" to='/generarCliente'>Generar Pedido</NavLink></li>
        {docID !== "B8ulCbQyT5tUif3WxyAy" && (<li className="nav-item"><NavLink className='nav-link'exact activeClassName="active" to='/pendientesCliente'>Pedidos Pendientes</NavLink></li>)}
        {docID !== "B8ulCbQyT5tUif3WxyAy" && (<li className="nav-item"><NavLink className='nav-link'exact activeClassName="active" to='/completosCliente'>Pedidos Completos</NavLink></li>)}
        <li style={{marginLeft: "50px"}} className='nav-item'>
        {status === "authenticated" ? (
              <div className='navbar__userInfo'>
                <p>{displayName} </p>
                <Link
                  to='/'
                  className='navbar__logoutButton'
                  onClick={logout}>
                  Cerrar Sesión
                </Link>
              </div>
            ) : null
            }   
        </li>

      </ul>

      {/* <button onClick={mandarNotificacion}>Notificaciones</button> */}
      {/* <button onClick={fakeLogin}>Login</button>
      <button onClick={suscribirte}>Suscribirte</button> */}
        
            
    </div>
  </div>
</nav>

    </>
  )
}
