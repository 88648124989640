import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import { CompleteScreen } from '../components/pedidos/CompleteScreen';
import { DashboardScreen } from '../components/pedidos/DashboardScreen';
import { GenerateScreen } from '../components/pedidos/GenerateScreen';
import { StatdisticsSreen } from '../components/pedidos/StatdisticsSreen';
import { AsignadosScreen } from '../components/pedidos/AsignadosScreen';
import { AuthRouter } from './AuthRouter';
import { CheckingAuth } from '../ui/components/CheckingAuth';
import { onAuthStateChanged } from 'firebase/auth';
import { FirebaseAuth } from '../firebase/config';
import { login, logout } from '../redux/store/auth/authSlice';
import { cargarUsuario } from '../helpers/cargarUsuario';
import { ModifyScreen } from '../components/pedidos/ModifyScreen';
import { ProduccionScreen } from '../components/pedidos/ProduccionScreen';
import { GastosExtrasScreen } from '../components/pedidos/GastosExtrasScreen';
import { GenerateClientScreen } from '../components/pedidos/GenerateClientScreen';
import { HistorialPedidosCliente } from '../components/pedidos/HistorialPedidosCliente';
import { CompletosClienteScreen } from '../components/pedidos/CompletosClienteScreen';
import { ModifyClientScreen } from '../components/pedidos/ModifyClientScreen';
import { NoPagadosScreen } from '../components/pedidos/NoPagadosScreen';
import { VendedoresScreen } from '../components/pedidos/VendedoresScreen';
// import { startCargandoPedidosCompletos, startCargandoTodosPedidos } from '../redux/store/pedidos/thunks';



export const AppRouter = () => {

  const { status, uid }  = useSelector(state => state.auth);
  const dispatch = useDispatch()



  
  useEffect( () => {
    onAuthStateChanged(FirebaseAuth, async (user) => {
      if (!user) return dispatch(logout());

      const { uid, email, displayName } = user;
      const userPerfil = await cargarUsuario(uid);

      let perfil = userPerfil[0].perfil;
      let direccion = userPerfil[0].direccion;
      let municipio = userPerfil[0].municipio;
      let telefono = userPerfil[0].telefono;
      let datosFactura = userPerfil[0].datosFactura;
      let rfc = userPerfil[0].rfc;
      let idcfdi = userPerfil[0].idcfdi;
      let imagenidcfdi = userPerfil[0].imagenidcfdi;
      dispatch(login({ uid, email, displayName, perfil, direccion, municipio, telefono, datosFactura, rfc, idcfdi, imagenidcfdi}));
      setTimeout( () => {
        console.log("");
      }, 2000)

    })
  }, [dispatch])

  // useEffect(() => {
  //   dispatch(startCargandoPedidosCompletos());
  // }, [dispatch])

  // useEffect(() => {
  //   dispatch(startCargandoTodosPedidos());
  // }, [dispatch])


  if (status === 'checking') {
    return <CheckingAuth />
  }

  return (
    <Router>

      {/* {
        (status === "authenticated")
        ?  <Route path={'/'}  component={PedidosRouter} />
        :  <Route path={'/auth'} component={AuthRouter}  /> 
      }

      <Redirect to='/auth/login' /> */}


    <div>
      <Switch>
            <Route
            path={'/auth'} 
            render={ () => {
              return (status === "authenticated" && uid !== "MZB0ssuzv8fGscvT4VXELbWXnGH2") ? <Redirect to='/' /> : <AuthRouter />
            }}        
            />

            <Route
            path={'/'} 
            exact         
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <DashboardScreen />
            }}      
            />

            <Route
            path={'/produccion'} 
            exact         
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <ProduccionScreen />
            }}      
            />

            <Route
            path={'/generar'} 
            exact   
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <GenerateScreen />
            }}           
            />

            <Route
            path={'/generarCliente'} 
            exact   
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <GenerateClientScreen />
            }}           
            />

          <Route
            path={'/modificarCliente'} 
            exact   
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <ModifyClientScreen />
            }}           
            />

            <Route
            path={'/pendientesCliente'} 
            exact   
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <HistorialPedidosCliente />
            }}           
            />

            <Route
            path={'/completosCliente'} 
            exact   
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <CompletosClienteScreen />
            }}           
            />
            

            <Route
            path={'/asignados'} 
            exact   
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <AsignadosScreen />
            }}           
            />

            <Route
            path={'/completos'} 
            exact     
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <CompleteScreen />
            }}         
            />

            <Route
            path={'/noPagados'} 
            exact     
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <NoPagadosScreen />
            }}         
            />

            <Route
            path={'/estadisticas'} 
            exact
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <StatdisticsSreen />
            }}                
            />

            <Route
            path={'/vendedores'} 
            exact
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <VendedoresScreen />
            }}                
            />

            <Route
            path={'/modificar'} 
            exact
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <ModifyScreen />
            }}                
            />

            <Route
            path={'/gastos'} 
            exact
            render={ () => {
              return status !== "authenticated" ? <Redirect to='/auth' /> : <GastosExtrasScreen />
            }}                
            />

            <Redirect 
                to='/auth/login'
            />
            
        </Switch>
    </div>
</Router>
  )
}
